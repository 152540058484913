import React from 'react'
// import NotFound from '../Images/notFound.jpg';
import { Link } from 'react-router-dom';


const Error404 = () => {
    return (
        <>
            <div className="px-4 h-full mt-header">
				<div className='flex flex-col justify-center h-full md:w-3/5 md:mx-auto'>
					<div className='not-found__media'>
						<h4 className='text-center font-semibold text-2xl text-gray-500 mb-3'>Error Code: 404</h4>
						{/* <img src={NotFound} className='m-auto' alt='Not Found' /> */}
					</div>

					<div className='not-found__content text-center'>
						<p className='mb-2 text-2xl font-medium'>Whoops!</p>
						<p className='mb-8'>We can’t seem to find the page you are looking for</p>
						<Link to={'/'} className='w-full' color='dark'>
							Go to  Home
						</Link>
					</div>
				</div>
			</div>
        </>
    )
}

export default Error404