import React, {useState, useEffect} from 'react';
import { Label, Checkbox, Button, Radio } from 'flowbite-react';
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";
import Select from "react-select";
import ClaimResponse from './ClaimResponse';



const DPClaim = ({claimType,claimCategory,claimExpired,purchaseDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText,typeOfClaim}) => {
	
	const imageMimeType = /image\/(png|jpg|jpeg)/i;
	if(DEBUG) console.log(Date.now());
	if(DEBUG) console.log(claimExpired)
	if(DEBUG) console.log(claimType)
	if(DEBUG) console.log(claimCategory)
	if(DEBUG) console.log(purchaseDetails)
	if(DEBUG) console.log(typeOfClaim);
	

	const [buttonStatus,setButtonStatus] = useState(true);
	
	const [accessoriesPhoto, setAccessoriesPhoto] = useState();
	const [originalBoxPhoto,setOriginalBoxPhoto] = useState();
	const [idPhoto,setIdPhoto] = useState();
	const [phonePhoto,setPhonePhoto] = useState();
	const [imeiPhoto,setImeiPhoto] = useState();
	const [isAccessories,setIsAccessories] = useState(false);
	const [isBoxAvailable,setIsBoxAvailable] = useState(false);
	const [customerPaymentValue,setCustomerPaymentValue] = useState();
	const [claimTypeProcess,setClaimTypeProcess] = useState();
	const [claimReplaceModel,setClaimReplaceModel] = useState();
	const [selectedMakeOptions, setSelectedMakeOptions] = useState();
	const [selectedModelOptions, setSelectedModelOptions] = useState();

	const [boxPhotoFinal,setBoxPhotoFinal]					= useState();
	const [accessoriesPhotoFinal,setAccessoriesPhotoFinal] 	= useState();
	const [damagePhotoFinal,setDamagePhotoFinal] 			= useState();
	const [idPhotoFinal,setIdPhotoFinal] 					= useState();
	const [imeiPhotoFinal,setImeiPhotoFinal]				= useState();

	const [make,setMake] = useState([]);
	const [model,setModel] = useState([]);
	
	// const [makeSangeetha] = useState([]);
	// const [modelSangeetha] = useState([]);
	const navigate = useNavigate();
	// Form 
    const { register, handleSubmit, formState: { errors } ,setValue } = useForm();
	
	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
	
	const handleAccessories = () => {
		setIsAccessories(current => !current)
	}
	
	const handleAccessoriesImageUpload = async(e,type) => {
		e.preventDefault()
		setShowPleaseWaitPopup(true);
		const file = e.target.files[0];
		if(DEBUG) console.log(file);
		var imageData = new FormData();
		imageData.append('purpose', 'tmpclaim');
		imageData.append('attachment', file);
		if(DEBUG) console.log(imageData);
		if(DEBUG) console.log("hitted");
		
		if(DEBUG) console.log(type);

		try {
			if (!file.type.match(imageMimeType)) {
				setErrorMsgAlertShow(true);
				setErrorMsgAlertText("Image mime type is not valid");
				setShowPleaseWaitPopup(false);
				return;
			}

			if(file.size > 1000000){
				setErrorMsgAlertShow(true);
				setErrorMsgAlertText("Please Upload less than 1 MB Image");
				setShowPleaseWaitPopup(false);
				return;
			}

            const response =  await axiosJWT.post('/api/uploadFile',imageData,{headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
			setShowPleaseWaitPopup(false);
            if(DEBUG) console.log(response.data.status);
			if(DEBUG) console.log(response.data.data);
            if(response.status === 200 ){
                if(response.data.status === true){
					if(type === 'accessories'){
						setAccessoriesPhoto(URL.createObjectURL(file));
						setAccessoriesPhotoFinal(response.data.data);
					} 

					if(type === 'original'){
						setOriginalBoxPhoto(URL.createObjectURL(file));
						setBoxPhotoFinal(response.data.data);
					} 

					if(type === 'idproof'){
						setIdPhoto(URL.createObjectURL(file));
						setIdPhotoFinal(response.data.data)
					} 

					if(type === 'imeiproof'){
						setImeiPhoto(URL.createObjectURL(file));
						setImeiPhotoFinal(response.data.data)
					} 

					if(type === 'phoneproof'){
						setPhonePhoto(URL.createObjectURL(file));
						setDamagePhotoFinal(response.data.data)
					} 
                } else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
                }
            } else {
				setErrorMsgAlertText('Error Status '+response.status);
				setErrorMsgAlertShow(true);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText('Error: '+error.response);
				setErrorMsgAlertShow(true);
            }
        }
	}

	const handleBoxAvailable = () => {
		setIsBoxAvailable(current => !current)
	}
	
	const handleClaimType = (e) => {
		if(DEBUG) console.log(e.currentTarget.value);
		setClaimTypeProcess(e.currentTarget.value)
		if(e.currentTarget.value === 'repair'){
			setButtonStatus(false);
		}
	}
	
	const handleReplaceModel = async(e) => {
		if(DEBUG) console.log(e.currentTarget.value);
		setClaimReplaceModel(e.currentTarget.value)
		setButtonStatus(true);
		setCustomerPaymentValue('');
		if(e.currentTarget.value === 'different'){
			setMake([]);
			setModel([]);
			setSelectedMakeOptions();
			setSelectedModelOptions();
			getMake();
			
		}

		if(e.currentTarget.value === 'sangeetha'){
			setMake([]);
			setModel([]);
			setSelectedMakeOptions();
			setSelectedModelOptions();
			getMakeSangeetha();
		}

		if(e.currentTarget.value === 'same'){
			setShowPleaseWaitPopup(true);
			try {
				const response = await axiosJWT.post('/api/checkVASPercentage',{phone_id:purchaseDetails.phone_id,replacement_type:e.currentTarget.value},{headers: {
					Authorization: `Bearer ${token}`
				}}).catch((error) => {
					if(DEBUG) console.log(error);
				})
				if(DEBUG) console.log(response);
				setShowPleaseWaitPopup(false);
				if(response.status === 200 ){
					if(response.data.status === true){
						if(DEBUG) console.log(response.data.data);
						setCustomerPaymentValue(response.data.data);
						setButtonStatus(false);
					} else {
						setErrorMsgAlertText(response.data.msg);
						setErrorMsgAlertShow(true);
					}
				} 
			} catch (error) {
				if (error.response) {
					setErrorMsgAlertText(error.response.data.msg);
					setErrorMsgAlertShow(true);
				}
			}
		}

	}

    const getMake = async() => {
		setShowPleaseWaitPopup(true);
        try {
            const response = await axiosJWT.get('/api/getmake',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					var a = []
					response.data.data.map((makeDet) => {
						if(DEBUG) console.log(makeDet);
						const arr = { value: makeDet.id, label: makeDet.make_name };
						a.push(arr);
					})
					if(a){
						setMake([...a]);
					}
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
            }
        }
    }

	const handleMakeSeleted = async (data) => {
		if(DEBUG) console.log(data);
		setSelectedMakeOptions(data)
		setValue('make_id',data.value);
		setShowPleaseWaitPopup(true);
		
		try {
            const response = await axiosJWT.post('/api/getmodel',{make_id:data.value},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					// var documentType = {};
					var mod = [];
					response.data.data.map((modelDet) => {
						const arr = { value: modelDet.id, label: modelDet.model_name };
						mod.push(arr);
					})

					if(mod){
						setModel([...mod]);
					}
                    // setPurchasePhoneDetails(response.data.data);
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
            }
        }
	}

	const handleModelSeleted = async(data) => {
		setSelectedModelOptions(data);
		setButtonStatus(true);
		setShowPleaseWaitPopup(true);
		try {
			const response = await axiosJWT.post('/api/checkVASPercentage',{phone_id:purchaseDetails.phone_id,replacement_type:'different',replace_make:selectedMakeOptions.value,replace_model:data.value},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					setCustomerPaymentValue(response.data.data);
					setButtonStatus(false);
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
			}
		}
	}
	
	const getMakeSangeetha = async() => {
		setShowPleaseWaitPopup(true);
        try {
            const response = await axiosJWT.get('/api/getMakeSangeetha',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					var a = [];
					response.data.data.map((makeDet) => {
						if(DEBUG) console.log(makeDet);
						const arr = { value: makeDet.id, label: makeDet.make_name };
						a.push(arr);
					})
					if(a){
						setMake([...a]);
					}
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
            }
        }
    }

	const handleMakeSangeethaSeleted = async (data) => {
		if(DEBUG) console.log(data);
		setSelectedMakeOptions(data)
		setValue('make_id',data.value);
		setShowPleaseWaitPopup(true);
		model.push([]);
		try {
            const response = await axiosJWT.post('/api/getModelSangeetha',{make_id:data.value},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					// var documentType = {};
					var mod = [];
					response.data.data.map((modelDet) => {
						const arr = { value: modelDet.id, label: modelDet.model_name };
						mod.push(arr);
					})

					if(mod){
						setTimeout(() => { setModel([...mod])}, 100);
					}
                    // setPurchasePhoneDetails(response.data.data);
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
            }
        }
	}

	const handleModelSangeethaSeleted = async(data) => {
		setSelectedModelOptions(data);
		setButtonStatus(true);
		setShowPleaseWaitPopup(true);
		try {
			const response = await axiosJWT.post('/api/checkVASPercentage',{phone_id:purchaseDetails.phone_id,replacement_type:'sangeetha',replace_make:selectedMakeOptions.value,replace_model:data.value},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					setCustomerPaymentValue(response.data.data);
					setButtonStatus(false);
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
			}
		}
	}


	useEffect(() => {
		// getMake();
		// eslint-disable-next-line
		if(typeOfClaim.length === 1){
			setClaimTypeProcess(typeOfClaim[0]);
			setButtonStatus(false);
		}
	},[typeOfClaim])

	const [claimSubmit,setClaimSubmit] = useState(false);
	const [claimSubmitResponse,setClaimSubmitResponse] = useState(false);

	const onSubmit = async(data) => {
		if(DEBUG) console.log(data)

		var claim_sales_id  			= purchaseDetails.phone_id;
		var is_box_available 			= data.is_box_available;
		var is_accessories_available 	= data.is_accessories_available
		var box_photo 					= boxPhotoFinal;
		var accessories_photo 			= accessoriesPhotoFinal;
		var damage_photo 				= damagePhotoFinal;	
		var id_photo 					= idPhotoFinal;
		var imei_photo 					= imeiPhotoFinal;
		var additional					= data.additional == true ?  true : '';	
		var claim_type	 				= 'DP'			
		var process_type 				= claimTypeProcess
		var replacement_type			= claimReplaceModel;
		var claim_category 				= claimCategory;
		var make 						= selectedMakeOptions?.value;
		var model 						= selectedModelOptions?.value;
		setShowPleaseWaitPopup(true);
		try {
			const response = await axiosJWT.post('/api/dpClaimSubmit',{
				claimSalesId:claim_sales_id,
				isBoxAvailable:is_box_available,
				isAccessoriesAvailable:is_accessories_available,
				boxPhoto:box_photo,
				accessoriesPhoto:accessories_photo,
				damagePhoto:damage_photo,
				idPhoto:id_photo,
				imeiPhoto:imei_photo,
				additional:additional,
				claimType:claim_type,
				processType:process_type,
				replacementType:replacement_type,
				claimCategory:claim_category,
				make:make,
				model:model,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					setClaimSubmit(true);
					setClaimSubmitResponse(response.data.data);
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
			}
		}
	}

	return (
		<>
			{ !claimSubmit  ?
				<section className='category-wrapper mt-header py-4'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
							<div className=' text-red-600 font-medium'>
								<p>Expires In: {claimExpired} Days </p>
							</div>
							<div className='category-claim-wrapper rounded shadow'>
								<div className='category-content__items px-2 md:px-4'>
									{/* <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Name</span>
										<span className='text-black'>Thiru</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Mob</span>
										<span className='text-black'>9987876789</span>
									</p> */}
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Number</span>
										<span className='text-black'>{purchaseDetails?.invoice_no}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>IMEI Number</span>
										<span className='text-black'>{purchaseDetails?.imei_number}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Make</span>
										<span className='text-black'>{purchaseDetails?.model}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Amount</span>
										<span className='text-black'>{ '₹'+purchaseDetails?.invoice_total}</span>
									</p>
								</div>
							</div>
							<div className=' text-red-600 font-medium text-lg'>
								<p>NOTE: All attachments are mandatory</p>
							</div>
							<div className='category-claim-wrapper rounded shadow p-2'>
								<div className='category-content__items px-2 md:px-4 border-0'>
									<div className="flex items-center gap-2">
										<Checkbox id="is_accessories_available" value={1} {...register("is_accessories_available", { required: true, onChange:(e) => handleAccessories(e, 'accessories') })} />
										<Label htmlFor="is_accessories_available" className='ml-1'>Accessories provided along with the original handset are available</Label>
									</div>
									{errors.is_accessories_available && <p className='text-left mt-2 text-rose-600 text-xs'> Please Check </p>}
									{ isAccessories ? 
									<div className="grid grid-cols-2 gap-2 my-6">
										<div className='flex flex-col border-gray-300 rounded border p-2 w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("is_accessories_photo",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'accessories')})} />
												<span className='camera-icon__upload w-full h-full place-items-center text-center'>
													{accessoriesPhoto ? 
														<img src={accessoriesPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'>Accessories with Handset Photo</div>
											{errors.is_accessories_photo && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>
									</div>:''
									}
								
									<hr className='my-3'/>
									<div className="flex items-center gap-2">
										<Checkbox id="is_box_available" value={1} {...register("is_box_available", { required: true, onChange:(e) => handleBoxAvailable(e) })}/>
										<Label htmlFor="is_box_available" className='ml-1'>I have original box which came with headset</Label>
									</div>
									{ isBoxAvailable ? 
									<div className="grid grid-cols-2 gap-2 my-6">
										<div className='flex flex-col border-gray-300 rounded border p-2 w-36 h-36 text-center'>
											<div className='img-upload__claim relative  w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("is_box_photo",{ required: true,onChange:(e) => handleAccessoriesImageUpload(e,'original')})} />
												<span className='camera-icon__upload w-full h-full place-items-center text-center'>
													{originalBoxPhoto ? 
														<img src={originalBoxPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className=' place-items-center text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
													
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'>Box with Handset Photo</div>
											{errors.is_box_photo && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>
									</div>:''
									}
									<hr className='my-3'/>


									<div className="grid grid-cols-2 gap-2 my-6">
										<div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("is_id_photo",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'idproof')})}  />
												<span className='camera-icon__upload w-full h-full text-center'>
													{idPhoto ? 
														<img src={idPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'>ID Proof</div>
											{errors.is_id_photo && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>

										<div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("is_phone_photo",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'phoneproof')})} />
												<span className='camera-icon__upload w-full h-full text-center'>
													{phonePhoto ? 
														<img src={phonePhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className=' text-center mt-3 text-sm text-gray-400 font-medium'>Phone Front Photo</div>
											{errors.is_phone_photo && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>
									</div>

									<div className="grid grid-cols-2 gap-2 my-6">
										<div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("is_imei_photo",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'imeiproof')})}  />
												<span className='camera-icon__upload w-full h-full text-center'>
													{imeiPhoto ? 
														<img src={imeiPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'>Phone Back Photo</div>
											{errors.is_id_photo && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>
									</div>

									
									<div className="flex items-center gap-2">
										<Checkbox id="additional"  value="true"  {...register("additional")} />
										<Label htmlFor="additional" className='ml-1'>If any discrepancy, click here</Label>
									</div>
									{ typeOfClaim.length == 2 ?
										<>
											<hr className='my-3'/>
											<div className="flex items-start flex-col gap-2">
												<p>Do you want to replace/repair? </p>
												<div className='grid grid-cols-2 gap-2 w-full mt-2'>
													<div className='flex items-center'>
														<Radio id="claimTypeReplace"  value="claim"  {...register("claim_type", { required: true, onChange:(e) => handleClaimType(e) })} />
														<Label htmlFor="claimTypeReplace" className='ml-1'> Replace </Label>
													</div>
													<div className='flex items-center'>
														<Radio id="claimTypeRepair"  value="repair" {...register("claim_type", { required: true, onChange:(e) => handleClaimType(e) })} />
														<Label htmlFor="claimTypeRepair" className='ml-1'> Repair </Label>
													</div>
												</div>
											</div>
										</> :
										'' 
									}

									<hr className='my-3'/>
									{ claimTypeProcess === 'claim' ? 
										<>
											<div className="flex items-start flex-col gap-2">
												<p>Do you want to replace with same mode (or) another model?</p>
												<div className='grid grid-cols-3 gap-2 w-full mt-2'>
													<div className='flex items-center'>
														<Radio id="replaceModelSame" value="same" {...register("replacement_type", { required: true, onChange:(e) => handleReplaceModel(e) })} />
														<Label htmlFor="replaceModelSame" className='ml-1'> Same Model </Label>
													</div>
													<div className='flex items-center'>
														<Radio id="replaceModelSangeetha" value="sangeetha" {...register("replacement_type", { required: true, onChange:(e) => handleReplaceModel(e) })}  />
														<Label htmlFor="replaceModelSangeetha" className='ml-1'> Sangeetha Model </Label>
													</div>
													<div className='flex items-center'>
														<Radio id="replaceModelDiffer" value="different" {...register("replacement_type", { required: true, onChange:(e) => handleReplaceModel(e) })}  />
														<Label htmlFor="replaceModelDiffer" className='ml-1'> Different Model </Label>
													</div>
													
												</div>
											</div>
											<hr className='my-3'/>
										</>:''
									}

									{ claimTypeProcess === 'claim' &&  claimReplaceModel === 'different' ?
										<>
											{ make && make.length > 0 &&(
											<div className="relative mb-3">
												<Select name="make" id="make" options={make} value={selectedMakeOptions} onChange={handleMakeSeleted}  required />
												<label htmlFor="make" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Make</label>
											</div>
											)}
											{ model && model.length > 0 &&(
											<div className="relative mb-3">
												<Select name="model" id="model" options={model} value={selectedModelOptions} onChange={handleModelSeleted}   required />
												<label htmlFor="model" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Model</label>
											</div>
											)}
										</>:''
									}

									{ claimTypeProcess === 'claim' &&  claimReplaceModel === 'sangeetha' ?
										<>
											{ make && make.length > 0 &&(
											<div className="relative mb-3">
												<Select name="make" id="make" options={make} value={selectedMakeOptions}  onChange={handleMakeSangeethaSeleted}  required />
												<label htmlFor="make" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Make</label>
											</div>
											)}
											{ model && model.length > 0 &&(
											<div className="relative mb-3">
												<Select name="model" id="model" options={model} value={selectedModelOptions} onChange={handleModelSangeethaSeleted}   required />
												<label htmlFor="model" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Model</label>
											</div>
											)}
										</>:''
									}
									{ claimTypeProcess === 'claim' &&  (claimReplaceModel === 'different'||  claimReplaceModel === 'same' ||  claimReplaceModel === 'sangeetha' ) && customerPaymentValue != '' && customerPaymentValue?.customer_payment != '' ?
										<>
											<div className="flex items-start flex-col gap-2 bg-gray-300 ">
												<div className='m-2'>
													<h5 className='text-blue-600'>Customer Payment Value</h5>
													<p>{customerPaymentValue?.html_text}</p>
													<span> Note: This is an approx Customer Payment Value - CPV Check with Store Team for Net Customer Payment Value - CPV</span>
												</div>
											</div>
											<hr className='my-3'/>
											<div className="flex items-start flex-col gap-2 ">
												<div className='m-2'>
													<h5 className='text-blue-600'>{'₹'+customerPaymentValue?.customer_payment}</h5>
												</div>
											</div>
											<hr className='my-3'/>
										</>:''
									}

									<div className="flex items-center gap-2">
										<Checkbox id="agree" value={1} {...register("is_agree", { required: true })} />
										<Label htmlFor="agree" className='ml-1'>I agree to the Terms and Conditions FAQ </Label>
									</div>
								</div>
							</div>
						</div>
						<div className='mx-4'>
							<Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
						</div>
					</form>
				</section>: 
				<ClaimResponse  claimSubmitResponse ={claimSubmitResponse} />
			}
		</>
	)
}

export default DPClaim
