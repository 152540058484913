import React,{useState} from 'react';
import { Button } from 'flowbite-react';
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";
import ClaimResponse from './ClaimResponse';
// import Select from "react-select";

const TPClaimStep = ({claimType,claimCategory,claimExpired,purchaseDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

	if(DEBUG) console.log(claimType)
	if(DEBUG) console.log(claimCategory)
	if(DEBUG) console.log(claimExpired)
	if(DEBUG) console.log(purchaseDetails)

	const [buttonStatus,setButtonStatus] = useState(false);
	const [claimSubmit,setClaimSubmit] = useState(false);
	const [claimSubmitResponse,setClaimSubmitResponse] = useState(false);
	// Form 
    const { register, handleSubmit, formState: { errors } } = useForm();

	const navigate = useNavigate();
	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

	const imageMimeType = /image\/(png|jpg|jpeg)/i;
	
	const [idPhoto,setIdPhoto] 				= useState();
	const [idPhotoFinal,setIdPhotoFinal] 	= useState();

	// const [complaintPhoto,setComplaintPhoto] 			= useState();
	// const [complaintPhotoFinal,setComplaintPhotoFinal] 	= useState();

	const [firCsrPhoto,setFirCsrPhoto] 				= useState();
	const [firCsrPhotoFinal,setFirCsrPhotoFinal] 	= useState();

	const [simBlockPhoto,setsimBlockPhoto] 				= useState();
	const [simBlockPhotoFinal,setsimBlockPhotoFinal] 	= useState();

	const [documentPhoto,setDocumentPhoto] 				= useState();
	const [documentPhotoFinal,setDocumentPhotoFinal] 	= useState();

	const handleAccessoriesImageUpload = async(e,type) => {
		e.preventDefault()
		setShowPleaseWaitPopup(true);
		const file = e.target.files[0];
		if(DEBUG) console.log(file);
		var imageData = new FormData();
		imageData.append('purpose', 'tmptpclaim');
		imageData.append('attachment', file);
		if(DEBUG) console.log(imageData);
		if(DEBUG) console.log("hitted");
		
		if(DEBUG) console.log(type);
		try {
			if (!file.type.match(imageMimeType)) {
				setErrorMsgAlertText("Image mime type is not valid");
				setErrorMsgAlertShow(true)
				setShowPleaseWaitPopup(false);
				return;
			}

			if(file.size > 1000000){
				setErrorMsgAlertText("Please Upload less than 1 MB Image");
				setErrorMsgAlertShow(true);
				setShowPleaseWaitPopup(false);
				return;
			}

            const response =  await axiosJWT.post('/api/uploadFile',imageData,{headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
			setShowPleaseWaitPopup(false);
            if(DEBUG) console.log(response.data.status);
			if(DEBUG) console.log(response.data.data);
            if(response.status === 200 ){
                if(response.data.status === true){
					if(type === 'complaint'){
						// setComplaintPhoto(URL.createObjectURL(file));
						// setComplaintPhotoFinal(response.data.data);
					} 

					if(type === 'fir_csr'){
						setFirCsrPhoto(URL.createObjectURL(file));
						setFirCsrPhotoFinal(response.data.data);
					} 

					if(type === 'idproof'){
						setIdPhoto(URL.createObjectURL(file));
						setIdPhotoFinal(response.data.data)
					} 

					if(type === 'sim'){
						setsimBlockPhoto(URL.createObjectURL(file));
						setsimBlockPhotoFinal(response.data.data)
					} 

					if(type === 'document'){
						setDocumentPhoto(URL.createObjectURL(file));
						setDocumentPhotoFinal(response.data.data)
					} 
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
                }
            } else {
                setErrorMsgAlertText(response.status);
				setErrorMsgAlertShow(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response);
				setErrorMsgAlertShow(true)
            }
        }
	}

	const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
		setButtonStatus(true);
		setShowPleaseWaitPopup(true);
		
		var claim_sales_id  			= purchaseDetails.phone_id;
		// var complaint_photo 			= complaintPhotoFinal;
		var fir_csr_photo 				= firCsrPhotoFinal;
		var sim_photo 					= simBlockPhotoFinal;	
		var id_photo 					= idPhotoFinal;
		var document_photo 				= documentPhotoFinal;	
		var claim_type	 				= 'TP';	

		try {
			const response = await axiosJWT.post('/api/tpClaimSubmit2',{
				claimSalesId:claim_sales_id,
				// complaintPhoto:complaint_photo,
				firCsrPhoto:fir_csr_photo,
				simBlockPhoto:sim_photo,
				documentPhoto:document_photo,
				idPhoto:id_photo,
				claimType:claim_type,
				claimCategory:claimCategory,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					setClaimSubmit(true);
					setClaimSubmitResponse(response.data.data);
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true)
			}
		}
	}

	return (
		<>
			{ !claimSubmit  ?
				<section className='category-wrapper mt-header py-4'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
							<div className='font-medium text-black'>
								<p>STEP 2 :</p>
							</div>
							<div className='category-claim-wrapper rounded shadow'>
								<div className='category-content__items px-2 md:px-4'>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Number</span>
										<span className='text-black'>{purchaseDetails?.invoice_no}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>IMEI Number</span>
										<span className='text-black'>{purchaseDetails?.imei_number}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Make</span>
										<span className='text-black'>{purchaseDetails?.model}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Amount</span>
										<span className='text-black'>{purchaseDetails?.invoice_total}</span>
									</p>
								</div>
							</div>
							
							<div className=' text-red-600 font-medium text-lg'>
								<p>NOTE: All attachments are mandatory</p>
							</div>
							<div className='category-claim-wrapper rounded shadow p-2'>
								<div className='category-content__items px-2 md:px-4 border-0'>
									<div className="grid grid-cols-2 gap-2 my-6">
										<div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("is_id_photo",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'idproof')})}  />
												<span className='camera-icon__upload w-full h-full text-center'>
													{idPhoto ? 
														<img src={idPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'>ID Proof</div>
											{errors.is_id_photo && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>

										<div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("firCsrPhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'fir_csr')})}  />
												<span className='camera-icon__upload w-full h-full text-center'>
													{firCsrPhoto ? 
														<img src={firCsrPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'>FIR/CSR/Complaint Acknowledgement Photo</div>
											{errors.firCsrPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>
									</div>
									<div className="grid grid-cols-2 gap-2 my-6">
										
										<div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("simBlockPhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'sim')})} />
												<span className='camera-icon__upload w-full h-full text-center'>
													{simBlockPhoto ? 
														<img src={simBlockPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className=' text-center mt-3 text-sm text-gray-400 font-medium'>SIM Card Verification Photo</div>
											{errors.simBlockPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>

										{/* <div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("complaintPhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'complaint')})} />
												<span className='camera-icon__upload w-full h-full text-center'>
													{complaintPhoto ? 
														<img src={complaintPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className=' text-center mt-3 text-sm text-gray-400 font-medium'>Handset IMEI on Box with Charger Photo</div>
											{errors.complaintPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div> */}
									
										<div className='flex flex-col border-gray-300 rounded border p-2 w-36 h-36 text-center'>
											<div className='img-upload__claim relative w-full h-full'>
												<input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("documentPhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'document')})}  />
												<span className='camera-icon__upload w-full h-full place-items-center text-center'>
													{documentPhoto ? 
														<img src={documentPhoto} className="w-full h-full text-center" />
														:
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
															<path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
															<path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
														</svg>
													}
												</span>
											</div>
											<div className='text-center mt-3 text-sm text-gray-400 font-medium'> Handset IMEI on Box with Charger Photo</div>
											{errors.documentPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
										</div>
									</div>
								</div>
							</div>

							<div className='category-claim-wrapper rounded shadow p-2'>
								<div className='category-content__items px-2 md:px-4 border-0'>
									<div className='font-medium text-red-600'>
										<p>Note :</p>
									</div>
									<div className=' text-black font-medium text-sm mx-4'>
										<p>1.ID Proof (DL/ PAN Card/ Passport) </p>
										<p>2.Sim Blocking Proof - Sim card Verification (Within 24 hrs) </p>
										<p>3.All documents uploading Mandatory & should be valid. </p>
										<p>4.Only Handset can be purchased against the claim based on Claim Rate. </p>
										<p>5.Uploaded documents found fake then claim will not be honoured.</p>
									</div>
								</div>
							</div>
						</div>
						
						<div className='mx-4'>
							<Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
						</div>
					</form>
				</section>: 
				<ClaimResponse claimSubmitResponse ={claimSubmitResponse} />
			}
		</>
	)
}

export default TPClaimStep
