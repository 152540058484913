import React, { useRef, useEffect, useState } from 'react'
import { Carousel } from "flowbite-react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../../shared/constants';
import jwt_decode from "jwt-decode";


const HomeBanner = () => {

    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    let token = '';

    const [getOfferDetails,setGetOffereDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const checkOfferDetails = async() => {
        try {
            const response =   await axiosJWT.get('/api/checkOffers',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setGetOffereDetails(response.data.data);
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkOfferDetails();
        // eslint-disable-next-line
    },[])

    if(DEBUG) console.log(getOfferDetails);






    return (
        <>
            { getOfferDetails && getOfferDetails.length > 0? 
                <div className="h-32 sm:h-80 md:h-64 home-banner-wrapper container mx-auto">
                    <Carousel leftControl=" " rightControl=" " slideInterval={5000}>
                        { getOfferDetails && getOfferDetails.map((dataList,idx) => {
                            return ( 
                                <Link to='/offer-detail' state={{"offerDetail":dataList}} key={idx}>
                                    <img src={dataList.offer_banner} className='h-full' alt={dataList.offer_name} />
                                </Link>
                            )
                        })}
                    </Carousel>
                </div>
                :''
            }
        </>
    );
};

export default HomeBanner;
