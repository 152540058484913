import React, { useState, useEffect } from "react";
import { Alert } from "flowbite-react"

const AppUpdateAlert = ({msg,errorMsgAlertShow,setErrorMsgAlertShow, callback}) => {
    
    const [alertstatement,setAlertStatement] = useState({
        alertMsg: msg,
        visible: errorMsgAlertShow && msg ? true : false,
    })
    
    useEffect( () => {
        console.log('useEffect');
        if(setErrorMsgAlertShow){
            setTimeout(() => {
                
                setAlertStatement({
                    alertMsg: '',
                    visible: false
                })
                if(callback) callback()
                setErrorMsgAlertShow(false);
            }, 5000)
        }
    }, [setErrorMsgAlertShow])

    return (
        <>
            { alertstatement.visible ? 
                <Alert color="failure">
                    <span>
                        <span className="font-medium">  {alertstatement.alertMsg} </span>
                    </span>
                </Alert>:''
            }
        </>
    )
}

export default AppUpdateAlert