import React, { useState} from 'react';
import { Button } from 'flowbite-react';
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";

const Refund = ({showRepairSubmit,setShowRepairSubmit,claimHistoryDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const imageMimeType = /image\/(png|jpg|jpeg)/i;

	const navigate = useNavigate();
	const [buttonStatus,setButtonStatus] = useState(false);
	// Form 
    const { register, handleSubmit, formState: { errors } } = useForm();

	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const [bankPhoto,setBankPhoto] = useState();
    const [bankPhotoFinal,setBankPhotoFinal] = useState();

    const handleAccessoriesImageUpload = async(e,type) => {
		e.preventDefault()
		const file = e.target.files[0];
		if(DEBUG) console.log(file);
		var imageData = new FormData();
		imageData.append('purpose', 'tmpclaim');
		imageData.append('attachment', file);
		if(DEBUG) console.log(imageData);
		setShowPleaseWaitPopup(true)
		if(DEBUG) console.log(type);
		try {
			if (!file.type.match(imageMimeType)) {
				setErrorMsgAlertText("Image mime type is not valid");
                setErrorMsgAlertShow(true)
                setShowPleaseWaitPopup(false);
				return;
			}

            if(file.size > 1000000){
				setErrorMsgAlertText("Please Upload less than 1 MB Image");
				setErrorMsgAlertShow(true);
				setShowPleaseWaitPopup(false);
				return;
			}

            const response =  await axiosJWT.post('/api/uploadFile',imageData,{headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            setShowPleaseWaitPopup(false);
            if(DEBUG) console.log(response.data.status);
			if(DEBUG) console.log(response.data.data);
            if(response.status === 200 ){
                if(response.data.status === true){
					if(type === 'bank'){
						setBankPhoto(URL.createObjectURL(file));
						setBankPhotoFinal(response.data.data);
					} 
                } else {
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                }
            } else {
                setErrorMsgAlertText(response.status);
                setErrorMsgAlertShow(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response);
                setErrorMsgAlertShow(true)
            }
        }
	}

	const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
		setButtonStatus(true);

		try {
			const response = await axiosJWT.post('/api/repairClaimRefundUpdateSubmit',{
				passbookPhoto:bankPhotoFinal,
                claimId:claimHistoryDetails.claim_id,
                repairStatus:claimHistoryDetails.repair_status,
                accountName:data.account_name,
                accountNo:data.account_no,
                bankName:data.bank_name,
                bankIfsc:data.bank_ifsc,
                bankBranch:data.bank_branch,
                amount:data.amount,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
                    navigate('/claim-history');
				} else {
					setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
                setErrorMsgAlertShow(true)
			}
		}
	}

    return (
        <>
            { !showRepairSubmit ?
                <div className='m-4 flex justify-end items-center'>
                    <Button onClick={() =>setShowRepairSubmit(true) } className='w-full px-5 rounded-lg' color="dark" > Add Bank Details </Button>
                </div>:''
            }
            {showRepairSubmit ?
                <section className='category-wrapper mt-header py-4'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className='font-medium text-black'>
                                <p>Bank Details :</p>
                            </div>
                            
                            <div className='category-content__items px-2 md:px-4 border-0'>

                                <div className="relative mb-3">
                                    <input type="text" id="account_name" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("account_name", { required: true, pattern: /^[A-Z a-z]+$/i })} />
                                    <label htmlFor="account_name" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">  Name As in Bank Account </label>
                                    {errors.account_name && <p className='text-left mt-2 text-rose-600 text-xs'> Please Fill Details </p>}
                                </div>
                                

                                <div className="relative mb-3">
                                    <input type="text" id="bank_name" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("bank_name", { required: true, pattern: /^[A-Z a-z]+$/i })} />
                                    <label htmlFor="bank_name" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"> Name of the Bank </label>
                                    {errors.bank_name && <p className='text-left mt-2 text-rose-600 text-xs'>  Please Fill Details </p>}
                                </div>
                                

                                <div className="relative mb-3">
                                    <input type="text" id="account_no" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("account_no", { required: true, pattern: /^[0-9]+$/i })} />
                                    <label htmlFor="account_no" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"> Account Number </label>
                                    {errors.account_no && <p className='text-left mt-2 text-rose-600 text-xs'>  Please Fill Details </p>}
                                </div>
                                

                                <div className="relative mb-3">
                                    <input type="text" id="bank_branch" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("bank_branch", { required: true, pattern: /^[A-Z a-z]+$/i })} />
                                    <label htmlFor="bank_branch" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Bank Branch</label>
                                    {errors.bank_branch && <p className='text-left mt-2 text-rose-600 text-xs'> Please Fill Details </p>}
                                </div>
                                
                                
                                <div className="relative mb-3">
                                    <input type="text" id="bank_ifsc" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("bank_ifsc", { required: true, pattern: /^[A-Z 0-9]+$/i })} />
                                    <label htmlFor="bank_ifsc" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"> Bank IFSC Code</label>
                                    {errors.bank_ifsc && <p className='text-left mt-2 text-rose-600 text-xs'> Please Fill Details </p>}
                                </div>
                                

                                <div className="relative mb-3">
                                    <input type="text" id="amount" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("amount", { required: true, pattern: /^[0-9]+$/i , minLength:1, maxLength: 5 })} />
                                    <label htmlFor="amount" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"> Amount </label>
                                    {errors.amount && <p className='text-left mt-2 text-rose-600 text-xs'> Please Fill Details </p>}
                                </div>
                                

                                <div className="grid grid-cols-2 gap-2 my-6">
                                    <div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
                                        <div className='img-upload__claim relative w-full h-full'>
                                            <input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("bankPhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'bank')})}  />
                                            <span className='camera-icon__upload w-full h-full text-center'>
                                                {bankPhoto ? 
                                                    <img src={bankPhoto} className="w-full h-full text-center" />
                                                    :
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                        <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                                    </svg>
                                                }
                                            </span>
                                        </div>
                                        <div className='text-center mt-3 text-sm text-gray-400 font-medium'>Cancelled Cheque / Bank Passbook</div>
                                        {errors.bankPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='mx-4'>
                            <Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
                        </div>
                    </form>
                </section>:''
            }
        </>
    )
}

export default Refund
