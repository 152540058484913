import React from 'react'
import NotFound from '../Images/no-record.svg';
import { Button } from 'flowbite-react';
import {useNavigate} from 'react-router-dom';

const NoRecord = ({...props}) => {

    const navigate = useNavigate()
    const handleRedirect = () => {
        navigate('/dashboard');
    }

    return (
        <div className="px-4 mt-header mt-4">
            <div className='flex flex-col justify-center h-full md:w-3/5 md:mx-auto'>
                <div className='not-found__media'>
                    <h4 className='text-center font-semibold text-2xl text-gray-500 mb-3'>{props?.title && props.title ? props.title: 'No Record Found'}</h4>
                    <img src={NotFound} className='m-auto' alt='Not Found' />
                </div>
                <div className='not-found__content text-center'>
                    <Button className='w-full' color='dark' onClick={() => handleRedirect() }>
                        Go to  Home
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NoRecord


