import React, { useState, useEffect } from "react";
import { Spinner, Modal } from "flowbite-react";

const PleaseWait = ({showPleaseWaitPopup}) => {

    const [visible, setVisible] = useState(false);
    useEffect( ()=>{
        if(showPleaseWaitPopup){
            setVisible(true);
        }
        return;
    }, [showPleaseWaitPopup])

    return (
        <>  
            <Modal show={visible} position="center" className='modal-center' centered="true" backdrop="static">
                <Modal.Body>
                    <>
                        <Spinner  color="failure" aria-label="Page Loader" size="xl" /> Please Wait....
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PleaseWait