import React, { useRef, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../../shared/constants';
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from 'flowbite-react';

const OfferDetails = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

    const dataFetchedRef = useRef(false);

    // Form 
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const navigate = useNavigate();
	const location = useLocation();
	if(DEBUG) console.log(location.state.offerDetail);

	const offerDetail = location.state.offerDetail;

	if(!offerDetail){
		navigate('/offers');
	}

    let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    
    const[storeDetails] = useState([]);
    const[brandDetails] = useState([]);
    const[modelDetails] = useState([]);

    const[couponHistoryDetail]= useState([]);

    const[scratchCouponDetails,setScratchCouponDetails]= useState();

    const [showCouponHistory,setShowCouponHistory] = useState(false);

    const [showCoupon,setShowCoupon] = useState(false);

    const getOfferHistory = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.post('/api/couponHistory',{
                offerId:offerDetail.offer_id,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    if(response.data.data.length > 0 ){
                        setShowCouponHistory(true);
                        response.data.data.map((offerHistory) => {
                            const arr = { model_name:offerHistory.model_name,created_at:offerHistory.created_at,discount_amount:offerHistory.discount_amount,coupon_code:offerHistory.coupon_code,coupon_status:offerHistory.coupon_status };
                            couponHistoryDetail.push(arr);
                        })
                    }
                    
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const checkStoreDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.post('/api/checkOfferEligibleStore',{
                offerId:offerDetail.offer_id,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.store_code+'-'+docType.store_name };
						storeDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                    navigate('/offers');
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkStoreDetails();
        getOfferHistory();
        // eslint-disable-next-line
    },[])

    const [selectedStoreOptions, setSelectedStoreOptions] = useState();
    
    const checkBrandDetails = async(data) => {

        setValue('store_id',data.value);
        setShowPleaseWaitPopup(true)
        setSelectedStoreOptions(data)
		
        
        try {
            const response =   await axiosJWT.post('/api/checkOfferEligibleBrand',{
                offerId:offerDetail.offer_id,
                storeId:data.value
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    brandDetails.length = 0;
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.make_name };
						brandDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const [selectedBrandOptions, setSelectedBrandOptions] = useState();
    const checkModelDetails = async(data) => {
        setShowPleaseWaitPopup(true)
        setSelectedBrandOptions(data)
		setValue('make_id',data.value);
        try {
            const response =   await axiosJWT.post('/api/checkOfferEligibleModel',{
                offerId:offerDetail.offer_id,
                storeId:selectedStoreOptions.value,
                makeId:data.value,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    modelDetails.length = 0;
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.model_name };
						modelDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const [selectedModelOptions, setSelectedModelOptions] = useState();
    const handleModel = (data) => {
		console.log(data);
		setSelectedModelOptions(data)
		setValue('model_id',data.value);
	}

    if(DEBUG) console.log('showCoupon '+showCoupon);
    if(DEBUG) console.log('showCouponHistory '+showCouponHistory);

    const showGenerateCoupon = () => {
        setShowCouponHistory(false);
        setShowCoupon(false);
    }
    
    const onSubmit = async(data) => {
		// if(DEBUG) console.log(date);
		setShowPleaseWaitPopup(true);
		try {
			const response = await axiosJWT.post('/api/generateCouponCode',{
                offerId:offerDetail.offer_id,
                storeId:data.store_id,
                makeId:data.make_id,
                modelId:data.model_id,
            },{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response.data);
			if(response.status == 200 ){
				if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data.coupon_code);
                    setShowCoupon(true);
                    setShowCouponHistory(false);
                    const arr = { coupon_code: response.data.data.coupon_code, amount:response.data.data.amount,expired_at:response.data.data.expired_at };
					setScratchCouponDetails(arr);
				} else {
					if(DEBUG) console.log(response.data.msg);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
				}
			} 
		} catch (error){
			if (error.response) {
                if(DEBUG) console.log(error.response.data.msg);
			}
		}
	}

    // const settings = {
    //     image: Banner1,
    //     finishPercent: 50,
    //     onComplete: () => console.log('The card is now clear!')
    // };

    return (
        <>  
            { !showCoupon && !showCouponHistory ?
                <div className='container h-full' style={{  backgroundImage:`url(${offerDetail.offer_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4'>
                        <img src={offerDetail.offer_top_bg} />
                        <div className='flex flex-col h-full px-4 justify-between'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="store_id" id="store_id" {...register("store_id", { required: true})} options={storeDetails} value={selectedStoreOptions} onChange={checkBrandDetails} noOptionsMessage={'Please Select'} required />
                                        <label htmlFor="store_id" className="absolute text-sm  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.field_color}}>Store</label>
                                        {errors.store_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Store </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="make_id" id="make_id" options={brandDetails} value={selectedBrandOptions} required {...register("make_id", { required: true})} onChange={checkModelDetails} noOptionsMessage={'Please Select'}  />
                                        <label htmlFor="make_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.field_color}}>Brand</label>
                                        {errors.make_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Brand </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="model_id" id="model_id" options={modelDetails} value={selectedModelOptions} noOptionsMessage={'Please Select'} required {...register("model_id", { required: true})} onChange={handleModel}  />
                                        <label htmlFor="model_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.field_color}}>Model</label>
                                        {errors.model_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Model </p>}
                                    </div>
                                </div>

                                
                                <div className='mb-6 mt-6'>
                                    <Button type="submit" className='w-full' color="dark" style={{color:offerDetail.button_text_color,backgroundColor:offerDetail.button_bg_color}}>
                                        Continue
                                    </Button>
                                </div>

                                <div className='mb-6 mt-6 text-red-600 font-medium text-sm mx-4' style={{color:offerDetail.field_color}} >
                                    <p> Note : {offerDetail.offer_notes} </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>:''
            }

            { showCoupon && !showCouponHistory ?
                <div className='w-full category-claim-wrapper rounded shadow p-4' style={{ backgroundImage:`url(${offerDetail.offer_coupon_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', }}  >
                    <div style={{color:offerDetail.date_color}}>
                        {scratchCouponDetails?.expired_at}
                    </div>
                    <div>
                        <p className='content-catg__details grid grid-cols-3 gap-3 my-2 text-right'>
                            <span style={{color:offerDetail.coupon_color}} className="text-left">Coupon Code</span>
                            <span className='text-black text-lg' style={{color:offerDetail.coupon_text_color}} >{scratchCouponDetails?.coupon_code}</span>
                            <span className='text-black' style={{color:offerDetail.amount_color}} >{ '₹'+ scratchCouponDetails?.amount }</span>
                        </p>
                    </div>
                </div>:''
            }

            { !showCoupon && showCouponHistory &&  couponHistoryDetail.length > 0 ?
                <div className='container h-full' style={{  backgroundImage:`url(${offerDetail.offer_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>

                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 px-4 mb-4 pt-4'>
                        { couponHistoryDetail && couponHistoryDetail.map((dataList,idx) => {
                            return ( 
                                <div className='category-claim-wrapper rounded shadow p-4' key={idx} style={{  backgroundImage:`url(${offerDetail.offer_coupon_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}  >
                                    <div style={{color:offerDetail.date_color}}>
                                        {dataList.created_at}
                                    </div>
                                    <div  style={{color:offerDetail.model_color}}>
                                        {dataList.model_name}
                                    </div>
                                    <div>
                                        <p className='content-catg__details grid grid-cols-3 gap-3 my-2 text-right'>
                                            <span style={{color:offerDetail.coupon_color}} className="text-left">Coupon Code</span>
                                            <span className='text-black text-lg' style={{color:offerDetail.coupon_text_color}} >{dataList.coupon_code}</span>
                                            <span className='text-black' style={{color:offerDetail.amount_color}} >{ '₹'+dataList.discount_amount }</span>
                                        </p>
                                        <p className='grid grid-cols-2 gap-2 my-2'>
                                            <span className='text-black' style={{color:offerDetail.status_color}} >{ dataList.coupon_status }</span>
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        <div className='mb-6 mt-6'>
                            <Button type="button" className='w-full' style={{color:offerDetail.button_text_color,backgroundColor:offerDetail.button_bg_color}} onClick={() => showGenerateCoupon() } >
                                Generate Coupon
                            </Button>
                        </div>
                    </div>
                </div>:''
            }
        </>
    )
}

export default OfferDetails