import React,{useRef, useState,useEffect} from "react";
import { Link } from "react-router-dom";
import sangeethaLogo from '../../Images/sangeetha-logo.svg'
import { DEBUG } from "../../shared/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AllCategories = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children, isOpen, setIsOpen,customerDetails } = props;	
    if(DEBUG) console.log(customerDetails);


    const navigate = useNavigate();

    const dataProfiledRef = useRef(false);
    // let expire = '';
    let token = '';

    const [personDetails,setPersonDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

	const getProfileDetails = async() => {
        try {
            const response =   await axiosJWT.get('/api/getProfileInfo',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setPersonDetails(response.data.data);
                }
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataProfiledRef.current) return;
        dataProfiledRef.current = true;
        getProfileDetails();
        // eslint-disable-next-line
    },[])

    return (
        <>
            <main className={ "fixed overflow-hidden z-99 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out header-drawer " + (isOpen ? " transition-opacity opacity-100 duration-500 translate-x-0  " : " transition-all delay-500 opacity-0 -translate-x-full  ") } >
                <section className={ " w-screen max-w-xs left-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform header-drawer-section" + (isOpen ? " translate-x-0" : "-translate-x-full")}>
                    <article className="relative w-screen max-w-xs flex flex-col space-y-6 overflow-y-scroll h-full header-drawer-article">
						<div className='header-navigation btn btn-dark-custom w-100'>
                            <Link to='/dashboard' className='flex flex-col items-start justify-between'>
                                <div className="header-navigation-logo h-10 w-1/2 flex mb-6">
                                    <img src={sangeethaLogo} className="w-full" alt="" />
                                </div>
                                <div className='flex items-center'>
                                    <span className='ml-2'>Hello <span>{personDetails?.customerName}</span></span>
                                </div>
                            </Link>
						</div>
                        {children}
                    </article>
                </section>

                {/* close backdrop To close Hamburger Menu */}
                <section
                    className="w-screen h-full cursor-pointer"
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >Close</section>
            </main>
        </>
    );
};

export default AllCategories;
