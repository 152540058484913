import React ,{useState, useEffect,useRef } from 'react'
import { Button,Textarea } from "flowbite-react";
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import ReactStars from "react-rating-stars-component";

const PPFeedback = ({claimHistoryDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

	const [buttonStatus,setButtonStatus] = useState(false);
	// Form 
    const { register, handleSubmit, formState: { errors } } = useForm();
	
	const navigate = useNavigate();
	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const [rating,setRating] = useState(0);

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRating(newRating);
    };

    const dataFetchedRef = useRef(false);
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        // eslint-disable-next-line
    },[])
    if(DEBUG) console.log(claimHistoryDetails);
    const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
		setButtonStatus(true);
		setShowPleaseWaitPopup(true);
	
        try {
			const response = await axiosJWT.post('/api/ppFeedbackSubmit',{
				ppId:claimHistoryDetails.id,
				rating:rating,
				message:data.note,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response);
			if(response.status === 200 ){
				// if(response.data.status === true){
				// 	if(DEBUG) console.log(response.data.data);
					navigate('/coupon');
				// } else {
				// 	setErrorMsgAlertText(response.data.msg);
                //     setErrorMsgAlertShow(true)
				// }
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
                setErrorMsgAlertShow(true)
			}
		}
	}

    const RatingEmoji = {
		"1": "Worst!",
		"2": "Just Ok!",
		"3": "Average!",
		"4": "Good!", 
		"5": "Excellent!"
	};


    return (
        <>
            <section className='category-wrapper mt-header py-4'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                        <div className=' text-blue-600 justify-center font-medium text-sm'>
                            <p> We would like to Known the rating of price protection</p>
                        </div>

                        <div className='category-claim-wrapper rounded shadow p-2'>
                            <div className='category-content__items px-2 md:px-4 border-0'>
                                <div className="flex justify-center items-center gap-2">
                                    <div className='font-medium text-sm'>
                                        <p>{claimHistoryDetails?.model}</p>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center gap-2">
                                    <ReactStars count={5} onChange={ratingChanged} size={40} isHalf={false} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>} activeColor="#ffd700" />
                                </div>
                            </div>

                            {rating === 0 ? null :
                                <div className='rating__val text-center mt-8'>
                                {rating === 1 ?  
                                    <>
                                        <svg stroke="currentColor" fill="#f74b3a" strokeWidth="0" className=' mx-auto mb-4' viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM4.053 4.276a.5.5 0 0 1 .67-.223l2 1a.5.5 0 0 1 .166.76c.071.206.111.44.111.687C7 7.328 6.552 8 6 8s-1-.672-1-1.5c0-.408.109-.778.285-1.049l-1.009-.504a.5.5 0 0 1-.223-.67zm.232 8.157a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 1 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5 0-.247.04-.48.11-.686a.502.502 0 0 1 .166-.761l2-1a.5.5 0 1 1 .448.894l-1.009.504c.176.27.285.64.285 1.049 0 .828-.448 1.5-1 1.5z" />
                                        </svg>
                                    </> : rating === 2 ?
                                    <>
                                        <svg stroke="currentColor" fill="#ff6501" strokeWidth="0" className=' mx-auto mb-4' viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                        </svg>
                                    </> : rating === 3 ?
                                    <>	
                                        <svg stroke="currentColor" fill="#ffd700" strokeWidth="0" className=' mx-auto mb-4' viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                        </svg>
                                    </> : rating === 4 ?
                                    <>
                                        <svg stroke="currentColor" fill="#3bff00" strokeWidth="0" className=' mx-auto mb-4' viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                        </svg>
                                    </> : rating === 5 ?
                                    <>
                                        <svg stroke="currentColor" fill="#246004" strokeWidth="0" className=' mx-auto mb-4' viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434zm6.559 5.448a.5.5 0 0 1 .548.736A4.498 4.498 0 0 1 7.965 13a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242.63 0 1.46-.118 2.152-.242a26.58 26.58 0 0 0 1.109-.224l.067-.015.017-.004.005-.002zm-.07-5.448c1.397-.864 3.543 1.838-.953 3.434-3.067-3.554.19-4.858.952-3.434z" />
                                        </svg>
                                    </> : null
                                }
                                    { RatingEmoji[rating] }
                                </div>
                            }

                        </div>
                        

                        <div className='category-content__items px-2 md:px-4 border-0'>
                            <div className="flex items-center gap-2">
                                <Textarea id="note" placeholder="Leave a comment..." rows={4} {...register("note", { required: false })} />
                            </div>
                            {errors.note && <p className='text-left mt-2 text-rose-600 text-xs'> Please Check </p>}
                        </div>
                    </div>
                    <div className='mx-4'>
                        <Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
                    </div>
                </form>
            </section>
        </>
    )
}

export default PPFeedback