import React, { useRef, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../../shared/constants';
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from 'flowbite-react';
// import CancelClaim from './CouponCancel';

const WalletOfferDetails = ({setErrorMsgAlertShow, setErrorMsgAlertText, setShowPleaseWaitPopup}) => {

    const dataFetchedRef = useRef(false);

    // Form 
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const navigate = useNavigate();
	const location = useLocation();
	if(DEBUG) console.log(location.state.offerDetail);

	const offerDetail = location.state.offerDetail;

    const [buttonStatus,setButtonStatus] = useState(true);

    const [showCalculation,setShowCalculation] = useState(false);

	if(!offerDetail){
		navigate('/offers');
	}

    let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    
    const[storeDetails] = useState([]);
    const[productDetails] = useState([]);
    const[brandDetails] = useState([]);
    const[modelDetails] = useState([]);

    const[couponHistoryDetail]= useState([]);

    const [beforeCouponDetails,setBeforeCouponDetails] = useState();
    const[scratchCouponDetails,setScratchCouponDetails]= useState();

    const [showCouponHistory,setShowCouponHistory] = useState(false);

    const [showCoupon,setShowCoupon] = useState(false);

    const getOfferHistory = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.post('/api/couponSuperCashHistory',{
                offerId:offerDetail.supercash_offer_id,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data.supercash_history.length);
                    couponHistoryDetail.length = 0
                    if(response.data.data.supercash_history.length > 0 ){
                        setShowCouponHistory(true);
                        response.data.data.supercash_history.map((offerHistory) => {
                            const arr = { 
                                supercash_history_id:offerHistory.supercash_history_id,
                                model_name:offerHistory.model_name,
                                created_at:offerHistory.created_at,
                                discount_amount:offerHistory.discount_amount,
                                coupon_code:offerHistory.coupon_code,
                                coupon_status:offerHistory.coupon_status 
                            };

                            couponHistoryDetail.push(arr);
                        })
                    }
                    
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const checkStoreDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleStore',{
                offerId:offerDetail.supercash_offer_id,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.store_code+'-'+docType.store_name };
						storeDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                    navigate('/wallet');
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkStoreDetails();
        getOfferHistory();
        // eslint-disable-next-line
    },[])

    const [selectedStoreOptions, setSelectedStoreOptions] = useState();
    
    const checkProductDetails = async(data) => {

        setValue('store_id',data.value);
        setShowPleaseWaitPopup(true)
        setSelectedStoreOptions(data)
		
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleProduct',{
                offerId:offerDetail.supercash_offer_id,
                storeId:data.value
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.product_name };
						productDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 
    
    const [selectedProductOptions, setSelectedProductOptions] = useState();

    const checkBrandDetails = async(data) => {

        setValue('product_id',data.value);
        setShowPleaseWaitPopup(true)
        setSelectedProductOptions(data)
		setSelectedBrandOptions('');
        
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleBrand',{
                offerId:offerDetail.supercash_offer_id,
                productId:data.value
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    brandDetails.length = 0;
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.make_name };
						brandDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const [selectedBrandOptions, setSelectedBrandOptions] = useState();

    const checkModelDetails = async(data) => {
        setShowPleaseWaitPopup(true)
        setSelectedBrandOptions(data)
		setValue('make_id',data.value);
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleModel',{
                offerId:offerDetail.supercash_offer_id,
                productId:selectedProductOptions.value,
                storeId:selectedStoreOptions.value,
                makeId:data.value,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    modelDetails.length = 0;
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.model_name };
						modelDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const [selectedModelOptions, setSelectedModelOptions] = useState();
    const handleModel =  async(data) => {
		console.log(data);
		setSelectedModelOptions(data)
		setValue('model_id',data.value);

        try {
            const response =   await axiosJWT.post('/api/checkSuperCashCalculate',{
                offerId:offerDetail.supercash_offer_id,
                productId:selectedProductOptions.value,
                storeId:selectedStoreOptions.value,
                makeId:selectedBrandOptions.value,
                modelId:data.value,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setButtonStatus(false);
                    const arr = { current_price: response.data.data.current_price, supercash_discount:response.data.data.supercash_discount,total_amount:response.data.data.total_amount };
					setBeforeCouponDetails(arr);
                    setShowCalculation(true);
                } else {
                    setShowCalculation(false);
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
        
	}

    if(DEBUG) console.log('showCoupon '+showCoupon);
    if(DEBUG) console.log('showCouponHistory '+showCouponHistory);

    const [formDisable,setFormDisable] = useState(false);

    const showGenerateCoupon = () => {
        setShowCouponHistory(false);
        setShowCoupon(false);
    }

    const generateOTP = async() => {
        setShowPleaseWaitPopup(true);
        try {
			const response = await axiosJWT.post('/api/generateSuperCashOTP',{
                offerId:offerDetail.supercash_offer_id,
            },{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response.data);
			if(response.status == 200 ){
				if(response.data.status === true){
                    setFormDisable(true);
				} else {
                    setFormDisable(false);
					if(DEBUG) console.log(response.data.msg);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
				}
			} 
		} catch (error){
			if (error.response) {
                if(DEBUG) console.log(error.response.data.msg);
			}
		}
    }


    
    const onSubmit = async(data) => {
       
		if(DEBUG) console.log(data);
		
		try {
			const response = await axiosJWT.post('/api/generateSuperCashCouponCode',{
                offerId:offerDetail.supercash_offer_id,
                storeId:data.store_id,
                productId:data.product_id,
                makeId:data.make_id,
                modelId:data.model_id,
                otp:data.otp
            },{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response.data);
			if(response.status == 200 ){
				if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data.coupon_code);
                    setShowCoupon(true);
                    setShowCouponHistory(false);
                    const arr = { coupon_code: response.data.data.coupon_code, amount:response.data.data.amount,expired_at:response.data.data.expired_at };
					setScratchCouponDetails(arr);
				} else {
					if(DEBUG) console.log(response.data.msg);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
				}
			} 
		} catch (error){
			if (error.response) {
                if(DEBUG) console.log(error.response.data.msg);
			}
		}
        
	}

    // if(DEBUG) console.log(couponHistoryDetail);
    // const [showCancelCoupon,setShowCancelCoupon] = useState(false);
    // const [cancelOfferDetails,setCancelOfferDetails] = useState(false);
    // const cancelCouponCode = (data) => {
    //     if(DEBUG) console.log(data);
    //     setCancelOfferDetails(data);
    //     setShowCancelCoupon(true);
    // }

    return (
        <>  
            {/* Form Field */}
            { !showCoupon && !showCouponHistory ?
                <div className='container h-full' style={{  backgroundImage:`url(${offerDetail.supercash_bg_url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4'>
                        <img src={offerDetail.offer_top_bg} />
                        <div className='flex flex-col h-full px-4 justify-between'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="store_id" id="store_id" {...register("store_id", { required: true})} options={storeDetails} value={selectedStoreOptions} onChange={checkProductDetails} required isDisabled={formDisable} />
                                        <label htmlFor="store_id" className="absolute text-sm  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.color_setting.field_color}}>Store</label>
                                        {errors.store_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Store </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="product_id" id="product_id" options={productDetails} value={selectedProductOptions} required {...register("product_id", { required: true})} onChange={checkBrandDetails} isDisabled={formDisable}  />
                                        <label htmlFor="product_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.color_setting.field_color}}>Product</label>
                                        {errors.product_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Product </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="make_id" id="make_id" options={brandDetails} value={selectedBrandOptions} required {...register("make_id", { required: true})} onChange={checkModelDetails} isDisabled={formDisable} />
                                        <label htmlFor="make_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.color_setting.field_color}}>Brand</label>
                                        {errors.make_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Brand </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="model_id" id="model_id" options={modelDetails} value={selectedModelOptions} required {...register("model_id", { required: true})} onChange={handleModel} isDisabled={formDisable}  />
                                        <label htmlFor="model_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" style={{color:offerDetail.color_setting.field_color}}>Model</label>
                                        {errors.model_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Model </p>}
                                    </div>
                                </div>

                                { showCalculation && ( 
                                    <>
                                        <div className="mb-6 mt-6 flex items-start flex-col gap-2 border-dashed bg-amber-100">
                                            <div className='m-2'>
                                                <p className='content-catg__details grid grid-cols-2 gap-3 my-2 text-right'>
                                                    <span className="text-left">Current Price</span>
                                                    <span className="text-left">{ '₹'+beforeCouponDetails?.current_price }</span>
                                                </p>
                                                <p className='content-catg__details grid grid-cols-2 gap-3 my-2 text-right'>
                                                    <span className="text-left">SuperCash Discount </span>
                                                    <span className="text-left">{ '₹'+beforeCouponDetails?.supercash_discount }</span>
                                                </p>
                                                <p className='content-catg__details grid grid-cols-2 gap-3 my-2 text-right'>
                                                    <span className="text-left">Price You Pay </span>
                                                    <span className="text-left">{ '₹'+beforeCouponDetails?.total_amount }</span>
                                                </p>
                                            </div>
                                        </div>
                                        { !formDisable && (
                                            <div className='mb-6 mt-6'>
                                                <Button className='w-full' color="dark" disabled={buttonStatus} style={{color:offerDetail.button_text_color,backgroundColor:offerDetail.color_setting.button_bg_color}} onClick={generateOTP}>
                                                    Generate OTP
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                )}

                                { showCalculation && formDisable && (
                                    <>
                                        <div className="relative mb-3">
                                            <input type="text" id="otp" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("otp", { required: true, pattern: /^[0-9]+$/i })} maxLength={'4'} />
                                            <label htmlFor="otp" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">OTP</label>
                                            {errors.otp && <p className='text-left mt-2 text-rose-600 text-xs'> allow only Number </p>}
                                        </div>
                                    </>
                                )}
                                
                                { showCalculation && formDisable && (
                                    <div className='mb-6 mt-6'>
                                        <Button type="submit" className='w-full' color="dark" disabled={buttonStatus} style={{color:offerDetail.button_text_color,backgroundColor:offerDetail.color_setting.button_bg_color}}>
                                            Generate Coupon
                                        </Button>
                                    </div>
                                )}
                                <div className='mb-6 mt-6 text-red-600 font-medium text-sm mx-4' style={{color:offerDetail.field_color}} >
                                    <p> Note : {offerDetail.offer_notes} </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>:''
            }

            {/* Show Coupon */}
            { showCoupon && !showCouponHistory ?
                <div className='w-full category-claim-wrapper rounded shadow p-4' style={{ backgroundImage:`url(${offerDetail.color_setting.offer_coupon_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', }}  >
                    <div>
                        <p className='content-catg__details grid grid-cols-2 gap-2 my-2 text-right'>
                            <span style={{color:offerDetail.coupon_color}} className="text-left">Coupon Code</span>
                            <span className='text-black text-lg' style={{color:offerDetail.coupon_text_color}} >{scratchCouponDetails?.coupon_code}</span>
                        </p>
                    </div>
                </div>:''
            }

            {/* Coupon History */}
            { !showCoupon && showCouponHistory &&  couponHistoryDetail.length > 0 ?
                <div className='container h-full' style={{  backgroundImage:`url(${offerDetail.supercash_bg_url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
                    <div className='m-4 px-2 content-end  bg-white'>
                        <p className='text-blue-800 font-bold'> SMPL Cash : {'₹'+offerDetail.supercash_amount} </p>
                    </div>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 px-4 mb-4 pt-4'>
                        { couponHistoryDetail && couponHistoryDetail.map((dataList,idx) => {
                            return ( 
                                <div className='category-claim-wrapper rounded shadow p-4' key={idx} style={{  backgroundImage:`url(${offerDetail.offer_coupon_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}  >
                                    <div style={{color:offerDetail.date_color}}>
                                        {dataList.created_at}
                                    </div>
                                    <div  style={{color:offerDetail.model_color}}>
                                        {dataList.model_name}
                                    </div>
                                    <div>
                                        <p className='content-catg__details grid grid-cols-3 gap-3 my-2 text-right'>
                                            <span style={{color:offerDetail.coupon_color}} className="text-left">Coupon Code</span>
                                            <span className='text-black text-lg' style={{color:offerDetail.coupon_text_color}} >{dataList.coupon_code}</span>
                                            <span className='text-black' style={{color:offerDetail.amount_color}} >{ '₹'+dataList.discount_amount }</span>
                                        </p>
                                        <p className='grid grid-cols-2 gap-2 my-2'>
                                            <span className='text-black' style={{color:offerDetail.status_color}} >{ dataList.coupon_status }</span>
                                            {/* { dataList.coupon_status == 'NOT-USED' ? 
                                                <>
                                                    <Button type="button" color="dark" onClick={() => cancelCouponCode(dataList) }  >
                                                        Cancel
                                                    </Button>
                                                </>
                                            :''} */}
                                        </p>

                                    </div>
                                </div>
                            )
                        })}

                        {/* <CancelClaim  showCancelCoupon={showCancelCoupon}  setShowCancelCoupon={setShowCancelCoupon} cancelOfferDetails={cancelOfferDetails} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} setShowPleaseWaitPopup={setShowPleaseWaitPopup}  /> */}

                        <div className='mb-6 mt-6'>
                            <Button type="button" className='w-full' style={{color:offerDetail.button_text_color,backgroundColor:offerDetail.button_bg_color}} onClick={() => showGenerateCoupon() } >
                                Generate Coupon
                            </Button>
                        </div>


                    </div>
                </div>:''
            }
        </>
    )
}

export default WalletOfferDetails