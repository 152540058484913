import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import axios from 'axios';
import { DEBUG, ErrorMessge_400 } from '../../shared/constants';
import jwt_decode from "jwt-decode";

const PhoneDetails = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

	const navigate = useNavigate();
	const location = useLocation();
	if(DEBUG) console.log(location.state.phoneDetail);

	const {phone_details, vas_details, claim_applied} = location.state.phoneDetail;

	if(DEBUG) console.log(phone_details);
	if(DEBUG) console.log(vas_details);
	if(DEBUG) console.log(claim_applied);

	if(location.state.phoneDetail){
		navigate('/purchase-details');
	}
	
	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


	const checkClaim = async(phone_id, package_id,expired,purchaseDetails) => {

		if(DEBUG) console.log(phone_id);
		if(DEBUG) console.log(package_id);
		setShowPleaseWaitPopup(true);
		try {
            const response =   await axiosJWT.post('/api/checkClaimForm',{phone_id:phone_id,package_id:package_id},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
					setShowPleaseWaitPopup(false);
                    if(DEBUG) console.log(response.data.data);
					if(response.data.data.claim_type == 'DP'|| response.data.data.claim_type == 'AB' || response.data.data.claim_type == 'TP'||response.data.data.claim_type == 'ZP' ){
						navigate('/claim-process',{state:{claimType:response.data.data.claim_type, claimCategory:response.data.data.claim_category,expired:expired,purchaseDetails:purchaseDetails,zopper:response.data.data.zopper,typeOfClaim:response.data.data.type_of_claim}})
					}
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
                }
            } else {
                setErrorMsgAlertText(ErrorMessge_400);
				setErrorMsgAlertShow(true);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true);
				setErrorMsgAlertText(error.response.data.msg);
            }
        }
	}

	return (
		<>
			
			<section className='category-wrapper mt-header'>

				<div className={ 'font-medium p-4 pb-0 flex w-full items-center justify-center text-center ' + ((phone_details?.status && phone_details.status === "verified") ? 'text-green-500': 'text-orange-500')}>
					
					{ phone_details?.status && phone_details.status == 'verified' ? 
						<span>
							<svg stroke="currentColor" fill="#0e9f6e" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
								<path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
							</svg>
						</span>:
						<span>
							<svg stroke="currentColor" fill="#FF5A1F" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
								<path d="M464 720a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z"></path>
							</svg>
						</span>
					}
					<span className='ml-2'>{phone_details?.status}</span>
				</div>

				<div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 grid-rows-2- gap-4 p-4 purchased__wrap mb-4'>					
					
					<div className='category-claim-wrapper shadow rounded'>
						<div className='category-content__items px-2 md:px-4'>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Make(Brand)</span>
								<span className='text-black'>{phone_details?.make}</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Model</span>
								<span className='text-black'>{phone_details?.model}</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>IMEI / Serial No</span>
								<span className='text-black'>{phone_details?.imei_number}</span>
							</p>
						</div>
					</div>

					<div className='category-claim-wrapper shadow rounded'>
						<div className='categoryClaim-head__top flex items-center'>
							<svg stroke="#121415" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
								<desc />
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M14 3v4a1 1 0 0 0 1 1h4" />
								<path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
								<line x1="9" y1="7" x2="10" y2="7" />
								<line x1="9" y1="13" x2="15" y2="13" />
								<line x1="13" y1="17" x2="15" y2="17" />
							</svg>
							<span className='ml-1'>Invoice Details</span>
						</div>
						<div className='category-content__items px-2 md:px-4'>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Invoice Number</span>
								<span className='text-black'>{phone_details?.invoice_no}</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Invoice Date</span>
								<span className='text-black'>{phone_details?.invoice_date}</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Invoice Total</span>
								<span className='text-black'>{phone_details?.invoice_total && phone_details.invoice_total > 0 ? '₹'+phone_details?.invoice_total : 0}</span>
							</p>
						</div>
					</div>

					
					<div className='category-claim-wrapper shadow rounded'>
						<div className='categoryClaim-head__top flex items-center'>
							<svg stroke="#121415" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
								<desc />
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M14 3v4a1 1 0 0 0 1 1h4" />
								<path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
								<line x1="9" y1="7" x2="10" y2="7" />
								<line x1="9" y1="13" x2="15" y2="13" />
								<line x1="13" y1="17" x2="15" y2="17" />
							</svg>
							<span className='ml-1'>Price Details</span>
						</div>
						<div className='category-content__items px-2 md:px-4'>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Device Value Without GST</span>
								<span className='text-black'>{phone_details?.handset_value && phone_details.handset_value > 0 ? '₹'+phone_details?.handset_value : 0}</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>GST on Device</span>
								<span className='text-black'>{phone_details?.handset_value_gst && phone_details.handset_value_gst > 0 ? '₹'+phone_details?.handset_value_gst : 0 }</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Discount</span>
								<span className='text-black'>{phone_details?.discount && phone_details.discount > 0 ? '₹'+phone_details?.discount : 0}</span>
							</p>
							<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
								<span>Cashback</span>
								<span className='text-black'>{phone_details?.cashback && phone_details.cashback > 0 ? '₹'+phone_details?.cashback : 0}</span>
							</p>
						</div>
					</div>

					
						
					{ vas_details && vas_details.length > 0 ?
						<div className='category-claim-wrapper shadow rounded'>
							<div className='categoryClaim-head__top flex items-center'>
								<svg stroke="#121415" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
									<desc />
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path d="M14 3v4a1 1 0 0 0 1 1h4" />
									<path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
									<line x1="9" y1="7" x2="10" y2="7" />
									<line x1="9" y1="13" x2="15" y2="13" />
									<line x1="13" y1="17" x2="15" y2="17" />
								</svg>
								<span className='ml-1'>Purchased Plans</span>
							</div>
							
								<ul className='category-content__lists grid grid-cols-1 gap-1 px-2 py-4 md:p-4'>
									{ vas_details && vas_details.map((dataList,key) => (
										<li className='grid grid-cols-3 gap-2 pb-2' key={key}>
											<span className='claim-desc'>{dataList.package_name}</span>
											{ dataList.remaining_days > 0 && (
											<span className='claim-info'>Expires in:<br/> {dataList.remaining_days} Days { Moment(dataList.package_expired_date).format('DD-MM-YYYY')}</span>
											)}
											{ dataList.is_make_claim === true  && dataList.remaining_days > 0?
												<>	
													<div className='claim-btn__div flex justify-end items-center'>
														<button className='claim-btn' onClick={() => checkClaim(phone_details.phone_id,dataList.package_id,dataList.remaining_days,phone_details)}>Claim</button>
													</div>	
												</>: 
												<>
													{ dataList.remaining_days == 0? 
															<div className='claim-btn__div flex justify-end items-center text-red-700 font-medium'>
																Expired
															</div>
														:''
													}
												</>
											}
											
											
										</li>
									))}
									{/* <li className='grid grid-cols-3 gap-2 pb-2'>
										<span className='claim-desc'>Physical Damage Protection</span>
										<div className='claim-btn__div flex justify-end items-center text-red-700 font-medium'>
											Expired
										</div>
									</li>
									<li className='grid grid-cols-3 gap-2'>
										<span className='claim-desc'>Fire Damage Protection</span>
										<div className='claim-btn__div flex justify-end items-center text-red-700 font-medium'>
											Expired
										</div>
									</li>
									<li className='grid grid-cols-3 gap-2'>
										<span className='claim-desc'>Repair & Reimburse</span>
										<div className='claim-btn__div flex justify-end items-center text-red-700 font-medium'>
											Expired
										</div>
									</li> */}
								</ul>
							
						</div>:
						''
					}

				</div>
			</section>
		</>
	)
}

export default PhoneDetails
