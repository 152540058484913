import React, { useState , useRef, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import SangeethaLogo from '../Images/sangeetha-logo.png';
import { useForm } from "react-hook-form";
import Moment, { now } from 'moment';
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import { useReadOTP } from "react-read-otp";
import OtpInput from 'react18-input-otp';
import {useTimer} from '../Util/useTimer';
import jwt_decode from "jwt-decode";
import axios from 'axios';

const Register = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

    const dataGuestTokenRef = useRef(false);
    const navigate= useNavigate();

    const [expire, setExpire] = useState('');
    const [token, setToken] = useState('');
    const [showOtpSection,setShowOtpSection] = useState(false);

    // OTP 
    const [enabledOTP, setEnabledOTP] = useState(false);
    const [otp, setOTP] = useState('');
    
    useReadOTP(setOTP, {
        enabledOTP
    });

    const [resendTime, setResendTime] = useTimer({
        direction: "down",
        multiplier: 1
    });

    const generateGuestToken = async() => {
        try {
            const response =   await axios.get('/api/guestToken').catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(response?.data?.redirect){
                navigate(response.data.redirect)
            }
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            if(DEBUG) console.log(decoded);
            setExpire(decoded.exp);
            // history.push("/dashboard");
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    }

    useEffect(() => {
        if(DEBUG) console.log('error');
        if (dataGuestTokenRef.current) return;
        dataGuestTokenRef.current = true;
        generateGuestToken();
        // eslint-disable-next-line
    },[]) //tokenAPI

    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/guestToken').catch((error) => {
                if(DEBUG) console.log(error)
                navigate('/');
            });
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            if(DEBUG) console.log(decoded);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // const history = useHistory();
    const { register, handleSubmit, watch,setError, getValues, formState: { errors }, clearErrors } = useForm();
    
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmailChange = (e) => {
        if (!isValidEmail(e)) {
            setError('customerEmail', { type: 'custom', message: 'Email is invalid' });
        } else {
            clearErrors('customerEmail'); 
        }
    };

    const handleDate = (e) => {
        if(DEBUG) console.log(e.target.value);
        const currentDate = Moment(now()).format('YYYY-MM-DD');
        const selectedDate = Moment(e.target.value).format('YYYY-MM-DD');

        if(currentDate > selectedDate){
            clearErrors('customerDOB'); 
        } else {
            setError('customerDOB', { type: 'custom', message: 'Please Check Date' });
        }
    }

    const handlePasswordChange = (e) => {
        const password = watch('newPassword')
        // console.log(password);
        if(password != e.target.value){
            setError('newConfirmPassword', { type: 'custom', message: 'custom message' });
        } else {
            clearErrors('newConfirmPassword'); 
        }
    }
    const[submitButton,setSubmitButton] = useState(true);
    // OTP Generate
    const onSubmit = async (data) =>  {
        if(DEBUG) console.log(data);
        setShowPleaseWaitPopup(true)
        try {
            setSubmitButton(false);
            const response =   await axiosJWT.post('/api/sendRegisterOTP',{
                customerNumber: data.customerMobile, customerEmail:data.customerEmail
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.message);
                    // Redirect OTP Section
                    setShowOtpSection(true);
                    setEnabledOTP(true);
                } else {
                    if(DEBUG) console.log(data);
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.message);
                    setSubmitButton(true);
                }
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
                setSubmitButton(true);
            }
            // history.push("/dashboard");
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
                setSubmitButton(true);
            }
        }
    }
    
    // const onSubmit = (data) =>  { 
    //     if(DEBUG)console.log(data) 
    // }

    
    // OTP
    const handleOTPChange = (otp) => {
        if(DEBUG) console.log(otp)
        setOTP(otp);
    }

    useEffect(() => {
        if(otp.length == 4){
            setSubmitOTPButton(true);
        } else {
            setSubmitOTPButton(false);
        }
    },[otp])
    

    const[submitOTPButton,setSubmitOTPButton] = useState(false);

   
    const resendOTP = (data) => {
        console.log(data);
        setShowPleaseWaitPopup(true)
        try {
            setSubmitOTPButton(false);
            axiosJWT.post('/api/resendLoginOTP',{
                customerNumber: '',
                type:'mobile'
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).then((response) => {
                setShowPleaseWaitPopup(false)
                if(response.status === 200 ){
                    if(response.data.status === true){
                        setResendTime(200)
                    } else {
                        if(DEBUG) console.log(response);
                    }
                } else {
                    if(DEBUG) console.log(response);
                }
            })
            .catch((error) => {
                if(DEBUG) console.log(error);
            })
            // history.push("/dashboard");
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    }

    // OTP Verification
    const onSubmitOTP = async (data) => {
        if(DEBUG) console.log(data);
        setShowPleaseWaitPopup(true)
        if(otp.length == 4 && data.customerMobile.length == 10 ){
            try {
                setSubmitOTPButton(false);
                const response =   await axiosJWT.post('/api/verifyRegisterOTP',{
                    customerNumber: data.customerMobile, otpvalue:otp, customerName:data.customerName, customerEmail:data.customerEmail,customerDOB: data.customerDOB, gender:data.gender,newPassword:data.newPassword
                },{headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })
                if(DEBUG) console.log(response.data);
                setShowPleaseWaitPopup(false);
                if(response.status === 200 ){
                    if(response.data.status === true){
                        navigate('/')
                    }
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(ErrorMessge_400);
                    setSubmitButton(true);
                }
                // history.push("/dashboard");
            } catch (error) {
                if (error.response) {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(error.response.data.msg);
                    setSubmitButton(true);
                }
            }
        }
    } 

	return (
		<>
            <div className="sangeetha-care__login flex justify-between flex-col px-4">
                {/* Register Form */}
                {!showOtpSection ? 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="login-header mt-4"> 
                            <Link to='/' className="sangeetha-logo text-center w-full">
                                <img src={SangeethaLogo} className="mx-auto" alt="" />
                            </Link>
                            {/* <h5 className='Login-heading h5 text-center mt-4'>Register</h5> */}
                            <h5 className='Login-heading h5 text-center mt-4'>Welcome to Sangeetha Mobiles</h5>
                            <div className='loggedIn__wrap mt-8'>
                                <div className="relative mb-3">
                                    <input type="text" id="username" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("customerName", { required: true, pattern: /^[A-Za-z]+$/i })} />
                                    <label htmlFor="username" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Customer Name</label>
                                </div>
                                {errors.customerName && <p className='text-left mb-2 text-rose-600 text-xs'> Please Enter the Customer Name </p>}
                                <div className="relative mb-3">
                                    <input type="text" id="customerMobile" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("customerMobile", { required: true, pattern: /^[0-9]+$/i , minLength:10, maxLength: 10 })} />
                                    <label htmlFor="customerMobile" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Mobile Number</label>
                                </div>
                                {errors.customerMobile && <p className='text-left mb-2 text-rose-600 text-xs'> Please enter a valid mobile number (10 digits)</p>}
                                <div className="relative mb-3">
                                    <input type="email" id="customerEmail" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("customerEmail",{ required: true, pattern:/\S+@\S+\.\S+/, onChange:(e) => handleEmailChange(e.target.value)})} />
                                    <label htmlFor="customerEmail" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email</label>
                                </div>
                                {errors.customerEmail && <p className='text-left mb-2 text-rose-600 text-xs'> Please enter a valid Email </p>}
                                <div className='loggedIn__lists'>
                                    <div className='input-group-address label-field'>
                                        <label>Gender</label>
                                        <div className="flex mt-2">
                                            <div className="flex items-center mr-4">
                                                <input id="male" type="radio" value="male"  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" {...register("gender", { required: true })} />
                                                <label htmlFor="male" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"  >Male</label>
                                            </div>
                                            <div className="flex items-center mr-4">
                                                <input id="female" type="radio" value="female"  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" {...register("gender", { required: true })} />
                                                <label htmlFor="female" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" >Female</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                {errors.gender && <p className='text-left mb-2 text-rose-600 text-xs'> Please Select </p>}
                                
                                <div className="relative mb-3">
                                    <input type="date" id="customerDOB" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register ("customerDOB", { required: false, onChange: handleDate })}  min={Moment('1951-01-01').format('YYYY-MM-DD')} max={Moment(now()).format('YYYY-MM-DD') } />
                                    <label htmlFor="customerDOB" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">DOB</label>
                                </div>
                                {errors.customerDOB && <p className='text-left mb-2 text-rose-600 text-xs'> Please Select Correct Date </p>}

                                <div className='grid grid-cols-2 gap-2'>
                                    <div className="relative mb-3">
                                        <input type="password" id="new-password" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("newPassword", { required: true,  minLength: 6 })} autoComplete="off" defaultValue={''} />
                                        <label htmlFor="new-password" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1" >Password</label>
                                        {errors.newPassword && <p className='text-left mb-2 text-rose-600 text-xs'> Password min.Length 6 Letter </p>}
                                    </div>
                                    <div className="relative mb-3">
                                        <input type="password" id="newConfirmPassword" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=""   {...register("newConfirmPassword", {required: true,  minLength: 6, onChange:handlePasswordChange })} autoComplete="off" defaultValue={''} />
                                        <label htmlFor="newConfirmPassword" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Confirm Password</label>
                                        {errors.newConfirmPassword && <p className='text-left mb-2 text-rose-600 text-xs'> Please Enter Confirm Password </p>}
                                        {/* {errorPassword.newConfirmPassword && <span className='err'>{errorPassword.confirmPassword}</span>} */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loginfooter">
                            <div className="footer-links mb-0 login-footerlink">
                                <span className='fontcolor-light'>By submiting,</span>&nbsp;
                                <span className='fontcolor-light'>I agree That above mentioned details are</span>
                            </div>
                            <div className="footer-links login-footerlink"> &nbsp;
                                <span className='fontcolor-light'>as per invoice</span>
                            </div>
                            { submitButton ?
                                <div className="continuelogin_btn mt-4">
                                    <button type="submit" className=" btn btn-block btn-dark-custom w-full block text-center"> Sign Up</button>
                                </div>:''
                            }
                            <div className="mt-2 flex justify-center items-center login-link__section">
                                <div>Already have and account?</div>
                                <Link to='/' className="login-link-text ml-2">Login</Link>
                            </div>
                        </div> 
                    </form>:''
                }
                {/* Show OTP Option */}
                {showOtpSection ?
                    <form onSubmit={handleSubmit(onSubmitOTP)}>
                        <div className="login-header mt-8">
                            <Link to='/' className="sangeetha-logo text-center w-full">
                                <img src={SangeethaLogo} className="mx-auto" alt="" />
                            </Link>
                        
                            <h5 className='Login-heading h5 text-center mt-4'>Welcome to Sangeetha Mobiles</h5>
                            {/* <p className=' Login-info text-center mt-1'>Please enter your mobile number</p> */}
                            <p className=' Login-info text-center mt-1'>Please enter OTP sent to {getValues('customerMobile').substring(0, 2)}******{getValues('customerMobile').substring(8, getValues('customerMobile').length)}.</p>
                            
                            <div className='loggedIn__wrap mt-12'>
                                <div className='loggedIn__lists'>
                                    <div className='input-group-address'>
                                        <div className='login-address__wrap flex gap-3 pb-4'>
                                            <OtpInput
                                                className='w-1/4 justify-center'
                                                    containerStyle="w-full flex justify-center"
                                                    inputStyle='!w-1/2 h-11'
                                                    value= {otp}
                                                    onChange={handleOTPChange}
                                                    numInputs={4}
                                                    shouldAutoFocus = "true"
                                                    isInputNum="true"
                                            />
                                        </div>
                                    </div>
                                    { !submitOTPButton ?
                                    <div className="text-center w-full block font-medium">
                                        <button disabled={resendTime !== 0} className="text-center inline-block underline" onClick={resendOTP}>Resend OTP</button>
                                        {resendTime !== 0 && <span> in {resendTime} seconds</span>}
                                    </div> : ''
                                    }
                                </div> 
                            </div>
                        </div>
                    
                        <div className="loginfooter">
                            <div className="footer-links mb-0 login-footerlink">
                                <span className='fontcolor-light'>By continuing,</span>&nbsp;
                                <span className='fontcolor-light'>I agree to the &nbsp;
                                <Link to='/' className='Linkpolicy'>Privacy Policy</Link></span>
                            </div>
                            <div className="footer-links login-footerlink"> &nbsp;
                                <span className='fontcolor-light'>and &nbsp;
                                <Link to='/' className='Linkpolicy'>Terms & Conditions</Link> </span>
                            </div>
                            { submitOTPButton ?
                                <div className="continuelogin_btn mt-4">
                                    <button type="submit" className=" btn btn-block btn-dark-custom w-full block text-center"> Submit</button>
                                </div>:''
                            }
                        </div> 
                    </form>
                    :''
                }
            </div>  
		</>
	)
}

export default Register