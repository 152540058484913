import React ,{useState, useEffect } from 'react'
import { Modal } from "flowbite-react";
import { DEBUG } from '../../shared/constants';

const ClaimInfo = ({showClaimInfo,setShowClaimInfo}) => {


    const [visible, setVisible] = useState(false);
    
    useEffect(() => {
        if(showClaimInfo){
            setVisible(true);
        }
    },[showClaimInfo])

    if(DEBUG) console.log(showClaimInfo);

    const handleClose = () => {
        setVisible(false)
        setShowClaimInfo(false);
    }

    
    
    return (
        <Modal show={visible} position="center" onClose={() => handleClose() }  popup={true} className='modal-center' >
            <Modal.Header>How to Process Claim</Modal.Header> 
            <Modal.Body>
                <div className="text-center">
                    <img src={showClaimInfo.image} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ClaimInfo