import React, { useState } from 'react';
import { DEBUG } from '../../shared/constants';
import { Button  } from 'flowbite-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";


const Service = ({showRepairSubmit,setShowRepairSubmit,claimHistoryDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const navigate = useNavigate();
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const [buttonStatus,setButtonStatus] = useState(false);

    const [servicePhoto,setServicePhoto]            = useState();
    const [servicePhotoFinal,setServicePhotoFinal] 	= useState();
	
    const [estimatePhoto,setEstimatePhoto]              = useState();
    const [estimatePhotoFinal,setEstimatePhotoFinal]    = useState();

    // Form 
    const { register, handleSubmit, formState: { errors }  } = useForm();
	
	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
	
    const handleAccessoriesImageUpload = async(e,type) => {
		e.preventDefault()
		const file = e.target.files[0];
		if(DEBUG) console.log(file);
		var imageData = new FormData();
		imageData.append('purpose', 'tmpclaim');
		imageData.append('attachment', file);
		if(DEBUG) console.log(imageData);
		if(DEBUG) console.log("hitted");
		if(DEBUG) console.log(type);
        setShowPleaseWaitPopup(true)
		try {
			
            if (!file.type.match(imageMimeType)) {
				setErrorMsgAlertText("Image mime type is not valid");
                setErrorMsgAlertShow(true);
				setShowPleaseWaitPopup(false);
				return;
			}

            if(file.size > 1000000){
				setErrorMsgAlertText("Please Upload less than 1 MB Image");
				setErrorMsgAlertShow(true);
				setShowPleaseWaitPopup(false);
				return;
			}

            const response =  await axiosJWT.post('/api/uploadFile',imageData,{headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            setShowPleaseWaitPopup(false);
            if(DEBUG) console.log(response.data.status);
			if(DEBUG) console.log(response.data.data);
            if(response.status === 200 ){
                if(response.data.status === true){
					if(type === 'service'){
						setServicePhoto(URL.createObjectURL(file));
						setServicePhotoFinal(response.data.data);
					} 

					if(type === 'estimate'){
						setEstimatePhoto(URL.createObjectURL(file));
						setEstimatePhotoFinal(response.data.data);
					} 

                } else {
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                }
            } else {
                setErrorMsgAlertText(response.status);
                setErrorMsgAlertShow(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response);
                setErrorMsgAlertShow(true)
            }
        }
	}

    const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
        setButtonStatus(true);
        if(DEBUG) console.log(servicePhotoFinal);
        if(DEBUG) console.log(estimatePhotoFinal);       
        setShowPleaseWaitPopup(true);
		try {
			const response = await axiosJWT.post('/api/repairClaimServiceUpdateSubmit',{
				servicePhoto:servicePhotoFinal,
				estimatePhoto:estimatePhotoFinal,
                claimId:claimHistoryDetails.claim_id,
                repairStatus:claimHistoryDetails.repair_status,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
            setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
                    navigate('/claim-history');
				} else {
					setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
                setErrorMsgAlertShow(true)
			}
		}
    }

    return (
        <>
            { !showRepairSubmit ?
                <div className='m-4 flex justify-end items-center'>
                    <Button onClick={() =>setShowRepairSubmit(true) } className='w-full px-5 rounded-lg' color="dark" > Add Service Photo  </Button>
                </div>:''
            }
            {showRepairSubmit ?
                <section className='category-wrapper mt-header py-4'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className=' text-red-600 font-medium text-lg'>
                                <p>NOTE: All attachments are mandatory</p>
                            </div>
                            <div className='category-claim-wrapper rounded shadow p-2'>
                                <div className='category-content__items px-2 md:px-4 border-0'>
                                    <div className="grid grid-cols-2 gap-2 my-6">
                                        <div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
                                            <div className='img-upload__claim relative w-full h-full'>
                                                <input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("servicePhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'service')})}  />
                                                <span className='camera-icon__upload w-full h-full text-center'>
                                                    {servicePhoto ? 
                                                        <img src={servicePhoto} className="w-full h-full text-center" />
                                                        :
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                                        </svg>
                                                    }
                                                </span>
                                            </div>
                                            <div className='text-center mt-3 text-sm text-gray-400 font-medium'> Service Photo</div>
                                            {errors.frontPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
                                        </div>

                                        <div className='flex flex-col border-gray-300 rounded border p-2  w-36 h-36 text-center'>
                                            <div className='img-upload__claim relative w-full h-full'>
                                                <input type='file' className='file__upload' accept="image/png, image/jpg, image/jpeg" {...register("estimatePhoto",{ required: true, onChange:(e) => handleAccessoriesImageUpload(e,'estimate')})} />
                                                <span className='camera-icon__upload w-full h-full text-center'>
                                                    {estimatePhoto ? 
                                                        <img src={estimatePhoto} className="w-full h-full text-center" />
                                                        :
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" className='text-gray-500' viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                                        </svg>
                                                    }
                                                </span>
                                            </div>
                                            <div className=' text-center mt-3 text-sm text-gray-400 font-medium'> Estimate Photo</div>
                                            {errors.backPhoto && <p className='text-left mb-2 text-rose-600 text-xs'> Please Upload Image  </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mx-4'>
                            <Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
                        </div>
                    </form>
                </section>: ''
            }
        </>
    )
}

export default Service