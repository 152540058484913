import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ABClaim from '../Components/Claim/ABClaim'
import DPClaim from '../Components/Claim/DPClaim'
import TPClaim from '../Components/Claim/TPClaim'
import TPClaimStep from '../Components/Claim/TPClaimStep'
import ZPClaim from '../Components/Claim/ZPClaim'

const ClaimProcess = ({setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const navigate = useNavigate();

    const location = useLocation();

    const claimType         =  location.state.claimType;
    const claimCategory     =  location.state.claimCategory;
    const claimExpired      =  location.state.expired;
    const purchaseDetails   =  location.state.purchaseDetails;
    const typeOfClaim       =  location.state?.typeOfClaim;
    const zopper            =  location.state?.zopper;

    useEffect(() => {
        if(claimType == ''){
            navigate('/claim');
        }
        //eslint-disable-next-line
    },[claimType])

    return (
        <>  
            { claimType === 'DP' ?
                <DPClaim claimType={claimType} claimCategory={claimCategory}  claimExpired={claimExpired} purchaseDetails={purchaseDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} typeOfClaim={typeOfClaim} /> : ''
            }

            { claimType === 'AB' ?
                <ABClaim claimType={claimType} claimCategory={claimCategory} claimExpired={claimExpired} purchaseDetails={purchaseDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> : ''
            }

            { claimType === 'TP' ?
                <TPClaim  claimType={claimType} claimCategory={claimCategory} claimExpired={claimExpired} purchaseDetails={purchaseDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> : ''
            }

            { claimType === 'TP_STEP2' ?
                <TPClaimStep  claimType={claimType} claimCategory={claimCategory} claimExpired={claimExpired} purchaseDetails={purchaseDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> : ''
            }


            { claimType === 'ZP' ?
                <ZPClaim claimType={claimType} claimCategory={claimCategory} claimExpired={claimExpired} purchaseDetails={purchaseDetails} zopper={zopper} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> : ''
            }
        </>
        
        
    )
}

export default ClaimProcess