import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import jwt_decode from "jwt-decode";
import NoRecord from './NoRecord';


const ClaimHistory = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

    const navigate = useNavigate();

    const dataFetchedRef = useRef(false);
    // let expire = '';
    let token = '';

    const [claimHistoryDetails,setClaimHistoryDetails] = useState([]);
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getClaimHistory = async() => {
        setShowPleaseWaitPopup(true);
        try {
            const response =   await axiosJWT.get('/api/getClaimHistory',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setClaimHistoryDetails(response.data.data.reverse());
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.msg);
                }
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getClaimHistory();
        // eslint-disable-next-line
    },[])

    return (
        <>
            { claimHistoryDetails && claimHistoryDetails.length > 0? 
                <section className="claim-history_wrapper mt-header py-4">
                    <div className="claim-history-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4">
                        { claimHistoryDetails && claimHistoryDetails.map((dataList,idx) => {
                            return ( 
                                <div className="category-claim-wrapper relative rounded shadow" key={idx}>
                                    {/* Ribbon */}
                                    <div className="ribbon-pop flex items-center">
                                        <span className="flex">
                                            <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413V1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <span className="ml-1">{dataList?.claim_type}</span>
                                    </div>
                                    <div className="claim-history__box">
                                        <Link to='/claim-details' state={{claimId:dataList?.claim_id,claimType:dataList?.claim_type,processType:dataList?.process_type}}>
                                            <div className=" claim-history__bg p-2 px-3 mb-3 flex items-center justify-between">
                                                <span>Claim ID: {dataList?.claim_id}</span>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9773 10.0001L6.85232 5.87511L8.03065 4.69678L13.334 10.0001L8.03065 15.3034L6.85232 14.1251L10.9773 10.0001Z" fill="#121415"/>
                                                </svg>
                                            </div>
                                            <div className="px-3">
                                                <p className=" text-gray-400">Applied Date: {dataList?.appliedon}</p>
                                                { dataList?.status && dataList.status === 'Claim Expired' ? 
                                                <>
                                                    <p className="my-2">Status: <span className=" text-red-600">Claim Expired</span></p>
                                                    <p className="text-red-600 font-medium">Sorry! you can&apos;t proceed further, since you have not visited store in48 hours after applying claim.</p>
                                                </>:''}

                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}

                        

                    </div>
                </section>
            :
                <NoRecord title={'No Claim History'} />

            }
        </>
    );
};

export default ClaimHistory;
