import React from 'react'
import { useLocation } from 'react-router-dom'

import DiscountQuestion from '../Components/CustomerSurvey/DiscountQuestion'


const CustomerSurvey = ({setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const location = useLocation();
    const discount = location.state.discount;
    // console.log(discount);
    
    return (
        <>  
            <DiscountQuestion discount={discount} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} />
        </>
    )
}

export default CustomerSurvey