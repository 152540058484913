import React, { useRef, useEffect, useState } from 'react'
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import jwt_decode from "jwt-decode";
import Moment, { now } from 'moment';
import { useForm } from "react-hook-form";


const Profile = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {
	
	const navigate = useNavigate();

    const dataProfiledRef = useRef(false);
    // let expire = '';
    let token = '';

    const [personDetails,setPersonDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

	const getProfileDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/getProfileInfo',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setPersonDetails(response.data.data);
                    setValue('customerName',response.data.data.customerName);
                    setValue('customerEmail',response.data.data.customerEmail);
                    setValue('customerNumber',response.data.data.customerNumber);
                    setValue('customerDOB',response.data.data.customerEmail);
                    setValue('customerAnniversaryDate',response.data.data.customerAnniversaryDate);
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.msg);
                }
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    } 

    useEffect(() => {
        if (dataProfiledRef.current) return;
        dataProfiledRef.current = true;
        getProfileDetails();
        // eslint-disable-next-line
    },[])
	
	const [buttonStatus,setButtonStatus] = useState(true);
	const [editCustomerName, setEditCustomerName] = useState(true);
	const [editCustomerEmail, setEditCustomerEmail] = useState(true);
	const [editCustomerNumber, setEditCustomerNumber] = useState(true);

	const HandleButton = (data) => {

		if(data === 'editCustomerName'){
			setEditCustomerName(false);
		}

		if(data === 'editCustomerEmail'){
			setEditCustomerEmail(false);
		}

		if(data === 'editCustomerNumber'){
			setEditCustomerNumber(false);
		}
		setButtonStatus(false);
	}
	
	const { register, handleSubmit, formState: { errors }, setValue ,setError, clearErrors} = useForm();
	function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmailChange = (e) => {
        if (!isValidEmail(e)) {
            setError('customerEmail', { type: 'custom', message: 'Email is invalid' });
        } else {
            clearErrors('customerEmail'); 
        }
    };

	const handleDate = (e) => {
        if(DEBUG) console.log(e.target.value);
        const currentDate = Moment(now()).format('YYYY-MM-DD');
        const selectedDate = Moment(e.target.value).format('YYYY-MM-DD');

        if(currentDate > selectedDate){
            clearErrors('customerDOB'); 
        } else {
            setError('customerDOB', { type: 'custom', message: 'Please Check Date' });
        }
    }

	const onSubmit = async (data) =>  {
		if(DEBUG) console.log(data);
        setShowPleaseWaitPopup(true)
		try {
            const response =   await axiosJWT.post('/api/updateProfile',{
                // customerNumber: data.customerMobile, 
                // customerEmail:data.customerEmail
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.message);
                } else {
                    if(DEBUG) console.log(data);
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.message);
                }
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
            }
            // history.push("/dashboard");
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
	}


	return (
		<>	
			<div className='container mx-auto'>
				<div className='flex flex-col  px-4'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='mt-6'>
							<div className="relative mb-3">
								<input type="text" id="customerName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer read-only:bg-gray-300" placeholder=" " defaultValue={personDetails?.customerName} readOnly={editCustomerName} {...register("customerName", { required: true, pattern: /^[A-Z a-z ]+$/i })}  />
								<label htmlFor="customerName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Full Name</label>
								<button className="changeBtn" onClick={() => HandleButton('editCustomerName') } >
									<svg stroke="currentColor" fill="#121415" strokeWidth="0" viewBox="0 0 24 24" height="1.4em" width="1.4em" xmlns="http://www.w3.org/2000/svg">
										<path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" />
									</svg>
								</button>
								{ errors.customerName && <p className='text-left mt-2 text-rose-600 text-xs'> Please Check </p>}
							</div>
							<div className="relative mb-3">
								<input type="text" id="customerNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer  read-only:bg-gray-300" placeholder=" " defaultValue={personDetails?.customerNumber} readOnly={editCustomerNumber} {...register("customerNumber", { required: true, pattern: /^[0-9]+$/i , minLength:10, maxLength: 10 })} />
								<label htmlFor="customerNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Mobile Number</label>
								<button className="changeBtn" onClick={ () =>  HandleButton('editCustomerNumber') }>
									<svg stroke="currentColor" fill="#121415" strokeWidth="0" viewBox="0 0 24 24" height="1.4em" width="1.4em" xmlns="http://www.w3.org/2000/svg">
										<path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" />
									</svg>
								</button>
								{ errors.customerNumber && <p className='text-left mt-2 text-rose-600 text-xs'> Please the Mobile Number </p>}
							</div>
							<div className="relative mb-3">
								<input type="text" id="customerEmail" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer  read-only:bg-gray-300" placeholder=" " 
								defaultValue={personDetails?.customerEmail} readOnly={editCustomerEmail} {...register("customerEmail",{ required: true, pattern:/\S+@\S+\.\S+/, onChange:(e) => handleEmailChange(e.target.value)})} />
								<label htmlFor="customerEmail" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email</label>
								<button className="changeBtn" onClick={ () =>  HandleButton('editCustomerEmail') }>
									<svg stroke="currentColor" fill="#121415" strokeWidth="0" viewBox="0 0 24 24" height="1.4em" width="1.4em" xmlns="http://www.w3.org/2000/svg">
										<path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" />
									</svg>
								</button>
								{ errors.customerEmail && <p className='text-left mt-2 text-rose-600 text-xs'> Please the Email ID </p>}
							</div>
							<div className="relative mb-3">
								<input type="date" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" defaultValue={personDetails?.customerDOB ? Moment(personDetails.customerDOB).format('YYYY-MM-DD') : '' }  max={Moment(now()).format('YYYY-MM-DD')} {...register ("customerDOB", { required: false, onChange: handleDate })} />
								<label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">DOB</label>
								{/* <button className="changeBtn">Change</button> */}
                                { errors.customerDOB && <p className='text-left mt-2 text-rose-600 text-xs'> Please the DOB </p>}
							</div>
							<div className="relative mb-3">
								<input type="date" id="customerAnniversaryDate" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " defaultValue={personDetails?.customerAnniversaryDate ? Moment(personDetails.customerAnniversaryDate).format('YYYY-MM-DD') : '' }  max={Moment(now()).format('YYYY-MM-DD')} {...register ("customerAnniversaryDate", { required: false, onChange: handleDate })}   />
								<label htmlFor="customerAnniversaryDate" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Anniversary Date</label>
                                { errors.customerAnniversaryDate && <p className='text-left mt-2 text-rose-600 text-xs'> Please the Anniversary Date </p>}
								{/* <button className="changeBtn">Change</button> */}
							</div>
						</div>
						<div className='mb-6'>
							<Button disabled={buttonStatus} className='w-full' color="dark">
								Save Changes
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Profile