import React from "react";
import HomeBanner from "../Components/Home/HomeBanner";
import HomeCategories from "../Components/Home/HomeCategories";
import { DEBUG } from "../shared/constants";

const Home = ({customerLogin}) => {
    if(DEBUG) console.log(customerLogin);
    return (
        <> 
            <HomeBanner />
            <div className='container mx-auto'>
                <HomeCategories />            
            </div>
        </>
    );
};

export default Home;
