import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import jwt_decode from "jwt-decode";
import InterChangeOption from '../Components/ClaimHistory/InterChangeOption';
import { Button } from 'flowbite-react';
import Payment from '../Components/ClaimHistory/Payment';
import JobSheet from '../Components/ClaimHistory/JobSheet';
import Service from '../Components/ClaimHistory/Service';
import Refund from '../Components/ClaimHistory/Refund';
import ClaimFeedback from '../Components/ClaimHistory/ClaimFeedback';
import CancelClaim  from '../Components/ClaimHistory/CancelClaim';

const ClaimDetails = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

    const navigate = useNavigate();

    const dataFetchedRef = useRef(false);
    const dataInterChangedRef = useRef(false);
    const dataStoredRef = useRef(false);
    // let expire = '';
    let token = '';
    const location = useLocation();
    if(DEBUG) console.log(location)
    const processType   = location.state.processType;
    const claimType     = location.state.claimType;

    const [claimHistoryDetails,setClaimHistoryDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    var api_url =  '';
    const getClaimHistoryDetails = async() => {
        setShowPleaseWaitPopup(true);
        try {
            api_url = '/api/getClaimHistoryDetails';
            if(processType === 'repair' && claimType == 'DP'){
                api_url = '/api/getRepairClaimHistoryDetails';
            }

            if(claimType == 'ZP'){
                api_url = '/api/getZopperClaimHistoryDetails';
            }

            const response =   await axiosJWT.post(api_url,{claimId:location.state.claimId},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setClaimHistoryDetails(response.data.data);
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.msg);
                }
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getClaimHistoryDetails();
        // eslint-disable-next-line
    },[])

    const[checkInterChange, setCheckInterChange] = useState(false);
    const getInterChangeOption = async() => {
        try {
            const response =   await axiosJWT.post('/api/checkInterChangeOption',{claimId:location.state.claimId},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setCheckInterChange(true);
                }
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
                setErrorMsgAlertShow(true);
            }
        }   
    }


    useEffect(() => {
        if(claimType === 'DP'){
            if (dataInterChangedRef.current) return;
            dataInterChangedRef.current = true;
            getInterChangeOption();
        }
        // eslint-disable-next-line
    },[claimType])

    const[storeDetails, setStoreDetails] = useState(false);
    const getStoreDetails = async() => {
        try {
            const response =   await axiosJWT.post('/api/checkStoreInformation',{claimId:location.state.claimId},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setStoreDetails(response.data.data);
                } else {
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                setErrorMsgAlertText(ErrorMessge_400)
                setErrorMsgAlertShow(true);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg)
                setErrorMsgAlertShow(true);
            }
        }   
    }

    useEffect(() => {
        if(claimType === 'DP' || claimType === 'AB' || claimType === 'TP'){
            if (dataStoredRef.current) return;
            dataStoredRef.current = true;
            getStoreDetails();
        }
        // eslint-disable-next-line
    },[claimType])
    
    const [showRepairSubmit,setShowRepairSubmit] = useState(false); 
    const [showCancelClaim,setShowCancelClaim] = useState(false);

    const handleCancelClaim = () => {
        setShowCancelClaim(true);
    }

    const CompleteTPClaim = () => {
        navigate('/claim-process',{
            state:{
                claimType:'TP_STEP2', 
                claimCategory:'TP',
                expired:'',
                purchaseDetails:claimHistoryDetails.purchase_details,
                typeOfClaim:'',
            }})
    }
    

    return (
        <>
            { claimHistoryDetails  && !showRepairSubmit && claimHistoryDetails.status != 'Claim Expired' ? 
                <section className='category-wrapper mt-header py-4'>
                    <div className='grid grid-cols-1 gap-4 mb-4 mx-4'>
                        <div className=' font-medium flex items-center flex-wrap'>
                            <p>Claim ID:</p>
                            <p className=' ml-1'>{claimHistoryDetails?.claim_id}</p>
                        </div>
                        <div className=' text-red-600 font-medium flex items-center flex-wrap'>
                            <p>Status:</p>
                            { (claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'claim') || claimHistoryDetails?.claim_type === 'AB' ||  claimHistoryDetails?.claim_type === 'TP' ?
                                <p className='ml-1'>{claimHistoryDetails?.status}</p>:''
                            }
                            { claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'repair'  ?
                                <p className='ml-1'>{claimHistoryDetails?.repair_status_msg}</p>:''
                            }

                            { claimHistoryDetails?.claim_type === 'ZP'  ?
                                <p className='ml-1'>{claimHistoryDetails?.status}</p>:''
                            }
                            
                        </div>
                    </div>
                    
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                        <div className='category-claim-wrapper rounded shadow'>
                            <div className='category-content__items px-2 md:px-4 border-0'>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>IMEI</span>
                                    <span className='text-black'>{claimHistoryDetails?.imei}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Make</span>
                                    <span className='text-black'>{claimHistoryDetails?.brand}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Model</span>
                                    <span className='text-black'>{claimHistoryDetails?.model}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Invoice Number</span>
                                    <span className='text-black'>{claimHistoryDetails?.invoice_number}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Invoice Date</span>
                                    <span className='text-black'>{claimHistoryDetails?.invoice_date}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    { claimHistoryDetails?.claim_type === 'DP' ? 
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className='category-claim-wrapper rounded shadow'>
                                <p className='px-2 md:px-4 mt-2 text-gray-600 text-md font-medium mb-0'>Attachments(*)</p>
                                <div className="grid grid-cols-2 gap-2 px-2 md:px-4 mt-2 mb-2">
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.damage_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Handset Front Photo</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.handset_back_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Handset Back Photo</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.id_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'> Photocopy ID Proof</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.accessories_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Accessories /charger Photo</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.box_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Box Photo with IMEI on View</div>
                                    </div>
                                </div>
                            </div>
                        </div>:
                        ''
                    }

                    { claimHistoryDetails?.claim_type === 'AB' ? 
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className='category-claim-wrapper rounded shadow'>
                                <p className='px-2 md:px-4 mt-2 text-gray-600 text-md font-medium mb-0'>Attachments(*)</p>
                                <div className="grid grid-cols-2 gap-2 px-2 md:px-4 mt-2 mb-2">
                                    
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.id_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Photocopy ID Proof</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.accessories_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Accessories /charger Photo</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.box_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Box Photo with IMEI on View</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.handset_front_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Handset Front Proof</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.handset_back_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Handset Back Proof</div>
                                    </div>
                                </div>
                            </div>
                        </div>:
                        ''
                    }

                    { claimHistoryDetails?.claim_type === 'TP' && claimHistoryDetails?.id_photo !== '' ? 
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className='category-claim-wrapper rounded shadow'>
                                <p className='px-2 md:px-4 mt-2 text-gray-600 text-md font-medium mb-0'>Attachments(*)</p>
                                <div className="grid grid-cols-2 gap-2 px-2 md:px-4 mt-2 mb-2">
                                    {/* <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.complaint_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Complaint Phone</div>
                                    </div> */}
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.document_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>Document Proof</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.fir_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>FIR CSR Proof</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.id_photo} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>ID Proof</div>
                                    </div>
                                    <div className='flex flex-col border-gray-300 rounded border p-2'>
                                        <div className='img-upload__claim relative'>
                                            <img src={claimHistoryDetails?.sim_block} className=' block m-auto w-20' alt='' />
                                        </div>
                                        <div className='text-center mt-2 text-sm text-gray-600'>SIM Block Proof</div>
                                    </div>
                                </div>
                            </div>
                        </div>:''
                    } 



                    { (claimHistoryDetails?.claim_type === 'DP' || claimHistoryDetails?.claim_type === 'AB' || claimHistoryDetails?.claim_type === 'TP' ) && storeDetails ?
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className='category-claim-wrapper rounded shadow'>
                                <div className='category-content__items px-2 md:px-4 border-0'>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Store Name</span>
                                        <span className='text-black'>{storeDetails?.store_name}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Store Address</span>
                                        <span className='text-black'>{storeDetails?.address}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Store Email</span>
                                        <span className='text-black break-words'>{storeDetails?.email}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Store Mobile No.</span>
                                        <span className='text-black'>{storeDetails?.mobile}</span>
                                    </p>
                                </div>
                            </div>
                        </div>:''
                    }

                    { claimHistoryDetails?.claim_type === 'ZP' ?
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className='category-claim-wrapper rounded shadow'>
                                <div className='category-content__items px-2 md:px-4 border-0'>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Zopper Warranty</span>
                                        <span className='text-black'>{claimHistoryDetails?.zopper_warranty}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Zopper Kit Id</span>
                                        <span className='text-black'>{claimHistoryDetails?.zopper_warranty_kit_id}</span>
                                    </p>
                                   
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Cause of Loss</span>
                                        <span className='text-black'>{claimHistoryDetails?.zopper_cause_of_loss}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Concern</span>
                                        <span className='text-black'>{claimHistoryDetails?.zopper_concern}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Appointment</span>
                                        <span className='text-black'>{claimHistoryDetails?.zopper_appointment}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Note</span>
                                        <span className='text-black'>{claimHistoryDetails?.zopper_note}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        : ''
                    }

                    { claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'repair' ?
                        ''
                        :''
                    }
                </section>:''
            }

            { claimHistoryDetails?.claim_type === 'TP' && claimHistoryDetails?.id_photo == '' && (
                <>
                    <div className='mx-4 flex justify-end items-center mb-4'>
                        <Button type="submit" className='w-full' color="dark" onClick={CompleteTPClaim} >
                            Complete TP Claim
                        </Button>
                    </div>
                </>
            )}

            { claimHistoryDetails?.claim_type === 'DP'  &&  checkInterChange ?
                <InterChangeOption checkInterChange={checkInterChange} claimHistoryDetails={claimHistoryDetails} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} />:''
            }
            
            { claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'repair' && claimHistoryDetails?.repair_status === 'payment_pending' && claimHistoryDetails.status != 'Claim Expired' ?
                <>
                    <Payment showRepairSubmit={showRepairSubmit} setShowRepairSubmit={setShowRepairSubmit} claimHistoryDetails={claimHistoryDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> 
                </>:''
            }

            { claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'repair' && claimHistoryDetails?.repair_status === 'job_pending' && claimHistoryDetails.status != 'Claim Expired' ?
                <>
                    <JobSheet showRepairSubmit={showRepairSubmit} setShowRepairSubmit={setShowRepairSubmit} claimHistoryDetails={claimHistoryDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> 
                </>:''
            }

            { claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'repair' && claimHistoryDetails?.repair_status === 'service_pending' && claimHistoryDetails.status != 'Claim Expired' ?
                <>
                    <Service showRepairSubmit={showRepairSubmit} setShowRepairSubmit={setShowRepairSubmit} claimHistoryDetails={claimHistoryDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> 
                </>:''
            }

            { claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails?.process_type === 'repair' && claimHistoryDetails?.repair_status === 'refund_pending' && claimHistoryDetails.status != 'Claim Expired' ?
                <>
                    <Refund showRepairSubmit={showRepairSubmit} setShowRepairSubmit={setShowRepairSubmit} claimHistoryDetails={claimHistoryDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} /> 
                </>:''
            }

            {!showRepairSubmit && claimHistoryDetails?.claim_type === 'DP' && claimHistoryDetails.status != 'Claim Expired' && (claimHistoryDetails?.process_type === 'repair' && (claimHistoryDetails?.repair_status === 'payment_pending' || claimHistoryDetails?.repair_status === 'job_pending' || claimHistoryDetails?.repair_status === 'service_pending')) || (claimHistoryDetails?.process_type === 'claim' && claimHistoryDetails?.status === 'pending'  ) ? 
                
                <>
                    <div className='mx-4 flex justify-end items-center mb-4'>
                        <p className=' text-gray-400 text-sm mr-2'>Would you like to cancel?</p>
                        <Button disabled={false} className='w-auto px-5 rounded-sm' gradientMonochrome="info" onClick={() => handleCancelClaim()} >Submit</Button>
                    </div>
                    <CancelClaim showCancelClaim={showCancelClaim} setShowCancelClaim={setShowCancelClaim} claimHistoryDetails={claimHistoryDetails} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} />
                </>
                :''
            }

            { claimHistoryDetails?.status == 'Claim Expired' ?
                <>
                    <div className="px-3">
                        <p className="text-red-600 font-medium">Sorry! you can&apos;t proceed further, since you have not visited store in48 hours after applying claim.</p>
                    </div>
                </>
                :''
            }

            { claimHistoryDetails?.is_reviewed === false && (claimHistoryDetails?.repair_status == 'completed' || claimHistoryDetails?.status === 'Claim Approved' ) ?
                <ClaimFeedback showRepairSubmit={showRepairSubmit} setShowRepairSubmit={setShowRepairSubmit} claimHistoryDetails={claimHistoryDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} />
                :''
            }

            
        </>
    )
}

export default ClaimDetails