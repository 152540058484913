import React ,{useState, useEffect } from 'react'
import { Button, Modal } from "flowbite-react";
import { DEBUG, ErrorMessge_400 } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const InterChangeOption = ({checkInterChange,claimHistoryDetails,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    
    useEffect(() => {
        if(checkInterChange){
            setVisible(true);
        }
    },[checkInterChange])

    if(DEBUG) console.log(checkInterChange);

    const popupOpen = (type) => {
        if(type == 'cancel'){
            setVisible(false);
        }
        if(type == 'ok'){
            interChangeSubmit();
        }
    }

    let token = '';
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const interChangeSubmit = async() => {
        var changeType = '';
        if(claimHistoryDetails.process_type == 'claim'){
            changeType = 'repair';
        }

        if(claimHistoryDetails.process_type == 'repair'){
            changeType = 'claim';
        }
        if(DEBUG) console.log(ErrorMessge_400);
        if(DEBUG) console.log(token);
        if(DEBUG) console.log(changeType);

        try {
            const response =   await axiosJWT.post('/api/checkInterChangeSubmit',{claimId:claimHistoryDetails.claim_id,changeType:changeType},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setVisible(false);
                    navigate('/claim-history');
                } else {
                    setErrorMsgAlertText(response.data.msg)
                    setErrorMsgAlertShow(true)
                }
            } else {
                setErrorMsgAlertText(ErrorMessge_400)
                setErrorMsgAlertShow(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg)
                setErrorMsgAlertShow(true)
            }
        }
    }
    
    return (
        <Modal show={visible} position="center" onClose={() => setVisible(false)}  popup={true} className='modal-center' >
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Do you want to change the type of claim ?
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button color="gray" onClick={() => popupOpen('cancel')} > Cancel </Button>
                        <Button color="failure" onClick={() => popupOpen('ok')} > Ok </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default InterChangeOption