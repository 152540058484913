import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from "./Containers/Home";
import Profile from "./Containers/Profile";
import Login from "./Containers/Login";
import Register from "./Containers/Register";
import AddPhone from "./Containers/AddPhone";
import AddVAS from "./Containers/AddVAS";
import AddProof from './Containers/AddProof';
import Claim from "./Containers/Claim";
import ClaimHistory from './Containers/ClaimHistory';
import ClaimDetails from './Containers/ClaimDetails';
import ClaimProcess from './Containers/ClaimProcess';
import PurchasedDetails from "./Containers/PurchaseDetails";
import PhoneDetails from "./Components/Purchase/PhoneDetails";
import AppControl from './Containers/AppControl';
import Offers from './Containers/Offers';
import Coupon from './Containers/Coupon';
import VoucherDetails from './Components/Voucher/VoucherDetails';
import OfferDetails from './Components/Offers/OfferDetails';
import Wallet from './Containers/Wallet';
import WalletOfferDetails from './Components/Wallet/WalletOfferDetails';
import DiscountSurvey from './Containers/DiscountSurvey';
import CustomerSurvey from './Containers/CustomerSurvey';
import StaticPage from './Containers/StaticPage';
import Error404 from './Components/Error/Error404';


const SiteRouter = ({setErrorMsgAlertShow,setErrorMsgAlertText,customerLogin,customerDetails,setCustomerLogin,setShowPleaseWaitPopup}) => {
    return (
        <Routes>
            <Route path="/" element={<Login setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} setCustomerLogin={setCustomerLogin} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/register" element={<Register setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText}  setShowPleaseWaitPopup={setShowPleaseWaitPopup}  />} />
            <Route path="/dashboard" element={<Home setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />}  />
            <Route path="/profile" element={<Profile setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/add-phone" element={<AddPhone  setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup}/>} />
            <Route path="/add-vas" element={<AddVAS  setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup}/>} />
            <Route path="/add-proof" element={<AddProof  setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup}/>} />
            <Route path="/claim" element={<Claim setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/claim-process" element={ <ClaimProcess setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/claim-history" element={<ClaimHistory setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/claim-details" element={<ClaimDetails setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/purchase-details" element={<PurchasedDetails  setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/phone-details" element={<PhoneDetails setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/offers" element={<Offers setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/offer-detail" element={<OfferDetails setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/coupon" element={<Coupon setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/voucher-details" element={<VoucherDetails setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />

            <Route path="/discount-survey" element={<DiscountSurvey setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/customer-survey" element={<CustomerSurvey setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            
            <Route path="/wallet" element={<Wallet setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/wallet-offer-detail" element={<WalletOfferDetails setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/static/:searchText" element={<StaticPage setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} customerDetails={customerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path="/app" element={ <AppControl setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} setCustomerLogin={setCustomerLogin} setShowPleaseWaitPopup={setShowPleaseWaitPopup} />} />
            <Route path='*' element ={<Error404 /> } />
        </Routes>
    )
}

export default SiteRouter