import React from 'react'
import { DEBUG } from '../../shared/constants'
import { Link } from 'react-router-dom';

const ClaimResponse = ({claimSubmitResponse}) => {

    if(DEBUG) console.log(claimSubmitResponse);



    return (
        <>
            <section className='claimed-wrapper mt-header p-4 h-full'>
				<div className="flex flex-col">
					<div className="sangeetha-care__login flex justify-between flex-col w-full"> 
                        <div className="login-header mt-8">
                            
                            <div className='mt-4 text-center font-medium'>
                                <h6 className='mt-3 capitalize'>
									<svg stroke="currentColor" className=' mx-auto' fill="#3fc037" strokeWidth="0" viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg">
										<path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
									</svg>  Claim Submitted successfully
								</h6>
								
							</div>
						</div>
					</div>
					<div className='flex items-center justify-center mt-12'>
						<span className='ml-1 border-gray-200 bg-slate-300 text-gray-700 border px-3 py-1 rounded-md'> <span>Claim ID:</span> {claimSubmitResponse?.claim_id}</span>
					</div>
					{ claimSubmitResponse?.claim_type == 'TP' ? 
						<>
							<p className='font-normal text-xs my-12 text-center'>Visit nearest Sangeetha Store within 48hrs with required details as mentioned below :
							<ul>
								<li>1.Complete Kit of Handset (box, accessories, charger)</li>
								<li>2.Valid ID Proof as per customer details on invoice</li>
							</ul>
							</p>
						</> : 
						<>
							<p className='font-normal text-xs my-12 text-center'>Visit nearest Sangeetha Store within 48 Hours with below details (Complete Kit of Handset as attached on Claim, Valid ID Proof as per Invoice and Customer Payment Value)</p>
						</>
					
					}
					
					<div className='claim-btn__div flex justify-center items-center'>
						<Link to='/claim-history' state={{claimId:claimSubmitResponse?.claim_id}} className='claim-btn'>
							Click Claim Details
						</Link>
					</div>
				</div>
			</section>

        </>
    )
}

export default ClaimResponse