import React, {useRef, useState, useEffect} from 'react';
import { Label, Button, Radio } from 'flowbite-react';
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import jwt_decode from 'jwt-decode';

const DiscountQuestion = ({discount,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const navigate = useNavigate();
    const dataFetchMakeRef = useRef(false);

    const [buttonStatus,setButtonStatus] = useState(false);

    const [questionList,setQuestionList] = useState();

    const { register, handleSubmit, formState: { errors } } = useForm('');

    let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getQuestion = async() => {
		setShowPleaseWaitPopup(true);
        try {
            const response = await axiosJWT.post('/api/getDiscountQuestion',{id:discount.id},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setQuestionList(response.data.data);
					// response.data.data.map((makeDet) => {
					// 	if(DEBUG) console.log(makeDet);
					// 	const arr = { value: makeDet.id, label: makeDet.make_name };
					// 	make.push(arr);
					// })
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
            }
        }
    }


    useEffect(() => {
		if (dataFetchMakeRef.current) return;
        dataFetchMakeRef.current = true;
		getQuestion();
		// eslint-disable-next-line
	},[])


    

    const onSubmit = async(data) => {
		if(DEBUG) console.log(data)

		setShowPleaseWaitPopup(true);
        setButtonStatus(false);
		try {
			const response = await axiosJWT.post('/api/discountQuestionSubmit',{
				id:discount.id,
                question:data,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false)
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
                    navigate('/');
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true);
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true);
			}
		}
	}


    return (
        <>
            { discount.coupon_code == '' && discount.is_customer_survey == '1' && (
                <section className='category-wrapper mt-header py-4'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className=' text-red-600 font-medium'>
                                <p>Reference ID: {discount?.reference_id} </p>
                            </div>
                            <div className='category-claim-wrapper rounded shadow'>
                                <div className='category-content__items px-2 md:px-4'>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Make </span>
                                        <span className='text-black'>{discount?.make_name}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Model</span>
                                        <span className='text-black'>{discount?.model_name}</span>
                                    </p>
                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                        <span>Discount Amount</span>
                                        <span className='text-black'>{ discount.discount_amount > 0 ? '₹'+discount.discount_amount : 0 }</span>
                                    </p>
                                </div>
                            </div>

                            {questionList && questionList.length > 0 ? 
                                <>
                                    { questionList && questionList.map((dataList,idx) => {
                                        return ( 
                                                <div className='category-claim-wrapper rounded shadow p-2' key={idx}>
                                                    <div className='category-content__items px-2 md:px-4 border-0'>
                                                        <div className="flex items-start flex-col gap-2">
                                                            <p> {dataList.question} </p>
                                                            <div className='grid grid-cols-2 gap-2 w-full mt-2'>
                                                                { dataList.option &&  dataList.option.map((dataOptionList,idx) => {
                                                                    return (
                                                                        <div className='flex items-center' key={idx}>
                                                                            <Radio id={"question_data"+dataList.id+'_'+dataOptionList.question_option}  value={dataOptionList.question_option.toLowerCase()}  {...register("question["+dataList.id+"]", { required: true })} />
                                                                            <Label htmlFor={"question_data"+dataList.id+'_'+dataOptionList.question_option} className='ml-1'> {dataOptionList.question_option} </Label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            {errors.is_accessories_available && <p className='text-left mt-2 text-rose-600 text-xs'> Please Check </p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    })}
                                </>:''
                            }
                        </div>
                        <div className='mx-4'>
                            <Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
                        </div>
                    </form>
                </section>
            )}

            {discount.coupon_code == '' && discount.is_customer_survey == '2' && (
                <section className='category-wrapper mt-header py-4'>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                        <div className=' text-green-600 font-medium'>
                            <p>Reference ID: {discount?.reference_id} </p>
                        </div>
                        <div className='category-claim-wrapper rounded shadow'>
                            <div className='category-content__items px-2 md:px-4'>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Make </span>
                                    <span className='text-black'>{discount?.make_name}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Model</span>
                                    <span className='text-black'>{discount?.model_name}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Discount Amount</span>
                                    <span className='text-black'>{ discount.discount_amount > 0 ? '₹'+discount.discount_amount : 0 }</span>
                                </p>

                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Status</span>
                                    <span className='text-orange-500 font-medium'>Waiting for Approval</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            )}


            {discount.coupon_code != '' && discount.is_customer_survey == '2' && (
                <section className='category-wrapper mt-header py-4'>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
                        <div className=' text-green-600 font-medium'>
                            <p>Reference ID: {discount?.reference_id} </p>
                        </div>
                        <div className='category-claim-wrapper rounded shadow'>
                            <div className='category-content__items px-2 md:px-4'>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Make </span>
                                    <span className='text-black'>{discount?.make_name}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Model</span>
                                    <span className='text-black'>{discount?.model_name}</span>
                                </p>
                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Discount Amount</span>
                                    <span className='text-black'>{ discount.discount_amount > 0 ? '₹'+discount.discount_amount : 0 }</span>
                                </p>

                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span>Status</span>
                                    <span className='text-green-500 font-medium'>Approved</span>
                                </p>

                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                    <span className='text-red-500 font-medium'>Coupon Code</span>
                                    <span className='text-red-500 font-medium'>{discount?.coupon_code}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default DiscountQuestion