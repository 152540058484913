import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";


const AppControl = ({setErrorMsgAlertShow,setErrorMsgAlertText,setCustomerLogin,setShowPleaseWaitPopup}) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    if(DEBUG) console.log(location);

    const appToken = location.search.replace('?','');

    if(DEBUG) console.log(appToken);

    const dataFetchedRef = useRef(false);

    const [expire, setExpire] = useState('');
    const [token, setToken] = useState('');
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/guestToken').catch((error) => {
                if(DEBUG) console.log(error)
                navigate('/');
            });

            if(response?.data?.redirect){
                navigate(response.data.redirect)
            }
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            if(DEBUG) console.log(decoded);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const checkToken = async() => {
        try {
            if(appToken){
                const response =   await axiosJWT.post('/api/autoLoginAPP',{
                    appToken:appToken
                },{headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })
                if(DEBUG) console.log(typeof response.data.status);
                if(DEBUG) console.log(response.status);
                setShowPleaseWaitPopup(false)
                if(response.status === 200 ){
                    if(response.data.status === true){
                        if(response.data.status === true){
                            setCustomerLogin(true);
                            navigate('/dashboard')
                        } else {
                            navigate('/')
                        }
                    } else {
                        setErrorMsgAlertShow(true)
                        setErrorMsgAlertText(response.data.msg);
                    }
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(ErrorMessge_400);
                }
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    }

    useEffect(() => {
        if(DEBUG) console.log('error');
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setShowPleaseWaitPopup(false);
        // generateGuestToken();
        checkToken();
        // eslint-disable-next-line
    },[]) //tokenAPI

    return (
        <>
        </>
    )
}

export default AppControl