import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavHamburger from './NavHamburger';
import NavUser from './NavUser';
import sangeethaLogo from '../../Images/sangeetha-logo.svg';
import './Header.css';
import { DEBUG } from '../../shared/constants';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import PleaseWait from '../Loader/PleaseWait';

const Header = ({customerDetails,setCustomerLogin,setCustomerDetails,showPleaseWaitPopup}) => {

    const location = useLocation()
    const [displayHeader,setDisplayHeader] = useState(false);

    const [displayHome,setDisplayHome]      = useState(false);

    const dataFetchedUserLoginRef = useRef(false);
    
    const navigate = useNavigate();
    
    useEffect (() => {
        if(DEBUG) console.log(location);
        if(location.pathname == '/' || location.pathname == '/register' || location.pathname == '/app'){
            setDisplayHeader(false);
        } else if(location.pathname == '/dashboard'){
            setDisplayHome(true);
            setDisplayHeader(true);
        } else {
            setDisplayHome(false);
            setDisplayHeader(true);
        }
        // eslint-disable-next-line
    },[location])

    const checkLoginCustomer = async() => {
        try {
            const response = await axios.get('/api/checkCustomerValid').catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log('product');
            if(DEBUG) console.log(response);
            if(response.status === 200){
                setCustomerLogin(true);
                const decoded = jwt_decode(response.data.accessToken);
                if(DEBUG) console.log(decoded);
                setCustomerDetails({registrationId:decoded.registrationId, customerNumber:decoded.customerNumber});
            } else {
                setCustomerLogin(false);
                setCustomerDetails('');
                navigate('/')
            }
        } catch (error) {
            setCustomerLogin(false);
            setCustomerDetails('');
            navigate('/')
        }
    }

    useEffect(() => {
        if (dataFetchedUserLoginRef.current) return;
        dataFetchedUserLoginRef.current = true;
        if(location.pathname != '/app'){
            checkLoginCustomer();
        }
        //eslint-disable-next-line
    },[location])
    
    const goBackButtonClick = () => {
        navigate(-1);
    }

	return (
		<>
            { displayHeader ?
                <header className='header-wrapper navbar-sticky-header w-full h-full'>
                    <div className="navbar-sticky bg-dark navbar-dark">
                        <div className="navbar navbar-expand-lg navbar-light">
                            <div className="container header-container px-0">
                                {displayHome ?
                                    <div className="header-logo-wrapper navbar-brand flex-shrink-0">
                                        <NavHamburger customerDetails={customerDetails} />
                                        <Link to='/dashboard' className="flex items-center ml-4">
                                            <img src={sangeethaLogo} className='img img-fluid' alt='Sangeetha Logo' />
                                        </Link>
                                    </div>:
                                    <div className="header-logo-wrapper navbar-brand flex-shrink-0">
                                        <span className="d-flex align-items-center" aria-hidden="true" onClick={goBackButtonClick} > 
                                            <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25055L7.80333 7.42888L5.69 9.54222H16.6667V11.2089H5.69L7.80333 13.3222L6.625 14.5005L2.5 10.3755L6.625 6.25055Z" fill="white" />
                                            </svg>
                                        </span>
                                        <Link to='/dashboard' className="flex items-center ml-4">
                                            <svg viewBox="0 0 576 512" height="22" width="22" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" fill="white" />
                                            </svg>
                                        </Link>
                                    </div>

                                }
                                

                                <div className="navbar-toolbar flex flex-shrink-0 items-center">       
                                    <NavUser  customerDetails={customerDetails} />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>:
                ''
            } 

            { showPleaseWaitPopup ?
                <PleaseWait showPleaseWaitPopup = {showPleaseWaitPopup} />:
                ''
            }
		</>
	)
}

export default Header