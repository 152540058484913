import React from "react";
// import { BrowserRouter} from "react-router-dom";
import Header from "./Components/Header";
import SiteRouter from './SiteRouter';
import AppUpdateAlerts from "./Components/Alert/AppUpdateAlerts";
import AppUpdateAlert from "./Components/Alert/AppUpdateAlert";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { DEBUG } from "./shared/constants";
import { useCallback,useEffect, useState } from "react";

const UPDATE_AVAILABLE_MESSAGE ="New content is available and will be automatically installed momentarily.";
const SUCCESSFUL_INSTALL_MESSAGE ="This app has successfully updated itself in the background. Content is cached for offline use.";

// import { getToken1, onMessageListener }  from './services/Firebase/Firebase';

const App = (props) => {

    const [service,setService]  = useState({
        isSet:false,
        customClass:props.netStat,
        updatedSW:null,
        isSWUpdateAvailable:false,
        wasSWInstalledSuccessfully: false,
    });

    // ---------------------------------------------------------------------------
    // -------- Workbox Service Worker event handlers and state management -------
    // ---------------------------------------------------------------------------
    const resetSWNotificationStates = () => {
        if (DEBUG) console.log("App.resetSWNotificationStates()");
        
        if (DEBUG) console.log("serviceData", service)
        if (service.updatedSW && service.isSWUpdateAvailable) {
            console.log("updateData", service.updatedSW)
            service.updatedSW.postMessage({
                type: "SKIP_WAITING",
            });
        }
        var service_reset = service;
        // reset the service worker states
        service_reset.updatedSW = null;
        service_reset.isSWUpdateAvailable = false;
        service_reset.wasSWInstalledSuccessfully = false;
        setService({...service,
            // isSet:true,
            updatedSW: null,
            isSWUpdateAvailable: false,
            wasSWInstalledSuccessfully: false,
        });
        
    }
    
    const onSWUpdateAvailable = (registration) => {
        // if (DEBUG) console.log("App.onSWUpdateAvailable()");
        var service_reset = service;
        if (service.isSet && registration) {
            service_reset.updatedSW = registration.waiting;
            service_reset.isSWUpdateAvailable = true;
            service_reset.wasSWInstalledSuccessfully = false;
            setService({...service,
                updatedSW: registration.waiting,
                isSWUpdateAvailable: true,
                wasSWInstalledSuccessfully: false,
            });

        } else {
          console.log("Warning: onSWUpdateAvailable() was called without a Workbox registration object, or, component was not yet mounted.");
        }
    }
    
    const onSWInstallSuccess = (registration) => {
        // if (DEBUG) console.log("App.onSWInstallSuccess()");
        var service_reset = service;
        if (service.isSet) {
            service_reset.updatedSW = registration;
            service_reset.isSWUpdateAvailable = false;
            service_reset.wasSWInstalledSuccessfully = true;
            setService({...service,
                updatedSW: registration,
                isSWUpdateAvailable: false,
                wasSWInstalledSuccessfully: true,
            });
        }
    }
    // eslint-disable-next-line
    const resetSWNotificationStates_1 = useCallback(resetSWNotificationStates.bind(),[]); // eslint-disable-next-line
    const onSWUpdateAvailable_1 =  useCallback(onSWUpdateAvailable.bind(),[]); // eslint-disable-next-line
    const onSWInstallSuccess_1 =  useCallback(onSWInstallSuccess.bind(),[]);

    // if (DEBUG) console.log("App.componentDidMount()");
   // eslint-disable-next-line
    useEffect(()=> {
        resetSWNotificationStates();
        
        var service_reset = service;
        service_reset.isSet = true
        setService({...service,
            setService:true
        });
        if (process.env.NODE_ENV === "production") {
            serviceWorkerRegistration.register({
                onUpdate: resetSWNotificationStates_1,
                onSuccess: onSWUpdateAvailable_1,
                onActivated: onSWInstallSuccess_1, // an event handler that we'll have to setup manually
            });
            if(DEBUG) console.log('remove service worker');
        }
        // eslint-disable-next-line
    },[])  
    
    // eslint-disable-next-line
    const basename = process.env.REACT_APP_BASENAME || null;
    let [online, isOnline] = useState(typeof window !== 'undefined' ? navigator.onLine: '');

    const setOnline = () => {
        console.log('We are online!');
        isOnline(true);
    };
    const setOffline = () => {
        console.log('We are offline!');
        isOnline(false);
    };
    
    useEffect(() => {
        if(typeof window !== 'undefined'){
            window.addEventListener('offline', setOffline);
            window.addEventListener('online', setOnline);
            // cleanup if we unmount
            return () => {
                window.removeEventListener('offline', setOffline);
                window.removeEventListener('online', setOnline);
            }
        }
    }, []);

    const [errorMsgAlertShow,setErrorMsgAlertShow] = useState();
    const [errorMsgAlertText,setErrorMsgAlertText] = useState();
    const [showPleaseWaitPopup,setShowPleaseWaitPopup] = useState(false);


    const [customerLogin,setCustomerLogin]      = useState();
    const [customerDetails,setCustomerDetails]  = useState();




    // Firebase 
    // const [notification, setNotification]   = useState({title:'',body:''});
    // const [isTokenFound, setTokenFound]     = useState(false);
    
    useEffect(() => {
        // if(typeof window !== 'undefined'){
        //     getToken1(setTokenFound);
        // }
        // eslint-disable-next-line
    }, [])

    // if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    //     onMessageListener().then(payload => {
    //         setNotification({title:payload?.notification?.title,body:payload?.notification?.body})
    //         if(DEBUG) console.log(payload);
    //     }).catch(err => console.log('failed: ', err));
    // }

    return (
        <> 
            <div className="page-wrapper flex flex-col w-full h-full">
                <div className="h-full mt-header">
                    <div className="flex h-full">
                        <div className="w-full sm:w-full md:w-3/6 lg:w-2/6 h-full">
                            {/* {notification.title}
                            {notification.body} */}
                            {/* {isTokenFound} */}
                            <AppUpdateAlerts serviceProps={service} updateMsg={UPDATE_AVAILABLE_MESSAGE} successMsg={SUCCESSFUL_INSTALL_MESSAGE} resetSWNotificationStates= {resetSWNotificationStates}  />
                            { !online ? 
                                <AppUpdateAlert msg={'You appear to have lost connection!'} callback="" />:''
                            }
                            { errorMsgAlertShow && errorMsgAlertText ? 
                                <AppUpdateAlert msg={errorMsgAlertText} errorMsgAlertShow={errorMsgAlertShow} setErrorMsgAlertShow = {setErrorMsgAlertShow} callback="" />:''
                            }
                            <Header customerLogin={customerLogin} setCustomerLogin={setCustomerLogin} customerDetails={customerDetails} setCustomerDetails={setCustomerDetails} showPleaseWaitPopup={showPleaseWaitPopup}   />
                            <SiteRouter setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} customerLogin={customerLogin} setCustomerLogin={setCustomerLogin} customerDetails={customerDetails} setCustomerDetails={setCustomerDetails} setShowPleaseWaitPopup={setShowPleaseWaitPopup}  />
                        </div>
                        <div className="hidden md:block md:w-3/6 lg:w-4/6  border-l border-black border-dashed">
                            <div className="w-full h-full bg-amber-400">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
};

export default App;
