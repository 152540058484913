import React, { useState , useRef ,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import SangeethaLogo from '../Images/sangeetha-logo.png';
import { useForm } from "react-hook-form";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { DEBUG, ErrorMessge_400} from "../shared/constants";
import { useReadOTP } from "react-read-otp";
import OtpInput from 'react18-input-otp';
import {useTimer} from '../Util/useTimer';

const Login = ({setErrorMsgAlertShow,setErrorMsgAlertText,setCustomerLogin,setShowPleaseWaitPopup}) => {

    const dataGuestTokenRef = useRef(false);

    const navigate= useNavigate();
    // const [tokenAPI,setTokenAPI] = useState(true);
    const[customerMobile,setCustomerMobile] = useState();
    // Form 
    const { register, handleSubmit, getValues, formState: { errors } } = useForm();
    // Login Type
    const[loginType,setLoginType] = useState();
    const[textOTP,setTextOTP] = useState(false);
    const[voiceOTP,setVoiceOTP] = useState(false);
    const [smsType,setSmsType] = useState();
    const[submitButton,setSubmitButton] = useState(false);
    const[submitOTPButton,setSubmitOTPButton] = useState(false);
    
    const [expire, setExpire] = useState('');
    const [token, setToken] = useState('');
    const [showOtpSection,setShowOtpSection] = useState(false);
    // const history = useHistory();

    // OTP 
    const [enabledOTP, setEnabledOTP] = useState(false);
    const [otp, setOTP] = useState('');

    useReadOTP(setOTP, {
        enabledOTP
    });
    
    const generateGuestToken = async() => {
        try {
            const response =   await axios.get('/api/guestToken').catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(response?.data?.redirect){
                navigate(response.data.redirect)
            }
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            if(DEBUG) console.log(decoded);
            setExpire(decoded.exp);
            // history.push("/dashboard");
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    }

    useEffect(() => {
        if(DEBUG) console.log('error');
        if (dataGuestTokenRef.current) return;
        dataGuestTokenRef.current = true;
        setShowPleaseWaitPopup(false);
        generateGuestToken();
        // eslint-disable-next-line
    },[]) //tokenAPI

    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/guestToken').catch((error) => {
                if(DEBUG) console.log(error)
                navigate('/');
            });
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            if(DEBUG) console.log(decoded);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // Check Login Type
    const checKOTP = async (e) => {
        if(e &&  e.trim() !== '' && e.length == 10 ){
            setShowPleaseWaitPopup(true)
            try {
                const response =   await axiosJWT.post('/api/checkLoginType',{customerNumber: e,}, {headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })
                if(DEBUG) console.log(response.data.status);
                setShowPleaseWaitPopup(false)
                if(response.status === 200 ){
                    if(response.data.status === true){
                        setLoginType(response.data.loginType);
                        setTextOTP(response.data.text)
                        setVoiceOTP(response.data.voice)
                        
                    } else {
                        // setMsg(response.data.message);   
                        setLoginType('');
                        setTextOTP(false);
                        setVoiceOTP(false);
                        setSubmitButton(false);
                        setErrorMsgAlertShow(true)
                        setErrorMsgAlertText(response.data.msg);
                    }
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(ErrorMessge_400);
                    navigate('/');
                }
                // history.push("/dashboard");
            } catch (error) {
                if (error.response) {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(error.response.data.msg);
                }
            }
        } else {
            setErrorMsgAlertShow(true)
            setErrorMsgAlertText('Please Check Mobile Number');
        }
    }

    useEffect(() => {
        if(smsType){
            setSubmitButton(true);
        } else {
            setSubmitButton(false);
        }
    },[smsType])

    // OTP Generate
    const onSubmit = async (data) =>  {
        if(DEBUG) console.log(data);
        setShowPleaseWaitPopup(true)
        if(loginType === 'otp'){
            try {
                
                setSubmitButton(false);
                const response =   await axiosJWT.post('/api/sendLoginOTP',{
                    customerNumber: data.customerMobile, sms_type:data.sms_type,module_type:'login'
                },{headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })
                if(DEBUG) console.log(response.data);
                setShowPleaseWaitPopup(false)
                if(response.status === 200 ){
                    if(response.data.status === true){

                        setErrorMsgAlertShow(true)
                        setErrorMsgAlertText(response.data.message);
                        // Redirect OTP Section
                        setShowOtpSection(true);
                        setEnabledOTP(true);
                        setCustomerMobile(data.customerMobile);

                    } else {
                        if(DEBUG) console.log(data);
                        setErrorMsgAlertShow(true)
                        setErrorMsgAlertText(response.data.message);
                        setSubmitButton(true);
                    }
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(ErrorMessge_400);
                    setSubmitButton(true);
                }
                // history.push("/dashboard");
            } catch (error) {
                if (error.response) {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(error.response.data.msg);
                    setSubmitButton(true);
                }
            }
        }
        if(loginType === 'password'){
            try {
                const response =   await axiosJWT.post('/api/LoginPassword',{
                    customerNumber: data.customerMobile, sms_type:data.sms_type,module_type:'login'
                },{headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })
                if(DEBUG) console.log(response.data);
                if(response.data.status === true){
                    if(DEBUG) console.log(data);
                } else {
                    if(DEBUG) console.log(data);
                }
            } catch (error) {
                if (error.response) {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(error.response.data.msg);
                }
            }
        }
        if(DEBUG) console.log(data);
    }
    // OTP
    const handleOTPChange = (otp) => {
        if(DEBUG) console.log(otp)
        setOTP(otp);
    }

    useEffect(() => {
        if(otp.length == 4){
            setSubmitOTPButton(true);
        } else {
            setSubmitOTPButton(false);
        }
    },[otp])

    const onSubmitOTP = async (data) => {
        if(DEBUG) console.log(data);
        setShowPleaseWaitPopup(true)
        if(otp.length == 4 && data.customerMobile.length == 10 ){
            try {
                setSubmitOTPButton(false);
                const response =   await axiosJWT.post('/api/loginOTPVerify',{
                    customerNumber: data.customerMobile, otpvalue:otp
                },{headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })
                setShowPleaseWaitPopup(false);
                if(DEBUG) console.log(response.data);
                if(response.status === 200 ){
                    if(response.data.status === true){
                        setCustomerLogin(true);
                        navigate('/dashboard')
                    } else {
                        navigate('/')
                    }
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(ErrorMessge_400);
                    setSubmitButton(true);
                }
                // history.push("/dashboard");
            } catch (error) {
                if (error.response) {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(error.response.data.msg);
                    setSubmitButton(true);
                }
            }
        }
    } 

    const [resendTime, setResendTime] = useTimer({
        direction: "down",
        multiplier: 1
    });

    const resendOTP = (data) => {
        console.log(data);
        if(loginType === 'otp'){
            try {
                setSubmitOTPButton(false);
                axiosJWT.post('/api/resendLoginOTP',{
                    customerNumber: customerMobile,
                    type:'mobile'
                },{headers: {
                    Authorization: `Bearer ${token}`
                }}).then((response) => {
                    if(response.status === 200 ){
                        if(response.data.status === true){
                            setResendTime(200)
                        } else {
                            if(DEBUG) console.log(response);
                        }
                    } else {
                        if(DEBUG) console.log(response);
                    }
                })
                .catch((error) => {
                    if(DEBUG) console.log(error);
                })
                // history.push("/dashboard");
            } catch (error) {
                if (error.response) {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(error.response.data.msg);
                    setSubmitButton(true);
                }
            }
        }
    }


    return (
        <>
            <div className="">
                <div className="sangeetha-care__login flex justify-between flex-col px-4"> 
                    {/* Login */}
                    { !showOtpSection ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="login-header mt-8">
                                <Link to='/' className="sangeetha-logo text-center w-full">
                                    <img src={SangeethaLogo} className="mx-auto" alt="" />
                                </Link>
                            
                                <h5 className='Login-heading h5 text-center mt-4'>Welcome to Sangeetha Mobiles</h5>
                                <p className=' Login-info text-center mt-1'>Please enter your mobile number </p>
                                <div className='loggedIn__wrap mt-12'>
                                    {/* Mobile Number Section */}
                                    <div className='loggedIn__lists'>
                                        <div className='input-group-address'>
                                            <div className='login-address__wrap pb-2'>
                                                <input  type='text' className='form-control form-control-login w-full' placeholder='Customer Mobile Number' {...register("customerMobile", { required: true, pattern: /^[0-9]+$/i , minLength:10, maxLength: 10 })} onChange={(e)=>{checKOTP(e.target.value)}} />
                                                <span className='absolute-login-input'>+91</span> 
                                            </div>
                                        </div>
                                        {errors.customerMobile && <p className='text-left mt-2 text-danger'> Please enter a valid mobile number (10 digits)</p>}
                                    </div>
                                    {/* Radio Button validation */}
                                    { loginType === 'otp' ? 
                                    <div className='loggedIn__lists'>
                                        <div className='input-group-address label-field'>
                                            <label>Received OTP Via</label>
                                            <div className="flex mt-3">
                                                { textOTP ? 
                                                    <div className="flex items-center mr-4">
                                                        <input id="inline-radio" type="radio" value="text" name="sms_type" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={() => setSmsType('text') } {...register("sms_type", { required: true })}
                                                        />
                                                        <label htmlFor="inline-radio" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" >Text</label>
                                                    </div>:''
                                                }
                                                { voiceOTP ?
                                                    <div className="flex items-center mr-4">
                                                        <input id="inline-2-radio" type="radio" value="voice" name="sms_type" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onClick={() => setSmsType('voice')} {...register("sms_type", { required: true })}  />
                                                        <label htmlFor="inline-2-radio" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Voice</label>
                                                    </div> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>: ''}
                                    { loginType === 'password' ? 
                                    <div className='loggedIn__lists'>
                                        <div className='input-group-address'>
                                            <div className='login-address__wrap pb-2'>
                                                <input  type='text' className='form-control form-control-login w-full' placeholder='Customer Password' {...register("password", { required: true })} />
                                                <span className='absolute-login-input'>+91</span> 
                                            </div>
                                        </div>
                                        {errors.password && <p className='text-left mt-2 text-danger'> Please Check Password </p>}
                                    </div>:''
                                    }
                                </div>
                            </div>
                            <div className="loginfooter">
                                <div className="footer-links mb-0 login-footerlink">
                                    <span className='fontcolor-light'>By continuing,</span>&nbsp;
                                    <span className='fontcolor-light'>I agree to the &nbsp;
                                    <Link to='/' className='Linkpolicy'>Privacy Policy</Link></span>
                                </div>
                                <div className="footer-links login-footerlink"> &nbsp;
                                    <span className='fontcolor-light'>and &nbsp;
                                    <Link to='/' className='Linkpolicy'>Terms & Conditions</Link> </span>
                                </div>
                                { submitButton ?
                                    <div className="continuelogin_btn mt-4">
                                        <button type="submit" className=" btn btn-block btn-dark-custom w-full block text-center"> Generate OTP </button>
                                    </div>:''
                                }
                                <div className="mt-2 flex justify-center items-center login-link__section">
                                    <div>Forgot Password?</div>
                                    <Link to='/register' className="login-link-text ml-2">Register</Link>
                                </div>
                            </div> 
                        </form>: ''
                    }
                    {/* OTP Verification */}
                    { showOtpSection ?
                        <form onSubmit={handleSubmit(onSubmitOTP)}>
                            <div className="login-header mt-8">
                                <Link to='/' className="sangeetha-logo text-center w-full">
                                    <img src={SangeethaLogo} className="mx-auto" alt="" />
                                </Link>
                            
                                <h5 className='Login-heading h5 text-center mt-4'>Welcome to Sangeetha Mobiles</h5>
                                {/* <p className=' Login-info text-center mt-1'>Please enter your mobile number </p> */}
                                <p className=' Login-info text-center mt-1'>Please enter OTP sent to {getValues('customerMobile').substring(0, 2)}******{getValues('customerMobile').substring(8, getValues('customerMobile').length)}.</p>
                                <div className='loggedIn__wrap mt-12'>
                                    <div className='loggedIn__lists'>
                                        <div className='input-group-address'>
                                            <div className='login-address__wrap flex gap-3 pb-4'>
                                                <OtpInput
                                                    className='w-1/4 justify-center'
                                                        containerStyle="w-full flex justify-center"
                                                        inputStyle='!w-1/2 h-11'
                                                        value= {otp}
                                                        onChange={handleOTPChange}
                                                        numInputs={4}
                                                        shouldAutoFocus = "true"
                                                        isInputNum="true"
                                                />
                                            </div>
                                        </div>
                                        { !submitOTPButton ?
                                        <div className="text-center w-full block font-medium">
                                            <button disabled={resendTime !== 0} className="text-center inline-block underline" onClick={resendOTP}>Resend OTP</button>
                                            {resendTime !== 0 && <span> in {resendTime} seconds</span>}
                                        </div> : ''
                                        }
                                    </div> 
                                </div>
                            </div>
                        
                            <div className="loginfooter">
                                <div className="footer-links mb-0 login-footerlink">
                                    <span className='fontcolor-light'>By continuing,</span>&nbsp;
                                    <span className='fontcolor-light'>I agree to the &nbsp;
                                    <Link to='/' className='Linkpolicy'>Privacy Policy</Link></span>
                                </div>
                                <div className="footer-links login-footerlink"> &nbsp;
                                    <span className='fontcolor-light'>and &nbsp;
                                    <Link to='/' className='Linkpolicy'>Terms & Conditions</Link> </span>
                                </div>
                                { submitOTPButton ?
                                    <div className="continuelogin_btn mt-4">
                                        <button type="submit" className=" btn btn-block btn-dark-custom w-full block text-center"> Submit</button>
                                    </div>:''
                                }
                                <div className="mt-2 flex justify-center items-center login-link__section">
                                    <div>Forgot Password?</div>
                                    <Link to='/register' className="login-link-text ml-2">Register</Link>
                                </div>
                            </div> 
                        </form>:''
                    }
                </div>  
            </div>
        </> 
    )
}

export default Login