import React, { useState, useEffect,useRef } from 'react';
import Moment, { now } from 'moment';
import { useForm } from "react-hook-form";
import { DEBUG  } from '../shared/constants';
import Select from "react-select";
import axios from 'axios';
// import jwt_decode from 'jwt-decode';
import { Button } from 'flowbite-react';
import AddPhoneSummaryDetails from '../Components/AddPhone/AddPhoneSummaryDetails';
import { useNavigate } from 'react-router-dom';


const AddPhone = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

	const navigate = useNavigate()
	// Form 
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

	const dataFetchDocumentRef = useRef(false);
    let token = '';

	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		// if(DEBUG) console.log(response);
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
		token = response.data.accessToken;
		// const decoded = jwt_decode(response.data.accessToken);
		// if(DEBUG) console.log(decoded);
		return config;
    }, (error) => {
        return Promise.reject(error);	
    });


	const [documentType] = useState([]);

    const getDocument = async() => {
		setShowPleaseWaitPopup(true);
        try {
            const response = await axiosJWT.get('/api/getDocumentType',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					// var documentType = {};
					response.data.data.map((docType) => {
						const arr = { value: docType.document, label: docType.document };
						documentType.push(arr);
					})
                    // setPurchasePhoneDetails(response.data.data);
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.msg);
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    }

    useEffect(() => {
		if (dataFetchDocumentRef.current) return;
        dataFetchDocumentRef.current = true;
        getDocument();
        // eslint-disable-next-line
    },[])


	// Invoice Date
	const [date, setDate] = useState();
	const [displayYear, setDisplayYear] = useState();
	const checkDate = (selectedDate) => {
		setShowPleaseWaitPopup(true);
		if(DEBUG) console.log(selectedDate);
		setDate(selectedDate)
		setValue('invoice_date',selectedDate);
		try {
			const response = axiosJWT.post('/api/checkInvoice',{invoice_date:selectedDate},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					if(response.data.data == 'withoutyear'){
						setDisplayYear(false)
					} else {
						setDisplayYear(true)
					}
				} else {
					// setErrorMsgAlertShow(true)
					// setErrorMsgAlertText(response.data.msg);
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertShow(true)
				setErrorMsgAlertText(error.response.data.msg);
			}
		}
	}

	const [selectedOptions, setSelectedOptions] = useState();

	const handleSelect = (data) => {
		console.log(data);
		setSelectedOptions(data)
		setValue('doc_type',data.value);
	}

	
	const [showPhoneSummary,setShowPhoneSummary] = useState(false);
	const [phoneSummary,setPhoneSummary] = useState({phone_details:'',vas_details:''});

	const onSubmit = async(data) => {
		// if(DEBUG) console.log(date);
		setShowPleaseWaitPopup(true);
		var invoice_det = data.doc_type+'/'+data.store_code+'/'+data.inv_no;

		if(displayYear){
			invoice_det = data.doc_type+'/'+data.invoice_year+'/'+data.store_code+'/'+data.inv_no;
		}

		try {
			const response = await axiosJWT.post('/api/getInvoiceDetails',{invoice_no: invoice_det,invoice_date:date},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response.data);
			if(response.status == 200 ){
				if(response.data.status === true){
					setPhoneSummary(response.data.data);
					setShowPhoneSummary(true);
					// phoneSummary.push(response.data.data);
				} else {
					setErrorMsgAlertShow(true)
					setErrorMsgAlertText(response.data.msg);
				}
			} 
		} catch (error){
			if (error.response) {
				setErrorMsgAlertShow(true)
				setErrorMsgAlertText(error.response.data.msg);
			}
		}
	}

	// console.log(setDocumentType);

	return (
		<>	
			{ showPhoneSummary ?
				<AddPhoneSummaryDetails phoneSummary = {phoneSummary} setShowPhoneSummary={setShowPhoneSummary} setShowPleaseWaitPopup={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText ={setErrorMsgAlertText} />:
				<div className='container mx-auto'>
					<div className='flex flex-col h-full px-4'>
						
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className='mt-6'>
								<div className="relative mb-3">
									<input type="date"  name="invoice_date" id="invoice_date" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " {...register ("invoice_date",{required:true})} onChange={(e) => checkDate(e.target.value)} max={Moment(now()).format('YYYY-MM-DD')}  min={Moment('2019-01-01').format('YYYY-MM-DD')} />
									<label htmlFor="invoice_date" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"  >Invoice Date</label>
									{errors.invoice_date && <p className='text-left mt-2 text-rose-600 text-xs'> Please Check Invoice Date</p>}
								</div>
								
								<div className={displayYear ? 'grid grid-cols-4 gap-2 mb-3': 'grid grid-cols-3 gap-3 mb-3'}>
									
									<div className="relative">
										<Select name="doc_type" id="doc_type" options={documentType} value={selectedOptions} onChange={handleSelect} noOptionsMessage={'Please Select'} required />
										<label htmlFor="doc_type" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Doc No</label>
										
									</div>
									
									{ displayYear ? 
										<div className="relative">
											<input type="text" name="invoice_year" id="invoice_year" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " {...register("invoice_year", { required: true, pattern: /^[0-9]+$/i , minLength:4, maxLength: 4 })} />
											<label htmlFor="invoice_year" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900  peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Inv. Year</label>
											
										</div>:''
									}

									<div className="relative">
										<input type="text" name="store_code" id="store_code" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " {...register("store_code", { required: true, pattern: /^[A-Z 0-9]+$/i , minLength:1, maxLength: 5 })} />

										<label htmlFor="store_code" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Store Code</label>
										
									</div>

									<div className="relative">
										<input type="text" name="invoice_no" id="invoice_no" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=" " {...register("inv_no", { required: true, pattern: /^[0-9]+$/i , minLength:1, maxLength: 5 })} />
										<label htmlFor="invoice_no" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Inv. No</label>
									</div>

								</div>
								<>
									{errors.doc_type && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Document Type</p>}
									{errors.invoice_year && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Invoice Year</p>}
									{errors.store_code && <p className='text-left mt-2 text-rose-600 text-xs'> Please Enter Store Code </p>}
									{errors.inv_no && <p className='text-left mt-2 text-rose-600 text-xs'>Please Enter Invoice No</p>}
								</>
							</div>
							<div className='mb-6'>
								<Button type="submit" className='w-full' color="dark">
									Click here for Summary
								</Button>
							</div>
						</form>
					</div>
				</div>
			}
		</>
	)
}

export default AddPhone
