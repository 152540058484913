import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom'; //
import HamburgerMenu from './HamburgerMenu';
import { DEBUG } from '../../shared/constants';
import axios from 'axios'

import addaphone from '../../Images/icon/add-a-phone.svg';
import addaproof from '../../Images/icon/add-a-proof.svg';
import claim from '../../Images/icon/claim.svg';
import claimhistory from '../../Images/icon/claim-history.svg';
import offers from '../../Images/icon/offers.svg';
import purchasedetails from '../../Images/icon/purchase-details.svg';
import logout from '../../Images/icon/logout.svg';
import { Sidebar } from "flowbite-react";
import { FaRegNewspaper } from "react-icons/fa";

const NavHamburger = ({customerDetails}) => {
    const navigate = useNavigate()
    console.log(customerDetails);
    // Hamburger menu 
    const [isOpen, setIsOpen] = useState(false);

    const LogoutSession = () => {
        try {
            axios.delete('/api/logout').then((response) => {
                if(DEBUG) console.log(response);
                if(response.data.status === true){
                    navigate('/')
                }
            })
            .catch((error) => {
                if(DEBUG) console.log(error);
            })
            
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    }

    return (
        <>
            <div className="menu-wrapper" aria-hidden="true" onClick={() => setIsOpen(true)} >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M15.75 3H2.25V4.5H15.75V3ZM15.75 8.25H2.25V9.75H15.75V8.25ZM15.75 13.5H2.25V15H15.75V13.5Z" fill="white" />
                </svg>
            </div>

            {/* Hamburger menu */}
            <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="h-full flex flex-col justify-between w-full">
                    <Sidebar aria-label="Sidebar with multi-level dropdown example" className="header-menu-list header-menu-list__flowbite">
                        <Sidebar.Items>
                            <Sidebar.ItemGroup>    

                                <Sidebar.Item href="/profile" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <svg stroke="currentColor" fill="#121415" strokeWidth="0" viewBox="0 0 1024 1024" height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                                                </svg>
                                            </span>
                                            <span className="nav-title">
                                                My Account
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Item href="/add-phone" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <img src={addaphone} className='m-auto w-5' alt='Add a Device' />
                                            </span>
                                            <span className="nav-title">
                                                Add a Device
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Item href="/add-proof" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <img src={addaproof} className='m-auto w-5' alt='Add a Proof' />
                                            </span>
                                            <span className="nav-title">
                                                Add a Proof
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Item href="/purchase-details" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <img src={purchasedetails} className='m-auto w-5' alt='Purchase Details' />
                                            </span>
                                            <span className="nav-title">
                                                Purchase
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Item href="/claim" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <img src={claim} className='m-auto w-5' alt='Claim' />
                                            </span>
                                            <span className="nav-title">
                                                Claim Now
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Item href="/claim-history" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <img src={claimhistory} className='m-auto w-5' alt='Claim History' />
                                            </span>
                                            <span className="nav-title">
                                                Claim History
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Item href="/offers" className="nav-item nav-expand mt-0 p-0">
                                    <div className="nav-link nav-expand-link  flex justify-between">
                                        <div className="flex items-center">
                                            <span>
                                                <img src={offers} className='m-auto w-5' alt='Offers' />
                                            </span>
                                            <span className="nav-title">
                                                Offers
                                            </span>
                                        </div>
                                    </div>
                                </Sidebar.Item>

                                <Sidebar.Collapse label="Vas Terms & Condition" className="svg_icons" icon={FaRegNewspaper} >
                                    
                                    <Sidebar.Item href="/static/vas-terms-condition" className="nav-item nav-expand mt-0 p-0">
                                        <div className="nav-link nav-expand-link  flex justify-between">
                                            <div className="flex items-center">
                                                <span className="nav-title">
                                                    DP Terms & Condition
                                                </span>
                                            </div>
                                        </div>
                                    </Sidebar.Item>

                                    <Sidebar.Item href="/static/ab-vas-terms-condition" className="nav-item nav-expand mt-0 p-0">
                                        <div className="nav-link nav-expand-link  flex justify-between">
                                            <div className="flex items-center">
                                                <span className="nav-title">
                                                    ABB Terms & Condition
                                                </span>
                                            </div>
                                        </div>
                                    </Sidebar.Item>

                                    <Sidebar.Item href="/static/pp-vas-terms-condition" className="nav-item nav-expand mt-0 p-0">
                                        <div className="nav-link nav-expand-link  flex justify-between">
                                            <div className="flex items-center">
                                                <span className="nav-title">
                                                    PP Terms & Condition
                                                </span>
                                            </div>
                                        </div>
                                    </Sidebar.Item>

                                    <Sidebar.Item href="/static/tp-vas-terms-condition" className="nav-item nav-expand mt-0 p-0">
                                        <div className="nav-link nav-expand-link  flex justify-between">
                                            <div className="flex items-center">
                                                <span className="nav-title">
                                                    TP Terms & Condition
                                                </span>
                                            </div>
                                        </div>
                                    </Sidebar.Item>

                                    <Sidebar.Item href="/static/privacy-policy" className="nav-item nav-expand mt-0 p-0">
                                        <div className="nav-link nav-expand-link  flex justify-between">
                                            <div className="flex items-center">
                                                <span className="nav-title">
                                                    Privacy Policy
                                                </span>
                                            </div>
                                        </div>
                                    </Sidebar.Item>
                                    
                                </Sidebar.Collapse>
                                
                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </Sidebar>
                    <div className="header-menu-list header-menu-logout">
                        <div className="nav-item nav-expand mt-0">
                            <button className="nav-link nav-expand-link flex" onClick={() => LogoutSession()} >
                                <div className="flex items-center">
                                    <span>
                                        <img src={logout} className='m-auto w-5' alt='Logout' />
                                    </span>
                                    <span className="nav-title">Logout</span>
                                </div>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </HamburgerMenu>
        </>
    
    )
}

export default NavHamburger
