import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../shared/constants';
import jwt_decode from "jwt-decode";
import NoRecord from './NoRecord';



const Offers = ({setShowPleaseWaitPopup}) => {

    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    let token = '';

    const [getOfferDetails,setGetOffereDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const checkOfferDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/checkOffers',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setGetOffereDetails(response.data.data);
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkOfferDetails();
        // eslint-disable-next-line
    },[])

    if(DEBUG) console.log(getOfferDetails);

    return (
        <>
            { getOfferDetails && getOfferDetails.length > 0? 
                <section className='category-wrapper mt-header'>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 px-4 mb-4'>					
                        { getOfferDetails && getOfferDetails.map((dataList,idx) => {
                            return ( 
                                <div className='category-claim-wrapper rounded shadow' key={idx}>
                                    <div className='category-head__top'>
                                        <Link to='/offer-detail' state={{"offerDetail":dataList}} >
                                            <img src={dataList.offer_banner} className='m-auto' alt='Not Found' />
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
                :
                <NoRecord title={'No Offer Available'} />
            }
        </>
    )
}

export default Offers