import React, {useRef,useState,useEffect} from 'react';
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NoRecord from './NoRecord';
import ClaimInfo from '../Components/ClaimHistory/ClaimInfo';

const Claim = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

	const navigate = useNavigate();

	const dataFetchedRef = useRef(false);
    // let expire = '';
    let token = '';

    const [puchasePhoneDetails,setPurchasePhoneDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

	

    const getPurchaseDetails = async() => {
        setShowPleaseWaitPopup(true);
        try {
            const response =   await axiosJWT.get('/api/getPurchaseDetails',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setPurchasePhoneDetails(response.data.data);
                } else {
                    setErrorMsgAlertShow(true)
                    setErrorMsgAlertText(response.data.msg);
                }
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getPurchaseDetails();
        // eslint-disable-next-line
    },[])

	const checkClaim = async(phone_id, package_id,expired,purchaseDetails) => {
        setShowPleaseWaitPopup(true);
		if(DEBUG) console.log(phone_id);
		if(DEBUG) console.log(package_id);
		
		try {
            const response =   await axiosJWT.post('/api/checkClaimForm',{phone_id:phone_id,package_id:package_id},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					if(response.data.data.claim_type == 'DP'|| response.data.data.claim_type == 'AB' || response.data.data.claim_type == 'TP' || response.data.data.claim_type == 'TP_STEP2' ||response.data.data.claim_type == 'ZP' ){
						navigate('/claim-process',{state:{claimType:response.data.data.claim_type, claimCategory:response.data.data.claim_category,expired:expired,purchaseDetails:purchaseDetails,zopper:response.data.data.zopper,typeOfClaim:response.data.data.type_of_claim}})
					}
                } else {
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                setErrorMsgAlertText(ErrorMessge_400);
                setErrorMsgAlertShow(true);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
	}

    const [searchTerm, setSearchTerm] = useState('');

    const [showClaimInfo,setShowClaimInfo] = useState(false);

    
	return (


		<>
            { puchasePhoneDetails && puchasePhoneDetails.length > 0 ?
                <section className='category-wrapper'>
                    {/* <div className='claim-banner__wrap'>
                        <img src={claimBanner} className='w-full' alt='' />
                    </div> */}
                    <div className="relative m-4">
                        <input type="text" id="floating_outlined" className="block pr-2.5 pl-8 pb-2.5 rounded-sm border-gray-400 pt-4 w-full text-sm text-black bg-transparent border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=""  value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
                        <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 pr-2 pl-6 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Search for a Invoice No</label>
                        <span className='search-icon'>
                            <svg stroke="currentColor" fill="#6b7280" strokeWidth="0" viewBox="0 0 24 24" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path><path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z" />
                            </svg>
                        </span>
                    </div>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 px-4 mb-4'>
                        { puchasePhoneDetails && puchasePhoneDetails.map((dataList,idx) => {
                            if (dataList.phone_details.model.toLowerCase().includes(searchTerm.toLowerCase()) || dataList.phone_details.invoice_no.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return(
                                    <div className='category-claim-wrapper rounded shadow' key={idx} >
                                        <div className='category-head__top'>
                                            {dataList.phone_details.model}
                                        </div>
                                        <div className='category-content__items px-2 md:px-4'>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Invoice Number</span>
                                                <span className='text-black'>{dataList.phone_details.invoice_no}</span>
                                            </p>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Invoice Date</span>
                                                <span className='text-black'>{dataList.phone_details.invoice_date}</span>
                                            </p>
                                        </div>
                                        { dataList.vas_details && dataList.vas_details.length > 0 ?
                                            <ul className='category-content__lists grid grid-cols-1 gap-1 px-2 py-4 md:p-4'>
                                                { dataList.vas_details && dataList.vas_details.length > 0 && dataList.vas_details.map((vasdataList,vasKey) => (
                                                    <li className='grid grid-cols-3 gap-2 pb-2' key={vasKey}>
                                                        <span className='claim-desc'>{vasdataList.package_name}</span>
                                                        { vasdataList.remaining_days > 0 && (
                                                        <span className='claim-info'>Expires in:<br/> {vasdataList.remaining_days} Days <br /> { Moment(vasdataList.package_expired_date).format('DD-MM-YYYY')}</span>
                                                        )}
                                                        { vasdataList.is_make_claim === true && vasdataList.remaining_days > 0 ?
                                                            <>  
                                                                <div className='claim-btn__div  flex justify-end items-center'>
                                                                    <button className='claim-btn' onClick={() => checkClaim(dataList.phone_details.phone_id,vasdataList.package_id,vasdataList.remaining_days,dataList.phone_details)}>Claim</button>
                                                                    {vasdataList.package_info_box && (
                                                                    <span className='ml-2' onClick={ () => setShowClaimInfo({'image':vasdataList.package_info_box,'visiable':'show'}) }>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={'18'}>
                                                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                                                                        </svg>
                                                                    </span>
                                                                    )}
                                                                </div>		
                                                            </>: 
                                                            <>
                                                                { vasdataList.remaining_days == 0? 
                                                                    <div className='claim-btn__div flex justify-end items-center text-red-700 font-medium'>
                                                                        Expired
                                                                    </div>
                                                                    :''
                                                                }
                                                            </>
                                                        }
                                                    </li>
                                                ))}
                                            </ul>:
                                            ''
                                        }
                                    </div>
                                )
                            }
                        })}
                    </div>
                </section>:
                <NoRecord title={'No Claim'} />
            }
            <>
                <ClaimInfo showClaimInfo={showClaimInfo} setShowClaimInfo={setShowClaimInfo} />
            </>
		</>
		
	)
}

export default Claim
