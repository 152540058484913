import React from 'react';
import { Link } from 'react-router-dom';
import addaphone from '../../Images/icon/add-a-phone.svg';
import addaproof from '../../Images/icon/add-a-proof.svg';
import claim from '../../Images/icon/claim.svg';
import claimhistory from '../../Images/icon/claim-history.svg';
import offers from '../../Images/icon/offers.svg';
import purchasedetails from '../../Images/icon/purchase-details.svg';
import discountSurvey from '../../Images/icon/discount-survey.svg';

const HomeCategories = () => {
	return (
		<>
			<section className='category-wrapper py-4 px-4'>
				{/* <div className='top-head__wrap'>
					<h4 className='category-heading flex items-center content-between'>Category Title</h4>
				</div> */}
				<div className='category-items-wrapper grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 -grid-flow-col grid-rows-2 gap-4'>
					<div className='category-content-wrapper'>
						<Link to='/add-phone' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={addaphone} className='m-auto w-14' alt='Add a Device' />
								</div>
								<div className='category-content-box'>
									<h5>Add a Device</h5>
								</div>
							</div>
						</Link>
					</div>

					<div className='category-content-wrapper'>
						<Link to='/add-vas' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={addaphone} className='m-auto w-14' alt='Add a Device' />
								</div>
								<div className='category-content-box'>
									<h5>Add Other Invoice VAS</h5>
								</div>
							</div>
						</Link>
					</div>
					<div className='category-content-wrapper'>
						<Link to='/add-proof' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={addaproof} className='m-auto w-14' alt='Add a Proof' />
								</div>
								<div className='category-content-box'>
									<h5>Add Proof </h5>
								</div>
							</div>
						</Link>
					</div>
					<div className='category-content-wrapper'>
						<Link to='/purchase-details' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={purchasedetails} className='m-auto w-14' alt='Purchase Details' />
								</div>

								<div className='category-content-box'>
									<h5>Purchase History</h5>
								</div>
							</div>
						</Link>
					</div>
					<div className='category-content-wrapper'>
						<Link to='/offers' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={offers} className='m-auto w-14' alt='Offers' />
								</div>

								<div className='category-content-box'>
									<h5> Offers </h5>
								</div>
							</div>
						</Link>
					</div>
					<div className='category-content-wrapper'>
						<Link to='/claim' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={claim} className='m-auto w-14' alt='Claim' />
								</div>

								<div className='category-content-box'>
									<h5>Claim</h5>
								</div>
							</div>
						</Link>
					</div>
					<div className='category-content-wrapper'>
						<Link to='/claim-history' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={claimhistory} className='m-auto w-14' alt='Claim History' />
								</div>

								<div className='category-content-box'>
									<h5>Claim History</h5>
								</div>
							</div>
						</Link>
					</div>
					<div className='category-content-wrapper'>
						<Link to='/wallet' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={offers} className='m-auto w-14' alt='Offers' />
								</div>

								<div className='category-content-box'>
									<h5> SMPL Cash </h5>
								</div>
							</div>
						</Link>
					</div>

					<div className='category-content-wrapper'>
						<Link to='/coupon' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={offers} className='m-auto w-14' alt='Offers' />
								</div>

								<div className='category-content-box'>
									<h5> Coupon </h5>
								</div>
							</div>
						</Link>
					</div>

					<div className='category-content-wrapper'>
						<Link to='/discount-survey' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={discountSurvey} className='m-auto w-14' alt='Offers' />
								</div>

								<div className='category-content-box'>
									<h5> Discount Survey </h5>
								</div>
							</div>
						</Link>
					</div>
					
					{/* <div className='category-content-wrapper'>
						<Link to='/' className=''>
							<div className='catg-img shadow'>
								<div className='category-img-box'>
									<img src={Catg3} className='m-auto' alt='Categories' />
								</div>

								<div className='category-content-box'>
									<h5>Purchase History</h5>
								</div>
							</div>
						</Link>
					</div> */}
				</div>
			</section>
		</>
	)
}

export default HomeCategories