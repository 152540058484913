import React, {useRef, useState, useEffect} from 'react';
import Moment, { now } from 'moment';
import { Textarea, Button } from 'flowbite-react';
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";
import Select from "react-select";
import ClaimResponse from './ClaimResponse';

const ZPClaim = ({claimType,claimCategory,claimExpired,purchaseDetails,zopper,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    if(DEBUG) console.log(claimType)
	if(DEBUG) console.log(claimCategory)
	if(DEBUG) console.log(claimExpired)
	if(DEBUG) console.log(purchaseDetails)
	if(DEBUG) console.log(zopper)

	if(DEBUG) console.log(Moment(now()).format('YYYY-MM-DD HH:mm:ss'));

	const [brand] = useState([]);
	const [category] = useState([]);
	const [zopperClaimType] = useState([]);
	const dataFetchMakeRef = useRef(false);
	const navigate = useNavigate();
	const [buttonStatus,setButtonStatus] = useState(false);

	const [claimSubmit,setClaimSubmit] = useState(false);
	const [claimSubmitResponse,setClaimSubmitResponse] = useState(false);

	// Form 
    const { register, handleSubmit, formState: { errors } ,setValue } = useForm();


	const [selectedCategoryOptions, setSelectedCategoryOptions] = useState();
	const [selectedBrandOptions, setSelectedBrandOptions] = useState();
	const [selectedClaimOptions, setSelectedClaimOptions] = useState();

	


	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

	const getCategory = async() => {
		setShowPleaseWaitPopup(true);
        try {
            const response = await axiosJWT.get('/api/zopperCategory',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
			setShowPleaseWaitPopup(false);
            if(DEBUG) console.log(response);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					response.data.data.map((makeDet) => {
						if(DEBUG) console.log(makeDet);
						const arr = { value: makeDet.value, label: makeDet.label };
						category.push(arr);
					})
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true)
            }
        }
    }

	const getBrand = async() => {
        try {
            const response = await axiosJWT.get('/api/zopperBrand',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
					response.data.data.map((makeDet) => {
						if(DEBUG) console.log(makeDet);
						const arr = { value: makeDet.value, label: makeDet.label };
						brand.push(arr);
					})
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true)
            }
        }
    }

	useEffect(() => {
		if (dataFetchMakeRef.current) return;
        dataFetchMakeRef.current = true;
		getCategory();
		getBrand();
		// eslint-disable-next-line
	},[])


	const handleCategorySeleted = async (data) => {
		if(DEBUG) console.log(data);
		setSelectedCategoryOptions(data)
		setValue('category',data.value);
		setShowPleaseWaitPopup(true);
		setSelectedClaimOptions('');
		try {
            const response = await axiosJWT.post('/api/zopperClaimType',{category:data.value},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
					zopperClaimType.length = 0
					response.data.data.map((makeDet) => {
						if(DEBUG) console.log(makeDet);
						if(makeDet.claimtypeid){
							const arr = { value: makeDet.claimtypeid, label: makeDet.name };
							zopperClaimType.push(arr);
						}
					})
                } else {
                    setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
                }
            } 
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true)
            }
        }
	}

	const handleBrandSeleted = async (data) => {
		if(DEBUG) console.log(data);
		setSelectedBrandOptions(data)
		setValue('brand',data.value);
	}

	const handleClaimSeleted = async (data) => {
		if(DEBUG) console.log(data);
		setSelectedClaimOptions(data)
		setValue('claimType',data.value);
	}

	const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
		setButtonStatus(true);
		setShowPleaseWaitPopup(true);
		var claim_sales_id  	= purchaseDetails.phone_id;
		var category 			= data.category;
		var brand 				= data.brand
		var claimType 			= data.claimType;
		var cause_of_loss 		= data.cause_of_loss;
		var concern 			= data.concern;	
		var warranty_kit_id 	= zopper?.zopper_kit_id;
		var warranty 			= zopper?.zopper_warrenty;
		var model 				= data.model;
		try {
			const response = await axiosJWT.post('/api/zpClaimSubmit',{
				claimSalesId:claim_sales_id,
				category:category,
				brand:brand,
				claimType:claimType,
				cause_of_loss:cause_of_loss,
				concern:concern,
				warrantykitId:warranty_kit_id,
				warranty:warranty,
				note:data.note,
				appointment:data.appointment,
				model:model
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			if(DEBUG) console.log(response);
			setShowPleaseWaitPopup(false);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					setClaimSubmit(true);
					setClaimSubmitResponse(response.data.data);
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true)
			}
		}
		setButtonStatus(false);
	}

    return (
        <>
			{ !claimSubmit  ?
				<section className='category-wrapper mt-header py-4'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
							<div className='font-medium text-black'>
								<p>Invoice Details :</p>
							</div>
							<div className='category-claim-wrapper rounded shadow'>
								<div className='category-content__items px-2 md:px-4'>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Number</span>
										<span className='text-black'>{purchaseDetails?.invoice_no}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>IMEI Number</span>
										<span className='text-black'>{purchaseDetails?.imei_number}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Make</span>
										<span className='text-black'>{purchaseDetails?.model}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Amount</span>
										<span className='text-black'>{purchaseDetails?.invoice_total}</span>
									</p>
								</div>
							</div>

							<div className='font-medium text-black'>
								<p>Zopper Details :</p>
							</div>
							<div className='category-claim-wrapper rounded shadow'>
								<div className='category-content__items px-2 md:px-4'>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Kit ID</span>
										<span className='text-black'>{zopper?.zopper_kit_id}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Warranty</span>
										<span className='text-black'>{zopper?.zopper_warrenty}</span>
									</p>
								</div>
							</div>



							<div className='category-content__items px-2 md:px-4 border-0'>

								<div className="relative mb-3">
									<Select name="category" id="category" options={category} value={selectedCategoryOptions} onChange={handleCategorySeleted}  required />
									<label htmlFor="category" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Category</label>
								</div>
								<div className="relative mb-3">
									<Select name="brand" id="brand" options={brand} value={selectedBrandOptions} onChange={handleBrandSeleted}   required />
									<label htmlFor="brand" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Brand</label>
								</div>

								<div className="relative mb-3">
									<input type="text" id="model" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("model", { required: true, pattern: /^[A-Z a-z 0-9]+$/i })} />
									<label htmlFor="model" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"> Model Name </label>
									{errors.model && <p className='text-left mt-2 text-rose-600 text-xs'> allow only character </p>}
								</div>

								<div className="relative mb-3">
									<input type="datetime-local" id="appointment" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" min={Moment(now()).format('YYYY-MM-DD HH:mm')} {...register("appointment", { required: true })}  />
									<label htmlFor="appointment" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Appointment</label>
									{errors.appointment && <p className='text-left mt-2 text-rose-600 text-xs'> Please Fill</p>}
								</div>

								<div className="relative mb-3">
									<input type="text" id="cause_of_loss" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("cause_of_loss", { required: true, pattern: /^[A-Z a-z]+$/i })} />
									<label htmlFor="cause_of_loss" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"> Cause of Loss</label>
									{errors.cause_of_loss && <p className='text-left mt-2 text-rose-600 text-xs'> allow only character </p>}
								</div>
								
								<div className="relative mb-3">
									<input type="text" id="concern" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent border-1 border-gray appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder="" {...register("concern", { required: true, pattern: /^[A-Z a-z]+$/i })} />
									<label htmlFor="concern" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Concern</label>
									{errors.concern && <p className='text-left mt-2 text-rose-600 text-xs'> allow only character </p>}
								</div>

								
								
								<div className="relative mb-3">
									<Select name="claimType" id="claimType" options={zopperClaimType} value={selectedClaimOptions} onChange={handleClaimSeleted}   required />
									<label htmlFor="brand" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Claim Type</label>
									{errors.claimType && <p className='text-left mt-2 text-rose-600 text-xs'> Please Select </p>}
								</div>
							
								<div className="flex items-center gap-2">
									<Textarea id="note" placeholder="Leave a comment..." rows={4} {...register("note", { required: false })} />
									{errors.note && <p className='text-left mt-2 text-rose-600 text-xs'> Please Enter </p>}
								</div>
								
							</div>
						</div>
						<div className='mx-4'>
							<Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
						</div>
					</form>
				</section>:
				<ClaimResponse claimSubmitResponse ={claimSubmitResponse} />
			}
        </>
    )
}

export default ZPClaim
