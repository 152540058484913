import React, { useRef, useState, useEffect } from 'react'
import { Label, Button, Radio, Modal } from 'flowbite-react';
import { DEBUG, ErrorMessge_400 } from '../../shared/constants';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import sangeethaLogo from '../../Images/sangeetha-logo.svg';

const Payment = ({showRepairSubmit,setShowRepairSubmit,claimHistoryDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const dataFetchedRef = useRef(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    
    const handleClaimType = (e) => {
		if(DEBUG) console.log(e.currentTarget.value);
		setValue('paymentFrom',e.currentTarget.value)
	}

    let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    
    const[paymentAmount,setPaymentAmount] = useState();
    const[paymentGateway,setPaymentGateway] = useState([]);

    const getpaymentAmount = async() => {
        try {
            const response =   await axiosJWT.post('/api/paymentAmount',{claimId:claimHistoryDetails.claim_id},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setPaymentAmount(response.data.data);
                } else {
                    setErrorMsgAlertText(response.data.msg)
                    setErrorMsgAlertShow(true);
                }
            } else {
                setErrorMsgAlertText(ErrorMessge_400)
                setErrorMsgAlertShow(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg)
                setErrorMsgAlertShow(true);
            }
        }
    }

    const getPaymentGatewayOption = async() => {
        try {
            const response =   await axiosJWT.post('/api/paymentGatewayOption',{claimId:claimHistoryDetails.claim_id,processType:claimHistoryDetails.process_type},{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setPaymentGateway(response.data.data);
                } else {
                    setErrorMsgAlertText(response.data.msg)
                    setErrorMsgAlertShow(true)
                }
            } else {
                setErrorMsgAlertText(ErrorMessge_400)
                setErrorMsgAlertShow(true)
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertText(error.response.data.msg)
                setErrorMsgAlertShow(true)
            }
        }
    }

    if(DEBUG) console.log(paymentAmount);
    if(DEBUG) console.log(paymentGateway);

    useEffect(() => {
        if(showRepairSubmit){
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            getpaymentAmount();
            getPaymentGatewayOption();
        }
        // eslint-disable-next-line
    },[showRepairSubmit])

    const [showModalPopup, setShowModalPopup] = useState(false);
    const [showPopupMsg, setShowPopupMsg] = useState();
    
    // const [razorpayOrderDetails, setRazorpayOrderDetails] = useState();
    const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
        setShowPleaseWaitPopup(true);
        try {
            const response = await axiosJWT.post('/api/generatePaymentOrderDetails',{
                claimId:claimHistoryDetails.claim_id,
                paymentFrom:data.paymentFrom,
                ruleId:paymentAmount.rule_id,
                amount:paymentAmount.amount
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response);
            setShowPleaseWaitPopup(false);
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.message);
                    if(response.data.pg === 'store'){
                        setShowModalPopup(true);
                        setShowPopupMsg(response.data.msg);
                    }

                    if(response.data.pg === 'razorpay'){
                        // setRazorpayOrderDetails({transaction:response.data.transaction, formData:response.data.transaction})
                        handlePayment(response.data.transaction,response.data.formData);
                    }   
                    
                } else {
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            }
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
                setErrorMsgAlertShow(true)
			}
		}
    }

    const popupOpen = (type) => {
        if(type == 'ok'){
            setShowModalPopup(false);
            navigate('/claim-history');
        }
    }

    // const handlePayment = useCallback(() => {
        
    //     const order = razorpayOrderDetails;

    //     const options = {
    //         key: "YOUR_KEY_ID",
    //         amount: "3000",
    //         currency: "INR",
    //         name: "Acme Corp",
    //         description: "Test Transaction",
    //         image: "https://example.com/your_logo",
    //         order_id: order.id,
    //         handler: (res) => {
    //             console.log(res);
    //         },
    //         prefill: {
    //             name: "Piyush Garg",
    //             email: "youremail@example.com",
    //             contact: "9999999999",
    //         },
    //         notes: {
    //             address: "Razorpay Corporate Office",
    //         },
    //         theme: {
    //             color: "#000000",
    //         },
    //     };
    //     const rzpay = new Razorpay(options);
    //     rzpay.open();
    //     // eslint-disable-next-line
    // },[Razorpay])

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const handlePayment = async(transaction,formData) => {

        console.log(transaction);
        console.log(formData);

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            setErrorMsgAlertText("Razorpay SDK failed to load. Are you online?");
            setErrorMsgAlertShow(true);
            return;
        }

        // const razorpayOrderDetails

        // const { amount, id: order_id, currency } = result.data;

        const options = {
            key: "rzp_live_ahMGRNi3QtWJCE", // Enter the Key ID generated from the Dashboard
            amount: formData.amount,
            currency: 'INR',
            description: "Test Transaction",
            image: { sangeethaLogo },
            order_id: formData.order_id,
            handler: async function (response) {
                const data = {
                    razorpayTransactionId: formData.order_id,
                    razorpayPaymentPayId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                setShowPleaseWaitPopup(true);
                const result = await axiosJWT.post('/api/submitPaymentOrderDetails',data,{headers: {
                    Authorization: `Bearer ${token}`
                }}).catch((error) => {
                    if(DEBUG) console.log(error);
                })

                if(DEBUG) console.log(result.data);
                setShowPleaseWaitPopup(false);
                if(result.status === 200 ){
                    if(result.data.status === true){
                        setShowModalPopup(true);
                        setShowPopupMsg('<h4>'+result.data.PaymentStatus+'</h4>');
                    }
                }
            },
            theme: {
                color: "#000000",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <>
            { !showRepairSubmit ?
                <div className='m-4 flex justify-end items-center'>
                    <Button onClick={() =>setShowRepairSubmit(true) } className='w-full px-5 rounded-lg' color="dark" > Pay Now </Button>
                </div>:''
            }
            {showRepairSubmit ?
                <>
                    <section className='category-wrapper mt-header py-4'>
                        <div className='grid grid-cols-1 gap-4 mb-4 mx-4'>
                            <div className=' font-medium flex items-center flex-wrap'>
                                <p>Claim ID:</p>
                                <p className=' ml-1'>{claimHistoryDetails?.claim_id}</p>
                            </div>
                        </div>

                        <div className='category-items-wrapper grid grid-cols-1 gap-4 mb-4 mx-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className=' rounded shadow'>
                                    <div className='category-content__items pt-4 pb-4 mx-4  border-0'>
                                        <div className='font-medium text-md'>
                                            <p>Active Payment Methods</p>
                                        </div>
                                        <div className='grid grid-cols-2 gap-2 w-full mt-2 mb-4'>
                                            {paymentGateway && paymentGateway.length > 0 ?
                                                paymentGateway.map((dataList,index) => {
                                                    return (
                                                    <div className='flex items-center' key={index}>
                                                        <Radio id={dataList.gateway_slug}  value={dataList.gateway_slug}  {...register("paymentMethod", { required: true, onChange:(e) => handleClaimType(e) })} />
                                                        <Label htmlFor={dataList.gateway_slug} className='ml-1'> {dataList.gateway_name} </Label>
                                                    </div>)
                                                })
                                                :''
                                            }
                                        </div>
                                        {errors.paymentMethod && <p className='text-left mb-2 text-rose-600 text-xs'> Please Select Option  </p>}
                                    </div>
                                </div>
                                
                                <div className=' text-blue-600 font-medium text-lg mt-8 mb-8'>
                                    <p>Amount {'₹'+paymentAmount?.amount}</p>
                                </div>
                        
                                <div className='mt-2'>
                                    <Button type="submit" className='w-full rounded-sm' color="dark">Pay Now</Button>
                                </div>
                            </form>
                        </div>
                    </section>
                
                    { showModalPopup ?
                        <Modal show={showModalPopup} position="center" onClose={() => popupOpen('ok')}  popup={true} className='modal-center' >
                            <Modal.Header />
                            <Modal.Body>
                                <div className="text-center">
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                        {showPopupMsg}
                                    </h3>
                                    <div className="flex justify-center gap-4">
                                        <Button color="failure" onClick={() => popupOpen('ok')} > Ok </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>: ''
                    }
                </>
                :''
            }
        </>
    )


}

export default Payment