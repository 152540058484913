import React, { useRef, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../../shared/constants';
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from 'flowbite-react';

const VoucherDetails = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

    const dataFetchedRef = useRef(false);

    // Form 
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const navigate = useNavigate();
	const location = useLocation();
	if(DEBUG) console.log(location.state.voucherDetails);

	const voucherDetails = location.state.voucherDetails;

	if(!voucherDetails){
		navigate('/coupon');
	}

    let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    
    const[storeDetails] = useState([]);
    const[productDetails] = useState([]);
    const[brandDetails] = useState([]);
    const[modelDetails] = useState([]);
    
    const[scratchCouponDetails,setScratchCouponDetails]= useState();
    const [showCouponHistory,setShowCouponHistory] = useState(false);
    const [showCoupon,setShowCoupon] = useState(false);

    const [selectedStoreOptions, setSelectedStoreOptions] = useState();

    const checkStoreDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/getStore',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.store_code+'-'+docType.store_name };
						storeDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                    navigate('/offers');
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkStoreDetails();
        // eslint-disable-next-line
    },[])

    const checkProductDetails = async(data) => {

        setValue('store_id',data.value);
        setShowPleaseWaitPopup(true)
        setSelectedStoreOptions(data)
		
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleProduct',{
                offerId:voucherDetails.id,
                storeId:data.value
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.product_name };
						productDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 
    
    const [selectedProductOptions, setSelectedProductOptions] = useState();

    const checkBrandDetails = async(data) => {

        setValue('product_id',data.value);
        setShowPleaseWaitPopup(true)
        setSelectedProductOptions(data)
		setSelectedBrandOptions('');
        
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleBrand',{
                offerId:voucherDetails.id,
                productId:data.value
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    brandDetails.length = 0;
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.make_name };
						brandDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const [selectedBrandOptions, setSelectedBrandOptions] = useState();

    const checkModelDetails = async(data) => {
        setShowPleaseWaitPopup(true)
        setSelectedBrandOptions(data)
		setValue('make_id',data.value);
        try {
            const response =   await axiosJWT.post('/api/checkSuperCashEligibleModel',{
                offerId:voucherDetails.id,
                productId:selectedProductOptions.value,
                storeId:selectedStoreOptions.value,
                makeId:data.value,
            },{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    modelDetails.length = 0;
                    if(DEBUG) console.log(response.data.data);
                    response.data.data.map((docType) => {
						const arr = { value: docType.id, label: docType.model_name };
						modelDetails.push(arr);
					})
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const [selectedModelOptions, setSelectedModelOptions] = useState();
    const handleModel = (data) => {
		console.log(data);
		setSelectedModelOptions(data)
		setValue('model_id',data.value);
	}

    if(DEBUG) console.log('showCoupon '+showCoupon);
    if(DEBUG) console.log('showCouponHistory '+showCouponHistory);

    const onSubmit = async(data) => {
		// if(DEBUG) console.log(date);
		setShowPleaseWaitPopup(true);
		try {
			const response = await axiosJWT.post('/api/voucherGenerateCouponCode',{
                offerId:voucherDetails.id,
                storeId:data.store_id,
                productId:data.product_id,
                makeId:data.make_id,
                modelId:data.model_id,
            },{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response.data);
			if(response.status == 200 ){
				if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data.coupon_code);
                    setShowCoupon(true);
                    setShowCouponHistory(false);
                    const arr = { coupon_code: response.data.data.coupon_code, amount:response.data.data.amount,expired_at:response.data.data.expired_at };
					setScratchCouponDetails(arr);
				} else {
					if(DEBUG) console.log(response.data.msg);
                    setErrorMsgAlertText(response.data.msg);
                    setErrorMsgAlertShow(true)
				}
			} 
		} catch (error){
			if (error.response) {
                if(DEBUG) console.log(error.response.data.msg);
			}
		}
	}

    return (
        <>  
            { !showCoupon ?
                <div className='container h-full'>
                    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4'>
                        <div className='flex flex-col h-full px-4 justify-between'>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="store_id" id="store_id" {...register("store_id", { required: true})} options={storeDetails} value={selectedStoreOptions} onChange={checkProductDetails} />
                                        <label htmlFor="store_id" className="absolute text-sm  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" >Store</label>
                                        {errors.store_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Store </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="product_id" id="product_id" options={productDetails} value={selectedProductOptions} required {...register("product_id", { required: true})} onChange={checkBrandDetails} />
                                        <label htmlFor="product_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap" >Product</label>
                                        {errors.product_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Product </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="make_id" id="make_id" options={brandDetails} value={selectedBrandOptions} required {...register("make_id", { required: true})} onChange={checkModelDetails} />
                                        <label htmlFor="make_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Brand</label>
                                        {errors.make_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Brand </p>}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <div className="relative">
                                        <Select name="model_id" id="model_id" options={modelDetails} value={selectedModelOptions} required {...register("model_id", { required: true})} onChange={handleModel}  />
                                        <label htmlFor="model_id" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap">Model</label>
                                        {errors.model_id && <p className='text-left mt-2 text-rose-600 text-xs'>Please Select Model </p>}
                                    </div>
                                </div>

                                
                                <div className='mb-6 mt-6'>
                                    <Button type="submit" className='w-full' color="dark">
                                        Continue
                                    </Button>
                                </div>

                                
                            </form>
                        </div>
                    </div>
                </div>:''
            }

            { showCoupon ?
                <div className='w-full category-claim-wrapper rounded shadow p-4'>
                    <div>
                        {scratchCouponDetails?.expired_at}
                    </div>
                    <div>
                        <p className='content-catg__details grid grid-cols-3 gap-3 my-2 text-right'>
                            <span className="text-left">Coupon Code</span>
                            <span className='text-black text-lg'>{scratchCouponDetails?.coupon_code}</span>
                            {/* <span className='text-black'>{ '₹'+ scratchCouponDetails?.amount }</span> */}
                        </p>
                    </div>
                </div>:''
            }
        </>
    )
}

export default VoucherDetails