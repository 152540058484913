
import React, { useRef, useEffect,useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG, ErrorMessge_400 } from '../shared/constants';
import jwt_decode from "jwt-decode";
import parse from 'html-react-parser';
// import Iframe from 'react-iframe'
import { Accordion } from "flowbite-react";


const StaticPage = ({setErrorMsgAlertShow,setErrorMsgAlertText,setShowPleaseWaitPopup}) => {

    
    const location = useLocation();

    var locationPath = location.pathname;
    locationPath     = locationPath.split("/");
    var locationName = locationPath[locationPath.length - 1];

    const navigate = useNavigate();

    const dataFetchedRef = useRef(false);
    // let expire = '';
    let token = '';

    const [staticPageContent,setStaticPageContent] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getStaticPageContent = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/static-page?pagename='+locationName,{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(DEBUG) console.log(response.data.data);
                setStaticPageContent(response.data.data);
            } else {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(ErrorMessge_400);
            }
        } catch (error) {
            if (error.response) {
                setErrorMsgAlertShow(true)
                setErrorMsgAlertText(error.response.data.msg);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getStaticPageContent();
        // eslint-disable-next-line
    },[])

    console.log(staticPageContent);

    return (
        <>
            {/* { staticPageContent ? parse(staticPageContent.html_content) : '' }   */}
            { staticPageContent?.type && staticPageContent.type == 'accordion' ? 
                <div className='staticPage'>
                    <Accordion alwaysOpen={true}>
                        { staticPageContent.html_content.length > 0 && staticPageContent.html_content.map((dataList,Key) => (
                            <Accordion.Panel key={Key}>
                                <Accordion.Title>
                                    <div className="flex items-center">
                                        <span className="nav-title">
                                            {dataList.title}
                                        </span>
                                    </div>                                        
                                </Accordion.Title>
                                <Accordion.Content>
                                    {parse(dataList.accordion_content)}
                                </Accordion.Content>
                            </Accordion.Panel>
                        ))}
                    </Accordion> 
                </div> : ''
            }

            { staticPageContent?.type && staticPageContent.type == 'plain_text' ? 
                <>
                    { staticPageContent.html_content.length > 0 && staticPageContent.html_content.map((dataList,key) => (
                        <div className="staticPage p-4" key={key}>
                            {parse(dataList.content)}
                        </div>
                    ))}
                </>:''
            }
            
        </>
    )

}

export default StaticPage