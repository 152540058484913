import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../shared/constants';
import jwt_decode from "jwt-decode";
import NoRecord from './NoRecord';

const DiscountSurvey = ({setShowPleaseWaitPopup}) => {

    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    let token = '';

    const [getDiscountDetails,setGetDiscountDetails] = useState();
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const checkDiscountDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/checkManagerDiscount',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setGetDiscountDetails(response.data.data);
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkDiscountDetails();
        // eslint-disable-next-line
    },[])

    const [searchTerm, setSearchTerm] = useState('');

    if(DEBUG) console.log(getDiscountDetails);

    
    return (
        <>
            { getDiscountDetails && getDiscountDetails.length > 0? 
                <section className='category-wrapper mt-header'>
                    <div className="relative m-4">
                        <input type="text" id="floating_outlined" className="block pr-2.5 pl-8 pb-2.5 rounded-sm border-gray-400 pt-4 w-full text-sm text-black bg-transparent border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer" placeholder=""  value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
                        <label htmlFor="floating_outlined" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 pr-2 pl-6 peer-focus:px-2 peer-focus:text-gray-500 peer-focus:dark:text-gray-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Search for a Reference No</label>
                        <span className='search-icon'>
                            <svg stroke="currentColor" fill="#6b7280" strokeWidth="0" viewBox="0 0 24 24" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path><path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z" />
                            </svg>
                        </span>
                    </div>
                    <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 px-4 mb-4 mt-4'>					
                        { getDiscountDetails && getDiscountDetails.map((dataList,idx) => {
                            return ( 
                                <>
                                    <div className='category-claim-wrapper rounded shadow' key={idx}>
                                        <div className='category-head__top'>
                                            {dataList.reference_id}
                                        </div>
                                        <div className='category-content__items px-2 md:px-4'>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Brand</span>
                                                <span className='text-black'>{dataList.make_name}</span>
                                            </p>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Model</span>
                                                <span className='text-black'>{dataList.model_name}</span>
                                            </p>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Discount Amount</span>
                                                <span className='text-black'>{ dataList.discount_amount > 0 ? '₹'+dataList.discount_amount : 0 }</span>
                                            </p>
                                        </div>
                                        <ul className='category-content__lists grid grid-cols-1 gap-1 px-2 py-4 md:p-4'>
                                            {dataList.coupon_code == '' && dataList.is_customer_survey == '1' && dataList.discount_status == 0 && (
                                                <li className='grid grid-cols-1 gap-2'>
                                                    <div className='claim-btn__div flex justify-end items-center'>
                                                        <Link to='/customer-survey' className='claim-btn' state={{"discount":dataList}} >Give Survey</Link>
                                                    </div>
                                                </li>
                                            )}

                                            {dataList.coupon_code == '' && dataList.is_customer_survey == '2' && dataList.discount_status == 0  && (
                                                <li className='grid grid-cols-1 gap-2'>
                                                    <div className='claim-btn__div flex justify-end items-center'>
                                                        <Link to='/customer-survey' className='claim-btn' state={{"discount":dataList}} > Waiting for Apporval </Link>
                                                    </div>
                                                </li>
                                            )}

                                            {dataList.coupon_code != '' && dataList.is_customer_survey == '2' && dataList.discount_status == 1  && (
                                                <li className='grid grid-cols-1 gap-2'>
                                                    <div className='claim-btn__div flex justify-end items-center'>
                                                        <Link to='/customer-survey' className='claim-btn' state={{"discount":dataList}} >  Apporved </Link>
                                                    </div>
                                                </li>
                                            )}

                                            { dataList.discount_status == 2  && (
                                                <li className='grid grid-cols-1 gap-2'>
                                                    <div className='claim-btn__div flex justify-end items-center'>
                                                        <span className='claim-btn'>  Rejected </span>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    {/* <div className='category-claim-wrapper rounded shadow' key={idx}>
                                        <div className='category-head__top'>
                                            {dataList?.model}
                                        </div>
                                        <div className='category-content__items px-2 md:px-4'>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Coupon Code</span>
                                                <span className='text-black'>{dataList?.coupon_code}</span>
                                            </p>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>From Date</span>
                                                <span className='text-black'>{dataList?.fromdate}</span>
                                            </p>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>To Date</span>
                                                <span className='text-black'>{dataList?.todate}</span>
                                            </p>
                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                <span>Coupon Status</span>
                                                <span className={(dataList.coupon_status === 'Expired' ?  'text-orange-500'  : 'text-green-500' )}>{dataList?.coupon_status}</span>
                                            </p>
                                            {dataList?.used_invoice && (
                                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                    <span>Used Invoice</span>
                                                    <span className='text-black'>{dataList?.used_invoice}</span>
                                                </p>
                                            )}
                                            {dataList?.used_invoice_date && (
                                                <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                    <span>Used Invoice Date</span>
                                                    <span className='text-black'>{dataList?.used_invoice_date}</span>
                                                </p>
                                            )}
                                        </div>
                                    </div> */}
                                </>
                            )
                        })}
                    </div>
                   
                </section>
                :
                <NoRecord title={'No Record Found'} />
            }
        </>
    )
}

export default DiscountSurvey