import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DEBUG } from '../shared/constants';
import jwt_decode from "jwt-decode";
import NoRecord from './NoRecord';
import PPFeedback from '../Components/Coupon/PPFeedback';



const Coupon = ({setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    let token = '';

    const [getCouponDetails,setGetCouponDetails] = useState();

    const[checkPPFeedbackData,setCheckPPFeedbackData] = useState();

    const[getVoucherCoupon,setGetVoucherCoupon] = useState();

    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
            if(DEBUG) console.log(error)
            navigate('/');
        });
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const checkCouponDetails = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/checkCoupon',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setGetCouponDetails(response.data.data);
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    const checkPPFeedback = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/ppFeedbackCheck',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setCheckPPFeedbackData(response.data.data);
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 


    const checkVoucherCoupon = async() => {
        setShowPleaseWaitPopup(true)
        try {
            const response =   await axiosJWT.get('/api/getVoucherCoupon',{headers: {
                Authorization: `Bearer ${token}`
            }}).catch((error) => {
                if(DEBUG) console.log(error);
            })
            if(DEBUG) console.log(response.data.status);
            setShowPleaseWaitPopup(false)
            if(response.status === 200 ){
                if(response.data.status === true){
                    if(DEBUG) console.log(response.data.data);
                    setGetVoucherCoupon(response.data.data);
                } else {
                    if(DEBUG) console.log(response.data);
                }
            } else {
                if(DEBUG) console.log(response.data);
            }
        } catch (error) {
            if (error.response) {
                if(DEBUG) console.log(error.response);
            }
        }
    } 

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        checkCouponDetails();
        checkPPFeedback();
        checkVoucherCoupon();
        // eslint-disable-next-line
    },[])

    if(DEBUG) console.log(getCouponDetails);

    
    return (
        <>
            { !checkPPFeedbackData && (
                <>
                    { (getCouponDetails && getCouponDetails.length > 0) || (getVoucherCoupon && getVoucherCoupon.length > 0 )? 
                        <section className='category-wrapper mt-header'>
                            <div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 px-4 mb-4 mt-4'>					
                                { getCouponDetails && getCouponDetails.map((dataList,idx) => {
                                    return ( 
                                        <>
                                            <div className='category-claim-wrapper rounded shadow' key={idx}>
                                                <div className='category-head__top'>
                                                    {dataList?.model}
                                                </div>
                                                <div className='category-content__items px-2 md:px-4'>
                                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                        <span>Coupon Code</span>
                                                        <span className='text-black'>{dataList?.coupon_code}</span>
                                                    </p>
                                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                        <span>From Date</span>
                                                        <span className='text-black'>{dataList?.fromdate}</span>
                                                    </p>
                                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                        <span>To Date</span>
                                                        <span className='text-black'>{dataList?.todate}</span>
                                                    </p>
                                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                        <span>Coupon Status</span>
                                                        <span className={(dataList.coupon_status === 'Expired' ?  'text-orange-500'  : 'text-green-500' )}>{dataList?.coupon_status}</span>
                                                    </p>
                                                    {dataList?.used_invoice && (
                                                        <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                            <span>Used Invoice</span>
                                                            <span className='text-black'>{dataList?.used_invoice}</span>
                                                        </p>
                                                    )}
                                                    {dataList?.used_invoice_date && (
                                                        <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                            <span>Used Invoice Date</span>
                                                            <span className='text-black'>{dataList?.used_invoice_date}</span>
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}

                                { getVoucherCoupon && getVoucherCoupon.map((dataList,idx) => {
                                    return ( 
                                        <>
                                            <div className='category-claim-wrapper rounded shadow' key={idx}>
                                                {dataList?.model_name ?
                                                    <div className='category-head__top'>
                                                       {dataList?.model_name}
                                                    </div>
                                                    :
                                                    <div className='category-head__top'>
                                                        Voucher - {'₹'+dataList?.coupon_amount}
                                                    </div>
                                                }
                                                <div className='category-content__items px-2 md:px-4'>
                                                    {dataList?.coupon_code && (
                                                        <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                            <span>Coupon Code</span>
                                                            <span className='text-green'>{dataList?.coupon_code}</span>
                                                        </p>
                                                    )}

                                                    {dataList?.model_name ?
                                                        <>
                                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                                <span>From Date</span>
                                                                <span className='text-black'>{dataList?.coupon_start_date}</span>
                                                            </p>
                                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                                <span>To Date </span>
                                                                <span className='text-black'>{dataList?.coupon_end_date}</span>
                                                            </p>
                                                        </>
                                                    :
                                                        <>
                                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                                <span>From Date</span>
                                                                <span className='text-black'>{dataList?.start_date}</span>
                                                            </p>
                                                            <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                                <span>To Date </span>
                                                                <span className='text-black'>{dataList?.end_date}</span>
                                                            </p>
                                                        </>
                                                    }
                                                    
                                                    {dataList?.coupon_status && (
                                                    <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                        <span>Coupon Status</span>
                                                        <span className={(dataList.coupon_status === 'Expired' ?  'text-orange-500'  : 'text-green-500' )}>{dataList?.coupon_status}</span>
                                                    </p>
                                                    )}
                                                    {dataList?.used_invoice && (
                                                        <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                            <span>Used Invoice</span>
                                                            <span className='text-black'>{dataList?.used_invoice}</span>
                                                        </p>
                                                    )}
                                                    {dataList?.used_invoice_date && (
                                                        <p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
                                                            <span>Used Invoice Date</span>
                                                            <span className='text-black'>{dataList?.used_invoice_date}</span>
                                                        </p>
                                                    )}
                                                </div>
                                                <ul className='category-content__lists grid grid-cols-1 gap-1 px-2 py-4 md:p-4'>
                                                    <li className='grid grid-cols-2 gap-2'>
                                                        <span className={'flex justify-start items-center '+ (dataList.enable_button === true ? 'text-green-500': 'text-orange-500' )} >{ dataList.enable_button === true ? 'Active': 'Not Active' }</span>
                                                        {dataList?.enable_button && (
                                                        <div className='claim-btn__div flex justify-end items-center'>
                                                            <Link to='/voucher-details' className='claim-btn' state={{"voucherDetails":dataList}} >Generate Coupon</Link>
                                                        </div>
                                                        )}
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </section>
                        :
                        <NoRecord title={'No Coupon Available'} />
                    }
                </>
            )}
            {checkPPFeedbackData && (
                <PPFeedback claimHistoryDetails={checkPPFeedbackData}
            setShowPleaseWaitPopup ={setShowPleaseWaitPopup} setErrorMsgAlertShow={setErrorMsgAlertShow} setErrorMsgAlertText={setErrorMsgAlertText} />
            ) }
        </>
    )
}

export default Coupon