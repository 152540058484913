import React from 'react'
import AppUpdateAlert from "./AppUpdateAlert";
import { DEBUG } from "../../shared/constants";

const AppUpdateAlerts = ({serviceProps,updateMsg,successMsg,resetSWNotificationStates}) => {

    
    if (DEBUG) console.log('AppUpdateAlerts');
    if (DEBUG) console.log(serviceProps.isSet);
    if (DEBUG) console.log(serviceProps);
    if (DEBUG) console.log(successMsg);
    return (
        <>
            {serviceProps.isSet && (
                <>
                    {serviceProps.isSWUpdateAvailable && (
                        <AppUpdateAlert msg={updateMsg} callback={resetSWNotificationStates} />
                    )}

                    {serviceProps.wasSWInstalledSuccessfully && (
                        <AppUpdateAlert msg={successMsg} callback={resetSWNotificationStates} />
                    )}
                </>
            )}
        </>
    )
}

export default AppUpdateAlerts
