import React,{ useState} from 'react';
import { Textarea, Button } from 'flowbite-react';
import { DEBUG } from '../../shared/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useForm } from "react-hook-form";
import ClaimResponse from './ClaimResponse';
// import Select from "react-select";


const TPClaim = ({claimType,claimCategory,claimExpired,purchaseDetails,setShowPleaseWaitPopup,setErrorMsgAlertShow,setErrorMsgAlertText}) => {

	if(DEBUG) console.log(claimType)
	if(DEBUG) console.log(claimCategory)
	if(DEBUG) console.log(claimExpired)
	if(DEBUG) console.log(purchaseDetails)

	const [buttonStatus,setButtonStatus] = useState(false);
	// Form 
    const { register, handleSubmit, formState: { errors } } = useForm();
	
	const [claimSubmit,setClaimSubmit] = useState(false);
	const [claimSubmitResponse,setClaimSubmitResponse] = useState(false);

	const navigate = useNavigate();
	let token = '';
	const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        // const currentDate = new Date();
        // if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get('/api/checkCustomerValid').catch((error) => {
			if(DEBUG) console.log(error)
			navigate('/');
		});
		config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        token = response.data.accessToken;
        const decoded = jwt_decode(response.data.accessToken);
        if(DEBUG) console.log(decoded);
        // expire =decoded.exp;
        // }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

	const onSubmit = async(data) => {
		if(DEBUG) console.log(data)
		setButtonStatus(true);
		setShowPleaseWaitPopup(true);
		
		var claim_sales_id  = purchaseDetails.phone_id;
		var claim_type	 	= 'TP'	
		var claim_category 	= claimCategory;	
		var note 			= data.note

		try {
			const response = await axiosJWT.post('/api/tpClaimSubmit',{
				claimSalesId:claim_sales_id,
				claimType:claim_type,
				claimCategory:claim_category,
				note:note,
			},{headers: {
				Authorization: `Bearer ${token}`
			}}).catch((error) => {
				if(DEBUG) console.log(error);
			})
			setShowPleaseWaitPopup(false);
			if(DEBUG) console.log(response);
			if(response.status === 200 ){
				if(response.data.status === true){
					if(DEBUG) console.log(response.data.data);
					setClaimSubmit(true);
					setClaimSubmitResponse(response.data.data);
				} else {
					setErrorMsgAlertText(response.data.msg);
					setErrorMsgAlertShow(true)
				}
			} 
		} catch (error) {
			if (error.response) {
				setErrorMsgAlertText(error.response.data.msg);
				setErrorMsgAlertShow(true)
			}
		}
	}

	return (
		<>
			{ !claimSubmit  ?
				<section className='category-wrapper mt-header py-4'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='category-items-wrapper grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4 mb-4 mx-4'>
							<div className='font-medium text-black'>
								<p>STEP 1 :</p>
							</div>
							<div className='category-claim-wrapper rounded shadow'>
								<div className='category-content__items px-2 md:px-4'>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Number</span>
										<span className='text-black'>{purchaseDetails?.invoice_no}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>IMEI Number</span>
										<span className='text-black'>{purchaseDetails?.imei_number}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Make</span>
										<span className='text-black'>{purchaseDetails?.model}</span>
									</p>
									<p className='content-catg__details grid grid-cols-2 gap-2 my-2'>
										<span>Invoice Amount</span>
										<span className='text-black'>{purchaseDetails?.invoice_total}</span>
									</p>
								</div>
							</div>
							<div className=' text-blue-600 font-medium text-sm'>
								<p>Mention in Brief description of incident ( with complete information and explained theft in detail )</p>
							</div>
							<div className='category-content__items px-2 md:px-4 border-0'>
								<div className="flex items-center gap-2">
									<Textarea id="note" placeholder="Leave a comment..." rows={4} {...register("note", { required: true })} />
								</div>
								{errors.note && <p className='text-left mt-2 text-rose-600 text-xs'> Please Check </p>}
							</div>
						</div>
						<div className='mx-4'>
							<Button type="submit" disabled={buttonStatus} className='w-full rounded-sm' color="dark">Submit</Button>
						</div>
						<div className=' text-red-600 font-medium text-sm mx-4'>
							<p> Note : Claim intimation to be done within 48 hours from the time of theft </p>
						</div>
					</form>
				</section>:
				<ClaimResponse  claimSubmitResponse ={claimSubmitResponse} />
			}
		</>
	)
}

export default TPClaim
